<template>
<!-- MODAL New Expedition -->
  <div id="newExpeditionModal"
  class="col-12 justify-content-center pe-6"
  style="padding: 15vh 20%">
    <div class="col-12 p-0 m-0 bg-caja section-container rounded-5">

<!-- HEAD New Expedition -->
      <div class="row overflow-hidden col-12 m-0 p-0 text-white bg-clomos rounded-top-5 pb-1 h-100">
        <div class="col-10">
          <h4 class="tx-left tx-clomos-white mb-2 ps-1 pt-2">
            <ion-icon name="arrow-redo-outline" class="tx-27 me-2 align-bottom"></ion-icon>
            {{dictionary['Nueva expedición'][session.user.language]}}
          </h4>
        </div>

        <div class="col-2 m-0 p-0" style="display: flex; justify-content: end;"
        @click="$emit('toggleNewExpeditionModal')">	
          <ion-icon name="close" class="ms-auto tx-46 tx-white align-bottom"></ion-icon>
        </div>
      </div>

      <div class="col-12 p-0 w-100">
        <div style="overflow: scroll; overflow-x: hidden; max-height: 60vh;">
          <div class="card-body">

<!-- CONTENT New Expedition -->
            <form class="form-material border-1 p-3" style="overflow: scroll; overflow-x: auto; overflow-y: hidden;">
              <div class="container-fluid">
                  
                <div class="col-12 ps-2 pe-2">
                  <label class="col-12 tx-bold tx-clomos-dark text-nowrap ps-3 pe-3">
                    {{dictionary['Orden'][session.user.language]}}
                  </label>
                </div>

                <div class="row ms-3 me-3 border-2 rounded p-1">
<!-- PRODUCTO -->
                  <div class="form-group col-lg-8 col-12 p-1">
                    <label class="col-12 p-0 tx-bold tx-clomos-midtone text-nowrap">
                      {{ dictionary['Identificador'][session.user.language] }}
                    </label>
                    <div class="col-12 p-0">
                      <input v-model="targetProduct.id" id="inputSearchProductId" type="text" autocomplete="off"
                      list="productStocks" class="form-control form-control-line ps-0 pe-0" style="color:black;"
                      @change="(event)=>{onTargetProduct(event)}"
                      @input="(event)=>{filterProductList(event)}">
                    </div>
                    <datalist id="productStocks">
                      <option v-for="product in filteredProductList" :key="product" 
                      :value="product.id"> 
                        {{ product.label }}
                      </option>
                    </datalist>
                  </div>

                  <div class="form-group col-lg-2 col-12 p-1">
                    <label class="col-12 p-0 tx-bold tx-clomos-midtone text-nowrap">
                      {{ dictionary['Unidades'][session.user.language] }}
                    </label>
                    <div class="col-md-12 p-0">
                      <input v-model="inputNewExpeditionAmmount" id="inputNewExpeditionAmmount" type="number" min=0 :default="cap" :max="cap"
                      class="form-control form-control-line ps-0 pe-0" style="color:black;">
                    </div>
                  </div>

                  <div class="form-group col-lg-2 col-12 p-1">
                    <label class="col-12 p-0 tx-bold tx-clomos-midtone text-nowrap">
                      {{ dictionary['Catálogo'][session.user.language] }}
                    </label>
                    <div class="col-12 p-0 bg-clomos rounded-3 pt-1 pb-1 btn tx-white"
                    :class="(isRequesting)?'opacity-50':''"
                    @click="(!isRequesting)?getStockList():''">
                      <ion-icon name="reload-outline" class="loader tx-22 align-bottom" :class="(isRequesting===true)?'requesting':'tx-white'"></ion-icon>
                    </div>
                  </div>

                  <div class="form-group col-lg-2 col-12 p-1">
                    <label class="col-12 p-0 tx-bold tx-clomos-midtone text-nowrap">
                      {{ dictionary['Lote'][session.user.language] }}
                    </label>
                    <div class="col-md-12 p-0">
                      <input v-model="inputNewExpeditionBatch" id="inputNewExpeditionBatch" type="text" :disabled="!hasBatch"
                      class="form-control form-control-line ps-0 pe-0" style="color:black;">
                    </div>
                  </div>

                  <div class="form-group col-lg-2 col-12 p-1">
                    <label class="col-12 p-0 tx-bold tx-clomos-midtone text-nowrap">
                      {{ dictionary['Nº de serie'][session.user.language] }}
                    </label>
                    <div class="col-md-12 p-0">
                      <input v-model="inputNewExpeditionSerialNumber" id="inputNewExpeditionSerialNumber" type="text" :disabled="!hasSerialNumber"
                      class="form-control form-control-line ps-0 pe-0" style="color:black;">
                    </div>
                  </div>

                  <div class="form-group col-lg-2 col-12 p-1">
                    <label class="col-12 p-0 tx-bold tx-clomos-midtone text-nowrap">
                      {{ dictionary['Talla'][session.user.language] }}
                    </label>
                    <div class="col-md-12 p-0">
                      <input v-model="inputNewExpeditionSize" id="inputNewExpeditionSize" type="text" :disabled="!hasSize"
                      class="form-control form-control-line ps-0 pe-0" style="color:black;">
                    </div>
                  </div>
                  
                  <div class="form-group col-lg-2 col-12 p-1">
                    <label class="col-12 p-0 tx-bold tx-clomos-midtone text-nowrap">
                      {{ dictionary['Color'][session.user.language] }}
                    </label>
                    <div class="col-md-12 p-0">
                      <input v-model="inputNewExpeditionColor" id="inputNewExpeditionColor" type="text" :disabled="!hasColor"
                      class="form-control form-control-line ps-0 pe-0" style="color:black;">
                    </div>
                  </div>

                  <div class="form-group col-lg-2 col-12 p-1">
                    <label class="col-12 p-0 tx-bold tx-clomos-midtone text-nowrap">
                      {{ dictionary['Caducidad'][session.user.language] }}
                    </label>
                    <div class="col-md-12 p-0">
                      <input v-model="inputNewExpeditionExpirationDate" id="inputNewExpeditionExpirationDate" type="date" :disabled="!hasExpirationDate"
                      class="form-control form-control-line ps-0 pe-0" style="color:black;">
                    </div>
                  </div>

                  <div class="form-group col-lg-2 col-12 p-0 pt-2 m-0">
                    <ion-icon name="add-circle-outline" class="tx-clomos-dark targets-icons m-0 p-0 pt-2 tx-36" style="vertical-align: middle;"
                    @click="onAppendProduct()">
                    </ion-icon>
                    <ion-icon name="close-circle-outline" class="tx-clomos-dark targets-icons m-0 p-0 pt-2 tx-36" style="vertical-align: middle;"
                    @click="onClearTarget()">
                    </ion-icon>
                  </div>

                </div>

                <div class="row ms-3 me-3 mt-3 border-2 rounded p-1" v-for="target,index in newExpeditionData.targetList" :key="target">
<!-- TARGETS -->

                  <div class="form-group col-lg-6 col-12 p-1">
                    <label class="col-12 p-0 tx-bold tx-clomos-midtone text-nowrap">
                      {{ dictionary['Identificador'][session.user.language] }}
                    </label>
                    <div class="col-md-12 p-0">
                      <div class="border-bottom tx-13 pb-1 text-nowrap" style="color:black;">
                      {{ target.id }}
                      </div>
                    </div>
                  </div>

                  <div class="form-group col-lg-4 col-12 p-1">
                    <label class="col-12 p-0 tx-bold tx-clomos-midtone text-nowrap">
                      {{ dictionary['Nombre'][session.user.language] }}
                    </label>
                    <div class="col-md-12 p-0">
                      <div class="border-bottom tx-13 pb-1 text-nowrap" style="color:black;">
                      {{ target.label }}
                      </div>
                    </div>
                  </div>

                  <div class="form-group col-lg-2 col-12 p-1">
                    <label class="col-12 p-0 tx-bold tx-clomos-midtone text-nowrap">
                      {{ dictionary['Unidades'][session.user.language] }}
                    </label>
                    <div class="col-md-12 p-0">
                      <div class="border-bottom tx-13 pb-1 text-nowrap" style="color:black;">
                      {{ target.ammount }}
                      </div>
                    </div>
                  </div>

                  <div class="form-group col-lg-2 col-12 p-1">
                    <label class="col-12 p-0 tx-bold tx-clomos-midtone text-nowrap">
                      {{ dictionary['Lote'][session.user.language] }}
                    </label>
                    <div class="col-md-12 p-0">
                      <div class="border-bottom tx-13 pb-1 text-nowrap" style="color:black;">
                      {{ (target.batch != undefined) ?(target.batch != "") ?target.batch :"Sin criterio" :"-"}}
                      </div>
                    </div>
                  </div>

                  <div class="form-group col-lg-2 col-12 p-1">
                    <label class="col-12 p-0 tx-bold tx-clomos-midtone text-nowrap">
                      {{ dictionary['Nº de serie'][session.user.language] }}
                    </label>
                    <div class="col-md-12 p-0">
                      <div class="border-bottom tx-13 pb-1 text-nowrap" style="color:black;">
                      {{ (target.serialNumber != undefined) ?(target.serialNumber != "") ?target.serialNumber :"Sin criterio" :"-"}}
                      </div>
                    </div>
                  </div>

                  <div class="form-group col-lg-2 col-12 p-1">
                    <label class="col-12 p-0 tx-bold tx-clomos-midtone text-nowrap">
                      {{ dictionary['Talla'][session.user.language] }}
                    </label>
                    <div class="col-md-12 p-0">
                      <div class="border-bottom tx-13 pb-1 text-nowrap" style="color:black;">
                      {{ (target.size != undefined) ?(target.size != "") ?target.size :"Sin criterio" :"-"}}
                      </div>
                    </div>
                  </div>

                  <div class="form-group col-lg-2 col-12 p-1">
                    <label class="col-12 p-0 tx-bold tx-clomos-midtone text-nowrap">
                      {{ dictionary['Color'][session.user.language] }}
                    </label>
                    <div class="col-md-12 p-0">
                      <div class="border-bottom tx-13 pb-1 text-nowrap" style="color:black;">
                      {{ (target.color != undefined) ?(target.color != "") ?target.color :"Sin criterio" :"-"}}
                      </div>
                    </div>
                  </div>

                  <div class="form-group col-lg-2 col-12 p-1">
                    <label class="col-12 p-0 tx-bold tx-clomos-midtone text-nowrap">
                      {{ dictionary['Caducidad'][session.user.language] }}
                    </label>
                    <div class="col-md-12 p-0">
                      <div class="border-bottom tx-13 pb-1 text-nowrap" style="color:black;">
                      {{ (target.expirationDate != undefined) ?(target.expirationDate != "") ?target.expirationDate :"Sin criterio" :"-"}}
                      </div>
                    </div>
                  </div>

                  <div class="form-group col-lg-2 col-12 p-0 m-0">
                    <ion-icon name="remove-circle-outline" class="tx-clomos-dark targets-icons m-0 p-0 pt-2 tx-36" style="vertical-align: middle;"
                    @click="onDeleteTarget(index)"></ion-icon>
                  </div>

                </div>

                <div class="row">
<!-- SUBMIT -->

                  <div class="form-group col-lg-6 col-12 p-0 pt-3 m-0 ms-auto me-auto">
                    <div class="col-md-12">
                      <button type="button" id="btnSubmitCreateNewExpedition" class="btn btn-clomos w-100"
                      @click="setNewExpeditionData">
                        {{dictionary['Crear nueva expedición'][session.user.language]}}
                      </button>
                    </div>
                  </div>

                </div>

              </div>

            </form>
              
          </div>
        </div>
      </div>
      
    </div>
  </div>

</template>

<script>
import $ from 'jquery';
import logisticProfilesJson from "/src/assets/json/logistic_profiles.json";
// Clomos
import api from '@/services/api';

export default {
  name: "NewExpeditionModal",
  props: {
    session: {
      type: Object,
      requiered: true,
    },
    dictionary: {
      type: Object,
      requiered: true,
    },
    countries: {
      type: Object,
      requiered: true,
    },
    currentCompany: {
      type: Object,
      requiered: true,
    },
    availableCompanies: {
      type: Object,
      requiered: true,
    },
    productId: {
      type: String,
      requiered: true,
    },
    hasReceipt: {
      type: Boolean,
      requiered: false,
    },
  },
  data() {
    return {
      newExpeditionData: {
        userId: this.session.user.id,
        targetList: [],
      },
      targetProduct: {
        id: "",
        reference: "",
        label: "",
        unit: "",
        logisticProfile: "",
        zone: ""
      },
      availableProductList: [],
      inputNewExpeditionBatch: "",
      inputNewExpeditionSerialNumber: "",
      inputNewExpeditionSize: "",
      inputNewExpeditionColor: "",
      inputNewExpeditionExpirationDate: "",
      inputNewExpeditionAmmount: 0,
      cap: 0,
      hasBatch: false,
      hasSerialNumber: false,
      hasSize: false,
      hasColor: false,
      hasExpirationDate: false,
      isRequesting: false,
      isFiltering: undefined,
      filteredProductList: [],
      logisticProfiles: JSON.parse(JSON.stringify(logisticProfilesJson))
    }
  },

  emits: ["toggleNewExpeditionModal"],
  methods: {
    // GET
    async getStockList() {
      this.isRequesting = true;
      let request = {
        nifCif: "showAll",
        limit: 0,
        offset: 0,  
        where: ""
      }
      let response = await api.getStockTypeList(request);
      this.isRequesting = false;
      this.availableProductList = api.parseStockTypesFromResponse(response);
      // localStorage.setItem("AvailableProductList", JSON.stringify(this.availableProductList));
      this.isFiltering = false;
    },
    // SET
    async setNewExpeditionData() {
      let valid = true;
      if (this.newExpeditionData.targetList.length == 0) {
        $("#inputSearchProductId").get()[0].classList.add("missing")
        $("#inputNewExpeditionBatch").get()[0].classList.add("missing")
        $("#inputNewExpeditionSerialNumber").get()[0].classList.add("missing")
        $("#inputNewExpeditionSize").get()[0].classList.add("missing")
        $("#inputNewExpeditionColor").get()[0].classList.add("missing")
        $("#inputNewExpeditionExpirationDate").get()[0].classList.add("missing")
        $("#inputNewExpeditionAmmount").get()[0].classList.add("missing")
        valid = false;
      }
      if (!valid) {
        setTimeout(() => {
          $("#inputSearchProductId").get()[0].classList.remove("missing")
          $("#inputNewExpeditionBatch").get()[0].classList.remove("missing")
          $("#inputNewExpeditionSerialNumber").get()[0].classList.remove("missing")
          $("#inputNewExpeditionSize").get()[0].classList.remove("missing")
          $("#inputNewExpeditionColor").get()[0].classList.remove("missing")
          $("#inputNewExpeditionExpirationDate").get()[0].classList.remove("missing")
          $("#inputNewExpeditionAmmount").get()[0].classList.remove("missing")
        }, 3000)
        return;
      }
      let response = await api.postNewExpedition(this.newExpeditionData);
      if (response["status-code"] === "200") {
        this.$emit('toggleNewExpeditionModal');
        window.alert("La expedición ha sido creada satisfactoriamente.");
      }
      else {
        window.alert("Error al crear la expedición.");
      }
    },
  // FILTER
    filterProductList(event) {
      let targetId = event.target.value;
      this.isFiltering = true;
      this.filteredProductList = [];
      for (let i in this.availableProductList)
        if ( this.availableProductList[i].variables?.dynamic?.perfilLogistico?.value != ""
        && this.availableProductList[i].variables?.dynamic?.zona?.value != ""
        && this.availableProductList[i].variables?.dynamic?.huecosHabilitados?.value != ""
        && (targetId != undefined || this.targetProduct.id === ""))
          this.filteredProductList.push(this.availableProductList[i]);
      this.isFiltering = false;
    },
  // TARGET
    onTargetProduct(event) {
      let targetId = event.target.value;
      this.inputNewExpeditionBatch = "";
      this.inputNewExpeditionSerialNumber = "";
      this.inputNewExpeditionSize = "";
      this.inputNewExpeditionColor = "";
      this.inputNewExpeditionExpirationDate = "";
      let match = false;
      for (let i in this.availableProductList) {
        if (this.availableProductList[i].variables?.dynamic?.referencia != undefined 
        && this.availableProductList[i].id === targetId) {
          this.targetProduct = {
            id: this.availableProductList[i].id,
            reference: this.availableProductList[i].variables.dynamic.referencia.value,
            label: this.availableProductList[i].label,
            unit: this.availableProductList[i].variables.dynamic.unidad.value,
            logisticProfile: this.availableProductList[i].variables.dynamic.perfilLogistico.value,
            zone: this.availableProductList[i].variables.dynamic.zona.value,
          }
          this.cap = Number(this.availableProductList[i].variables.analog.stockNeto.value);
          let variables = {
            batch: false ,
            serialNumber: false ,
            size: false ,
            color: false,
            expirationDate: false 
          }
          for (let i in this.logisticProfiles[this.targetProduct.logisticProfile]?.variables) {
            variables[this.logisticProfiles[this.targetProduct.logisticProfile]?.variables[i]] = true;
          }
          this.hasBatch = variables.batch;
          this.hasSerialNumber = variables.serialNumber;
          this.hasSize = variables.size;
          this.hasColor = variables.color;
          this.hasExpirationDate = variables.expirationDate;
        
          match = true;
        }
        if (match) 
          break;
      }
      if (!match || targetId === "") {
        this.hasBatch = false;
        this.hasSerialNumber = false;
        this.hasSize = false;
        this.hasColor = false;
        this.hasExpirationDate = false;
      }
    },
    onClearTarget() {
      this.targetProduct = {
        id: "",
        reference: "",
        label: "",
        unit: "",
        logisticProfile: "",
        zone: ""
      };
      this.inputNewExpeditionAmmount = "";
      this.inputNewExpeditionBatch = "";
      this.inputNewExpeditionSerialNumber = "";
      this.inputNewExpeditionSize = "";
      this.inputNewExpeditionColor = "";
      this.inputNewExpeditionExpirationDate = "";
      this.hasBatch = false;
      this.hasSerialNumber = false;
      this.hasSize = false;
      this.hasColor = false;
      this.hasExpirationDate = false;
    },
    onAppendProduct() {
      let valid = true;
      if (this.inputNewExpeditionAmmount<=0) {
        $("#inputNewExpeditionAmmount").get()[0].classList.add("missing");
        valid = false;
      }
      if (this.targetProduct.id==="") {
        $("#inputSearchProductId").get()[0].classList.add("missing");
        valid = false;
      }
      let match= false;
      for (let i in this.availableProductList) {
        if (this.availableProductList[i].id === this.targetProduct.id)
          match = true
        if (match)
          break;
      }
      if (!match) {
        $("#inputSearchProductId").get()[0].classList.add("missing");
      }
      if (!valid || !match) {
        setTimeout(() => {
          $("#inputNewExpeditionAmmount").get()[0].classList.remove("missing")
        }, 3000)
        setTimeout(() => {
          $("#inputSearchProductId").get()[0].classList.remove("missing")
        }, 3000)
        return;
      } 
      let target = {
      'id': this.targetProduct.id, 
      'reference': this.targetProduct.reference, 
      'label': this.targetProduct.label, 
      'zone': this.targetProduct.zone,
      'unit': this.targetProduct.unit,
      'logisticProfile': this.targetProduct.logisticProfile,
      'ammount': this.inputNewExpeditionAmmount
      }
      if (this.hasBatch) target['batch'] = this.inputNewExpeditionBatch;
      if (this.hasSerialNumber) target['serialNumber'] = this.inputNewExpeditionSerialNumber;
      if (this.hasExpirationDate) target['expirationDate'] = this.inputNewExpeditionExpirationDate;
      if (this.hasSize) target['size'] = this.inputNewExpeditionSize;
      if (this.hasColor) target['color'] = this.inputNewExpeditionColor;

      for (let i in this.newExpeditionData.targetList) {
        if (this.newExpeditionData.targetList[i]["id"] === target["id"]) {
          if (target["batch"] === this.newExpeditionData.targetList[i]["batch"]
          && target["serialNumber"] === this.newExpeditionData.targetList[i]["serialNumber"]
          && target["expirationDate"] === this.newExpeditionData.targetList[i]["expirationDate"]
          && target["size"] === this.newExpeditionData.targetList[i]["size"]
          && target["color"] === this.newExpeditionData.targetList[i]["color"]) {
            this.newExpeditionData.targetList[i]["ammount"] += target["ammount"]
            return;
          }
        }
      }

      this.newExpeditionData.targetList.push(target)
    },
    onDeleteTarget(index) {
      this.newExpeditionData.targetList.splice(index,1)
    }
  },
  async created() {
    // this.availableProductList = (localStorage.getItem("AvailableProductList")!=undefined)?JSON.parse(localStorage.getItem("AvailableProductList")):[];
    await this.getStockList();
  }
}
</script>

<style>

#newExpeditionModal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99999;
  background-color: rgba(0, 0, 0, 0.5);
  width: 100vw;
  height: 100vh;
}

.targets-icons:hover {
  color: var(--main-dark-50);
}

#inputSearchProductId.missing, #inputNewExpeditionAmmount.missing {
  box-shadow: inset 0px 0px 0px 2px #ce7d0a;
}

@media (max-width: 767px) {
  #stockImgUploadFormContainer {
    flex-direction: column;
  }
}

.loader {
  color: white !important;
  visibility: visible;
  transition: all 0.3s;
}

.requesting {
  animation: spin 3s infinite linear;
  transition: all 0.3s;
}

@keyframes spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}
</style>