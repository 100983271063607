<template>
  <!-- SECTION Bundles-->
    <div id="bundlesSection" class="col-12 justify-content-center pe-3"
    :class="(visibility.sideMenu)?'section-container-sm' :'section-container-lg'">
      <div class="col-12 p-0 pb-3 m-0 bg-caja section-container rounded-5">
    
      <!-- HEADER Bundles -->
        <div class="row overflow-hidden col-12 m-0 p-0 text-white bg-clomos rounded-top-5 pb-1 h-100" >
          <div class="col-lg-9 col-md-12 col-12 justify-content-center" style="display: inherit">
            <!--<ion-icon v-if="session.user.bookmark==='bundles'" name="bookmark" class="bookmark-button"></ion-icon>
            <ion-icon v-else name="bookmark-outline" class="bookmark-button"></ion-icon>-->
            <h4 class="tx-left tx-clomos-white text-nowrap mb-2 ps-1 pt-2" style="height:40px;">
              
              <ion-icon name="grid-outline" class="tx-27 me-2 align-bottom"></ion-icon>
              <span> {{dictionary["Huecos"][session.user.language] }} </span>
              <span v-if="this.$route.params.currentDisplay==='bundles' || this.targetMachineId == ''"> 
                {{ ": "+currentCompany.name }} 
              </span>
              <span v-else-if="this.targetMachineData.label != undefined"> 
                {{ ": "+targetMachineData.label }} 
              </span>
            </h4>
          </div>
        </div>
        
        <div class="form-group p-3 m-3 mb-0 border-1 d-flex justify-content-center"
        style="overflow: scroll; overflow-x: auto; overflow-y: hidden;">
          <div class="border-3 rounded-3" style="width: fit-content;"> 
            <table class="tx-black fw-medium justify-content-center" styles="border: solid white 3px; border-radius: 8px;">
              <tr v-for="row in lockersSlotsGrid" :key="row">
                <td v-for="slot in row" :key="slot"
                class="tx-white fw-medium justify-content-center" style="border: solid white 3px; border-radius: 8px; width: 100px; max-width: 100px; height: 100px; max-height: 100px;"
                :style="{  backgroundColor:
                (slot['status']==='') ? 'silver' :
                Number(slot['status']) === 0 ? 'green':
                Number(slot['status']) === 1 ? 'black' : 
                Number(slot['status']) === 2 ? 'red' : 
                Number(slot['status']) === 3 ? 'orange':
                'transparent' }"> 
                  <div v-if="slot['label']!=undefined">
                    {{ slot['label'] }}
                  </div>  
                  <div>
                    {{ (slot['status']==='') ? "No hay ningún hueco." :
                    (Number(slot['status']) === 0) ?"Vacío" :
                    (Number(slot['status']) === 1) ?"Reservado" :
                    (Number(slot['status']) === 2) ?"Ocupado" :
                    (Number(slot['status']) === 3) ?"Espera" : 
                    "" }}
                  </div>
                </td> 
              </tr>
            </table>
          </div>
          <div v-if="targetMachineData.variables" class="border-start text-start tx-24 ms-3 ps-3">
            <ion-icon @click="onRefresh" name="reload-outline" class="border-3 rounded tx-20 tx-clomos-midtone" style="cursor:pointer;"></ion-icon>
            <br>
            <label class="tx-clomos-dark text-nowrap"> Huecos totales: </label>
            <text class="ps-1"> {{targetMachineData.variables.analog.huecosTotales.value}} </text>
            <br>
            <label class="tx-clomos-dark text-nowrap"> Huecos libres: </label>
            <text class="ps-1"> {{targetMachineData.variables.analog.huecosLibres.value}} </text>
            <br>
            <label class="tx-clomos-dark text-nowrap"> Huecos ocupados: </label>
            <text class="ps-1"> {{targetMachineData.variables.analog.huecosOcupados.value}} </text>
            <br>
          </div>
        </div>

    </div> 
  </div>   
</template>  

<script>
  import $ from 'jquery';
      // Clomos
  import api from '@/services/api'; 
  
  export default {
    name: "LockerSlotsComponent",
    props: {
      session: {
        type: Object,
        requiered: true,
      },
      dictionary: {
        type: Object,
        requiered: true,
      },
      countries: {
        type: Object,
        requiered: true,
      },
      timeZones: {
        type: Object,
        requiered: true,
      },
      visibility: {
        type: Object,
        requiered: true,
      },
      currentCompany: {
        type: Object,
        requiered: true,
      },
      availableCompanies: {
        type: Object,
        requiered: true,
      },
      targetMachineId: {
        type: String,
        requiered: true,
      },
      dataTableOptions: {
        type: Object,
        requiered: true,
      }
    },
    watch: { 
      currentCompany: async function() {
        if (this.$route.params.currentDisplay === 'bundles' || this.targetMachineId === '') {
          await this.getBundleListByCompany();
        }
        else {
          await this.getMachineData();
          await this.getBundleListByMachine();
        }
        if (this.lockerSlots[0] != undefined && this.targetBundleId === "")
          this.targetBundleId = this.lockerSlots[0].id;
        this.loadBundles();
      },
      targetMachineId: async function() {
        if (this.$route.params.currentDisplay === 'bundles' || this.targetMachineId === '') {
          await this.getBundleListByCompany();
        }
        else {
          await this.getMachineData();
          await this.getBundleListByMachine();
        }
        if (this.lockerSlots[0] != undefined && this.targetBundleId === "")
          this.targetBundleId = this.lockerSlots[0].id;
        this.loadBundles();
      },
      targetBundleId: async function() {
        if (this.$route.params.currentDisplay === 'bundles' || this.targetMachineId === '') {
          await this.getBundleListByCompany();
        }
        else {
          await this.getMachineData();
          await this.getBundleListByMachine();
        }
        if (this.lockerSlots[0] != undefined && this.targetBundleId === "")
          this.targetBundleId = this.lockerSlots[0].id;
      },
    },
    data() {
      return {
        targetMachineData: {},
        bundleData: {},
        targetBundleId: "",
        lockerSlots: [],
        machineList: [],
        lockersSlotsGrid: [],
        linkAction: "link",
        variables: {
          analog: {},
          digital: {},
          dynamic: {},
        }
      }
    },
    emits: ["setCurrentCompany"],
    methods: {
      // DISPLAY
      onCurrentCompanyChanged(event) {
        let value = event.target.value;
        this.$router.replace({params: {currentCompanyNifCif: value}})
        this.$emit('setCurrentCompany',value);
      },
      onRefresh() {
        this.getBundleListByMachine(this.loadBundles);
      },
      // BUTTONS
      setButtonFunctions() {
        setTimeout(()=>{
          $(".btnTargetBundle").get().forEach(btn => {
            btn.addEventListener('click', this.onTargetBundle);
          });
        }, 300);
      },
      // GET
      async getBundleListByCompany(callback) {
        let request = {
          nifCif: this.currentCompany.nifCif,
          limit: 0,
          offset: 0,  
          where: "",
        };
        let response = await api.getBundleListByCompany(request);
        this.lockerSlots = api.parseBundlesFromResponse(response);
        if (callback && typeof callback === "function") callback(this.lockerSlots);
      },
      async getBundleListByMachine(callback) {
        let request = {
          machineId: this.targetMachineId,
          limit: 0,
          offset: 0,  
          where: "",
        };
        let response = await api.getBundleListByMachine(request);
        this.lockerSlots = api.parseBundlesFromResponse(response);
        if (callback && typeof callback === "function") callback(this.lockerSlots);
      },
      async getMachineData(callback) {
        let response = await api.getMachineData(this.targetMachineId);
        this.targetMachineData = api.parseMachineFromResponse(response);
        if (callback && typeof callback === "function") callback(this.targetMachineData);
      },
      async getMachinesDataByCompany(callback) {
        let request = {
          nifCif: this.currentCompany.nifCif,
          limit: 0,
          offset: 0,  
          where: "",
        };
        let response = await api.getMachineListByCompany(request);
        this.machineList = api.parseMachinesFromResponse(response);
        if (callback && typeof callback === "function") callback(this.machineList);
      },
      // TARGET 
      onTargetBundle(event) {
        console.log('TARGET WHERE ID:');
        console.log(event.target.getAttribute('data-id'));
        this.targetBundleId = event.target.getAttribute('data-id')
        this.$router.replace({params: {targetEntity: this.targetBundleId}})
      },
      // LOAD
      loadBundles() {
        if (this.targetMachineData.variables == undefined) return; 
        if (this.targetMachineData.variables.analog.numeroFilas === undefined
        || this.targetMachineData.variables.analog.numeroColumnas === undefined) 
          return;
        let rows = parseInt(this.targetMachineData.variables.analog.numeroFilas.value);
        let columns = parseInt(this.targetMachineData.variables.analog.numeroColumnas.value);
        this.lockersSlotsGrid = [];
        let r0 = 0;
        while (r0 < rows) {
          this.lockersSlotsGrid.push([]);
          let c0 = 0;
          while (c0 < columns) {
            this.lockersSlotsGrid[r0][c0] = {"id": "", "label": "", "status": ""};
            for (let bundle in this.lockerSlots) {
              if (this.lockerSlots[bundle].geoJSON != undefined
              && this.lockerSlots[bundle].geoJSON.geometry != undefined
              && this.lockerSlots[bundle].geoJSON.geometry.coordinates != undefined
              && this.lockerSlots[bundle].geoJSON.geometry.coordinates[0] == r0
              && this.lockerSlots[bundle].geoJSON.geometry.coordinates[1] == c0) {
                this.lockersSlotsGrid[r0][c0]["id"] = this.lockerSlots[bundle].id; 
                this.lockersSlotsGrid[r0][c0]["label"] = this.lockerSlots[bundle].label;
                this.lockersSlotsGrid[r0][c0]["status"] = this.lockerSlots[bundle].variables.analog.estado.value;
              }
            }
            c0 += 1;
          }
          r0 += 1;
        }
      }
    }, 
    async created() {
      await this.getMachinesDataByCompany();
      if (this.$route.params.currentDisplay === 'bundles') {
        await this.getBundleListByCompany();
      }
      else if (this.targetMachineId != ''){
        await this.getMachineData();
        await this.getBundleListByMachine();
      }
      if (this.lockerSlots[0] != undefined && this.targetBundleId === "")
      this.targetBundleId = this.lockerSlots[0].id;
      this.loadBundles();
    },
  } 
</script>
  
<style>
  .btnTargetBundle:hover, .btnLinkBundle:hover, .btnUnlinkBundle:hover, .btnEditBundle:hover, .btnDeleteBundle:hover {
    color: var(--main-dark);
  }

</style> 