<template>
<!-- SECTION Receptions-->
  <div id="receptionsSection" class="col-12 justify-content-center pe-3"
  :class="(visibility.sideMenu)?'section-container-sm' :'section-container-lg'">
    <div class="col-12 p-0 m-0 bg-caja section-container rounded-5">
  
<!-- HEADER Receptions -->

      <div class="row overflow-hidden col-12 m-0 p-0 text-white bg-clomos rounded-top-5 pb-1 h-100" >

        <div class="col-lg-9 col-md-12 col-12 justify-content-center" style="display: inherit">
          <!--<ion-icon v-if="session.user.bookmark==='receptions'" name="bookmark" class="bookmark-button"></ion-icon>
          <ion-icon v-else name="bookmark-outline" class="bookmark-button"></ion-icon>-->
          <h4 class="tx-left tx-clomos-white text-nowrap mb-2 ps-1 pt-2" style="height:40px;">

            <ion-icon name="arrow-redo-outline" class="tx-27 me-2 align-bottom"></ion-icon>
            {{dictionary["Recepción de productos"][session.user.language]}}
          </h4>
        </div>

        <div class="col-lg-3 col-12 p-2 pb-1">	
          <button id="btnDisplayNewReceptionModal"
          class="btn btn-clomos bg-white rounded p-1 inner-border fw-normal text-nowrap"
          style="width: 100%; height:100%; vertical-align: middle; border: solid 2px white"
          @click="toggleNewReceptionModal">
              <ion-icon name="add-circle-outline" class="tx-clomos-dark me-2 tx-20" style="vertical-align: middle; margin-top: -0.25rem;"></ion-icon>
              <span class="ms-auto tx-clomos-dark">{{dictionary["Solicitar recepción"][session.user.language]}}</span>
          </button>
        </div>
      </div>

      <div class="m-0 p-3 overflow-auto">
        <table id="receptionsDataTable" class="display w-100 border-1 p-0 m-0 rounded-3">
          <thead class="bg-clomos-light">
            <tr>
              <th v-for="column in receptionsColumns" :key="column.data"
              :width="column.width" class="tx-black fw-medium text-nowrap">
                {{dictionary[column.text][session.user.language]}}
              </th>
            </tr>
          </thead>
        </table>
      </div>

    </div>
  </div>

<!-- MODAL New Reception -->
  <NewReceptionModal
  v-if="display.newReceptionModal"
  :session="session"
  :dictionary="dictionary"
  :countries="countries"
  :timeZones="timeZones"
  :currentCompany="currentCompany"
  :availableCompanies="availableCompanies"
  v-on:toggleNewReceptionModal="toggleNewReceptionModal">
  </NewReceptionModal> 

<!-- MODAL Prepare Reception -->
  <PrepareReceptionModal
  v-if="display.prepareReceptionModal"
  :session="session"
  :dictionary="dictionary"
  :countries="countries"
  :timeZones="timeZones"
  :currentCompany="currentCompany"
  :availableCompanies="availableCompanies"
  :receptionId="targetReceptionId"
  v-on:togglePrepareReceptionModal="togglePrepareReceptionModal">
  </PrepareReceptionModal> 

<!-- MODAL Plan Text Alert -->
  <TextAlertModal
  v-if="display.planReceptionModal"
  :session="session"
  :dictionary="dictionary"
  :countries="countries"
  :alertHeader="dictionary['Planificar'][session.user.language] + ': '"
  :alertTarget="targetReceptionId"
  :alertInput="(session.browser === 'firefox') ?'date' :'datetime-local'"
  :alertValue="alertValue"
  v-on:updateAlertValue="onUpdateAlertValue"
  v-on:acceptAlert="onPlanReception"
  v-on:dismissAlert="togglePlanReceptionModal">
  </TextAlertModal>

<!-- MODAL End Text Alert -->
  <TextAlertModal
  v-if="display.endReceptionModal"
  :session="session"
  :dictionary="dictionary"
  :countries="countries"
  :alertHeader="dictionary['Recepcionar'][session.user.language] + ': '"
  :alertTarget="targetReceptionId"
  v-on:acceptAlert="onEndReception"
  v-on:dismissAlert="toggleEndReceptionModal">
  </TextAlertModal>

<!-- MODAL Delete Text Alert -->
  <TextAlertModal
  v-if="display.deleteReceptionModal"
  :session="session"
  :dictionary="dictionary"
  :countries="countries"
  :alertHeader="dictionary['Recepción a eliminar'][session.user.language] + ': '"
  :alertTarget="targetReceptionId"
  v-on:acceptAlert="onDeleteReception"
  v-on:dismissAlert="toggleDeleteReceptionModal">
  </TextAlertModal>

</template>

<script>
import $ from 'jquery';
import NewReceptionModal from '@/components/receptions/NewReceptionModal';
import PrepareReceptionModal from '@/components/receptions/PrepareReceptionModal';
import TextAlertModal from '@/components/other/TextAlertModal';
// Clomos
import api from '@/services/api';

export default {
  name: "ReceptionsComponent",
  components: { PrepareReceptionModal, NewReceptionModal, TextAlertModal  },
  props: {
    session: {
      type: Object,
      requiered: true,
    },
    dictionary: {
      type: Object,
      requiered: true,
    },
    countries: {
      type: Object,
      requiered: true,
    },
    timeZones: {
      type: Object,
      requiered: true,
    },
    currentCompany: {
      type: Object,
      requiered: true,
    },
    availableCompanies: {
      type: Object,
      requiered: true,
    },
    visibility: {
      type: Object,
      requiered: true,
    },
    dataTableOptions: {
      type: Object,
      requiered: true,
    }
  },
  watch: { 
    currentCompany: async function() {
      await this.getReceptionList();
      this.displayReceptionsDataTable();
    }
  },
  data() {
    return {
      receptionsColumns: [
        {text:"Albarán", data: "receptionId", width: "5%",
          render: (data) => {
            return "<ion-icon name='list-circle-outline' data-id="+data+" class='btnDetailReception btn tx-24 tx-primary  p-0 m-0'></ion-icon>";
          }
        },
        {text:"Identificador", data: "receptionId", width: "15%"},
        {text:"Estado", data: "receptionStatus", width: "10%",
          render: (data) => {
            switch (data.status) {
              case "0":
                return this.dictionary["Creada"][this.session.user.language];
              case "1":
                return this.dictionary["Planificada"][this.session.user.language];
              case "2":
                return this.dictionary["Ejecutando"][this.session.user.language];
              case "3":
                return this.dictionary["Ejecutada"][this.session.user.language];
              case "4":
                return this.dictionary["Finalizada"][this.session.user.language];
              case "5":
                return this.dictionary["Cancelada"][this.session.user.language];
              default:
                return data.status
            }
          }        
        },
        {text:"Tipo", data: "receptionType", width: "10%",
          render: (data) => {
            switch (data) {
              case "0":
                return "Entrada";
              case "1":
                return "Salida";
              case "2":
                return "Regularización";
              case "3":
                return "Devolución";
              case "4":
                return "Pedido";
            }
          }
        },
        {text:"Fecha de creación", data: "receptionCreationDateTime", width: "10%",
          render: (data) => {   
            return "<span style='display: none;'>" + data.getTime() + "</span> <spam>" + this.parseDateUserTimezone(data) + "</spam>";
          }
        },
        {text:"Planificar", data: "receptionStatus", width: "5%",
          render: (data) => {
            return (Number(data.status)===0)
              ? "<ion-icon name='chevron-forward-circle-outline' data-id="+data.id+" class='btnPlanReception btn tx-24 tx-primary p-0 m-0'></ion-icon>"
              : "<ion-icon name='checkmark-circle-outline' data-id="+data.id+" class='btnPlannedReception btn tx-24 tx-success p-0 m-0' disabled></ion-icon>";
          }
        },
        {text:"Preparar", data: "receptionStatus", width: "5%",
          render: (data) => {
            if (Number(data.status)==5)
            {
              return  "<ion-icon name='close-circle-outline' data-id="+data.id+" class='btnPrepareReception btn tx-24 tx-danger opacity-50 p-0 m-0' disabled></ion-icon>";
            }
            return (Number(data.status)<1)
              ? "<ion-icon name='chevron-forward-circle-outline' data-id="+data.id+" class='btn tx-24 tx-clomos-midtone opacity-50 p-0 m-0' disabled></ion-icon>"
              : (Number(data.status)===1 || Number(data.status)===2)
                ? "<ion-icon name='chevron-forward-circle-outline' data-id="+data.id+" class='btnPrepareReception btn tx-24 tx-primary p-0 m-0'></ion-icon>"
                : "<ion-icon name='checkmark-circle-outline' data-id="+data.id+" class='btnPrepareReception btn tx-24 tx-success p-0 m-0' disabled></ion-icon>";
          }
        },
        // {text:"Recepcionar", data: "receptionStatus", width: "5%",
        //   render: (data) => {
        //     return (Number(data.status)<3)
        //       ? "<ion-icon name='chevron-forward-circle-outline' data-id="+data.id+" class='btn tx-24 tx-clomos-midtone opacity-50 p-0 m-0' disabled></ion-icon>"
        //       : (Number(data.status)===3)
        //         ? "<ion-icon name='chevron-forward-circle-outline' data-id="+data.id+" class='btnEndReception btn tx-24 tx-primary p-0 m-0'></ion-icon>"
        //         : "<ion-icon name='checkmark-circle-outline' data-id="+data.id+" class='btnExecutedReception btn tx-24 tx-success p-0 m-0' disabled></ion-icon>";
        //   }
        // },
        {text:"Eliminar", data: "receptionStatus", width: "5%",
          render: (data) => {
            return (Number(data.status)===0)
              ? "<ion-icon name='trash-outline' data-id="+data.id+" class='btnDeleteReception btn tx-24 tx-primary p-0 m-0'></ion-icon>"
              : "<ion-icon name='trash-outline' data-id="+data.id+" class='btn tx-24 tx-clomos-midtone opacity-50 p-0 m-0' disabled></ion-icon>";
          }
        }
      ],
      targetReceptionId: "",
      receptionList: [],
      alertValue: '',
      bulgeInput: {
        volume: {
          height: 0,
          width: 0,
          depth: 0
        },
        units: 0
      },
      bulgeList: [],
      display: {
        newReceptionModal: false,
        planReceptionModal: false,
        prepareReceptionModal: false,
        endReceptionModal: false,
        deleteReceptionModal: false
      }
    }
  },
  computed: {
    targetReception() {
      for (let i in this.receptionList) {
        if (this.receptionList[i].id === this.targetReceptionId)
          return this.receptionList[i];
      }
      return {};
    },
  },
  emits: ["setCurrentCompany", "getAvailableCompanies"],
  methods: {
    // DISPLAY
    parseDateUserTimezone(date) {
      let formatedDate = "-";
      let parsedDate = (date!="")
        ?this.$dayjs(date).utc("z").local().tz(this.session.user.dateTimeZone).$d
        :""; 
      if (parsedDate!="") {
        let d = new Date(parsedDate);
        switch (this.session.user.dateTimeFormat) {
          case "DD/MM/YYYY": 
            formatedDate = String(d.getDate()).padStart(2,"0")+"/"+String((d.getMonth()+1)).padStart(2,"0")+"/"+String(d.getFullYear())+" "+String(d.getHours()).padStart(2,"0")+":"+String(d.getMinutes()).padStart(2,"0");
            break;
          case "MM/DD/YYYY": 
            formatedDate = String((d.getMonth()+1)).padStart(2,"0")+"/"+String(d.getDate()).padStart(2,"0")+"/"+String(d.getFullYear())+" "+String(d.getHours()).padStart(2,"0")+":"+String(d.getMinutes()).padStart(2,"0");
            break;
          default:
            formatedDate = String((d.getMonth()+1)).padStart(2,"0")+"/"+String(d.getDate()).padStart(2,"0")+"/"+String(d.getFullYear())+" "+String(d.getHours()).padStart(2,"0")+":"+String(d.getMinutes()).padStart(2,"0");
            break;
        }
      }
      return formatedDate;
    },
    onCurrentCompanyChanged(event) {
      let value = event.target.value;
      this.$router.replace({params: {currentCompanyNifCif: value}})
      this.$emit('setCurrentCompany',value);
    },
    displayReceptionsDataTable() {
      $("#receptionsDataTable").DataTable().clear()
      for (let i in this.receptionList) {
        $("#receptionsDataTable").DataTable().rows.add([{
          receptionId: this.receptionList[i].id, 
          receptionType: this.receptionList[i].type,
          receptionStatus: {id: this.receptionList[i].id, status: this.receptionList[i].status, plannedDateTime: this.receptionList[i].plannedDateTime}, 
          receptionExecutionDateTime: (this.receptionList[i].executionDateTime != undefined) ? new Date(this.receptionList[i].executionDateTime) : "",
          receptionCreationDateTime: (this.receptionList[i].creationDateTime != undefined) ? new Date(this.receptionList[i].creationDateTime) : "",
          receptionPlannedDateTime: (this.receptionList[i].plannedDateTime != undefined) ? new Date(this.receptionList[i].plannedDateTime) : "",
          receptionExecutionEndDateTime: (this.receptionList[i].executionEndDateTime != undefined) ? new Date(this.receptionList[i].executionEndDateTime) : ""
        }]);
      }
      $("#receptionsDataTable").DataTable().draw();
      this.setButtonFunctions();
      if($(".dataTables_paginate").get()[0] != undefined) $(".dataTables_paginate").get()[0].addEventListener('click', this.setButtonFunctions);
      $(".sorting").get().forEach((btn)=> { btn.addEventListener('click', this.setButtonFunctions)});
    },
    async toggleNewReceptionModal() {
      if (this.display.newReceptionModal) {
        await this.getReceptionList();
        this.displayReceptionsDataTable();
      }
      this.display.newReceptionModal = !this.display.newReceptionModal;
    },
    async togglePlanReceptionModal() {
      if (this.display.planReceptionModal) {
        await this.getReceptionList();
        this.displayReceptionsDataTable();
      }
      this.display.planReceptionModal = !this.display.planReceptionModal;
    },
    async togglePrepareReceptionModal() {
      if (this.display.prepareReceptionModal) {
        await this.getReceptionList();
        this.displayReceptionsDataTable();
      }
      this.display.prepareReceptionModal = !this.display.prepareReceptionModal;
    },
    async toggleEndReceptionModal() {
      if (this.display.endReceptionModal) {
        await this.getReceptionList();
        this.displayReceptionsDataTable();
      }
      this.display.endReceptionModal = !this.display.endReceptionModal;
    },
    async toggleDeleteReceptionModal() {
      if (this.display.deleteReceptionModal) {
        await this.getReceptionList();
        this.displayReceptionsDataTable();
      }
      this.display.deleteReceptionModal = !this.display.deleteReceptionModal;
    },
    // BUTTONS
    setButtonFunctions() {
      setTimeout(()=>{
        $(".btnTargetReception").get().forEach(btn => {
          btn.addEventListener('click', this.onTargetReception);
        });
        $(".btnDetailReception").get().forEach(btn => {
          btn.addEventListener('click', this.onDetailReception)
        });
        $(".btnPlanReception").get().forEach(btn => {
          btn.addEventListener('click', this.onPlanReception);
        }); 
        $(".btnPrepareReception").get().forEach(btn => {
          btn.addEventListener('click', this.onPrepareReception);
        }); 
        $(".btnEndReception").get().forEach(btn => {
          btn.addEventListener('click', this.onEndReception);
        });
        $(".btnDeleteReception").get().forEach(btn => {
          btn.addEventListener('click', this.onDeleteReception);
        });
      }, 300);
    },
    // GET
    async getReceptionList(callback) {
      let request = {
        nifCif: this.session.user.nifCif
      };
      let entryListResponse = await api.getReceptionList(request);
      let entryList = api.parseOrdersFromResponse(entryListResponse)
      let refundListResponse = await api.getRefundList(request);
      let refundList = api.parseOrdersFromResponse(refundListResponse);
      
      this.receptionList = entryList.concat(refundList);
      this.receptionList.sort((a,b)=>a.creationDateTime.toUpperCase()>b.creationDateTime.toUpperCase());

      if (callback && typeof callback === "function") callback(this.receptionList);
    },
    // TARGET 
    onTargetReception(event) {
      console.log('TARGET WHERE ID:');
      console.log(event.target.getAttribute('data-id'));
      this.targetReceptionId = event.target.getAttribute('data-id');
    },
    // PLAN
    onUpdateAlertValue(value) {
      this.alertValue = value;
    },
    async onPlanReception(event) {
      if (!this.display.planReceptionModal) {
        console.log('PLAN WHERE ID:');
        console.log(event.target.getAttribute('data-id'));
        this.targetReceptionId = event.target.getAttribute('data-id');
      }
      else {
        if (this.alertValue==='') this.alertValue = new Date();
        let statusData = {
          orderId : this.targetReceptionId,
          dateTime : this.alertValue,
          status : 1
        }
        let response = await api.setOrderStatus(statusData);
        if (response["status-code"] === "200") {
          window.alert("La recepción ha sido planeada satisfactoriamente.");
        }
        else {
          window.alert("Error al planear el recepción.");
        }
      }
      this.togglePlanReceptionModal();
    },
    // PREPARE
    onPrepareReception(event) {
      console.log('PREPARE WHERE ID:');
      console.log(event.target.getAttribute('data-id'));
      this.targetReceptionId = event.target.getAttribute('data-id')
      this.togglePrepareReceptionModal();
    },
    // DETAILS
    onDetailReception(event) {
      console.log('DETAIL WHERE ID:');
      console.log(event.target.getAttribute('data-id'));
      this.targetReceptionId = event.target.getAttribute('data-id')
      var tr = $(event.target).closest('tr');
      var row = $("#receptionsDataTable").DataTable().row(tr);
      if (row.child.isShown()) {
        event.target.name = "list-circle-outline";
        row.child.hide();
        tr.removeClass('shown');
      } 
      else {
        event.target.name = "chevron-up-circle-outline";
        let content = '' 
        content = content +
          '<table id="detailTable'+this.targetReceptionId+'" cellspacing="0" class="border-1" style="width:100%; margin: 0;">' +
            '<tr>' +
              '<td class="tx-bold col-3">Referencia</td>' +
              '<td class="tx-bold">Nombre</td>' +
              '<td class="tx-bold">Cantidad</td>' +
              '<td class="tx-bold">Lote</td>' +
              '<td class="tx-bold">Nº de serie</td>' +
              '<td class="tx-bold">Talla</td>' +
              '<td class="tx-bold">Color</td>' +
              '<td class="tx-bold">Fecha de caducidad</td>' +
            '</tr>';
          for (let i in this.targetReception.params.productos) {
            let product = this.targetReception.params.productos[i];
            content = content + '<tr>';
              if (product.referencia!=undefined&&product.referencia!="") content = content + '<td>' + product.referencia + '</td>'
                else content = content + '<td>-</td>';
              if (product.etiqueta!=undefined&&product.etiqueta!="") content = content + '<td>' + product.etiqueta + '</td>'
                else content = content + '<td>-</td>';
              if (product.unidades!=undefined&&product.unidades!="") content = content + '<td>' + product.unidades + '</td>'
                else content = content + '<td>-</td>';
              if (product.lote!=undefined&&product.lote!="") content = content + '<td>' + product.lote + '</td>'
                else content = content + '<td>-</td>';
              if (product.numeroSerie!=undefined&&product.numeroSerie!="") content = content + '<td>' + product.numeroSerie + '</td>'
                else content = content + '<td>-</td>';
              if (product.talla!=undefined&&product.talla!="") content = content + '<td>' + product.talla + '</td>'
                else content = content + '<td>-</td>';
              if (product.color!=undefined&&product.color!="") content = content + '<td>' + product.color + '</td>'
                else content = content + '<td>-</td>';
              if (product.fechaCaducidad!=undefined&&product.fechaCaducidad!="") content = content + '<td>' + product.fechaCaducidad + '</td>'
                else content = content + '<td>-</td>';
            '</tr>';
          }
          content = content + '</table>';
        row.child(content).show();
        document.getElementById('detailTable'+this.targetReceptionId).parentNode.style.padding = 0;
        tr.addClass('shown');
      }
    },
    // END
    async onEndReception(event) {
      if (!this.display.endReceptionModal) {
        console.log('END WHERE ID:');
        console.log(event.target.getAttribute('data-id'));
        this.targetReceptionId = event.target.getAttribute('data-id');
      }
      else {
        let statusData = {
          orderId : this.targetReceptionId,
          dateTime : new Date(),
          status : 4
        }
        let response = await api.setOrderStatus(statusData);
        if (response["status-code"] === "200") {
          this.$emit('toggleNewReceptionModal');
          window.alert("La recepción ha finalizado satisfactoriamente.");
        }
        else {
          window.alert("Error al finalizar la recepción.");
        }
      }
      this.toggleEndReceptionModal();
    },
    // DELETE
    async onDeleteReception(event) {
      if (!this.display.deleteReceptionModal) {
        console.log('DELETE WHERE ID:');
        console.log(event.target.getAttribute('data-id'));
        this.targetReceptionId = event.target.getAttribute('data-id');
      }
      else {
        let response = await api.deleteOrder(this.targetReceptionId);
        if (response["status-code"] === "200") {
          this.$emit('toggleNewReceptionModal');
          window.alert("La recepción ha sido eliminada satisfactoriamente.");
        }
        else {
          window.alert("Error al eliminar la recepción.");
        }
      }
      this.toggleDeleteReceptionModal();
    }
  },
  async created() {
    await this.getReceptionList()
    this.$emit('getAvailableCompanies',(this.displayReceptionsDataTable));
  },
  mounted() {
    $("#receptionsDataTable").DataTable(
      {
        pagingType: 'full_numbers',
        pageLength: 10,
        processing: true,
        dom: 'Bfrtip',
        buttons: [
          { "extend": 'colvis', "className": 'btn' },
          { "extend": 'copy', "className": 'btn ms-auto' },
          { "extend": 'csv', "className": 'btn' },
          { "extend": 'print', "className": 'btn me-0' },
        ],
        columns: this.receptionsColumns,
        select: this.dataTableOptions.select,
        language: this.dataTableOptions.language[this.session.user.language],
        //stateSave: this.dataTableOptions.stateSave,
        order: [[4,'desc']]
      }
    );
    $(".dataTables_filter").get()[0].getElementsByTagName('input')[0].addEventListener('blur', this.setButtonFunctions);
  }
}
</script>
<style>
#prepareReceptionModal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99999;
  background-color: rgba(0, 0, 0, 0.5);
  width: 100vw;
  height: 100vh;
}

.btnDetailReception:hover, .btnPlanReception:hover,  .btnPrepareReception:hover, .btnEndReception:hover, .btnDeleteReception:hover {
  color: var(--main-dark);
}
.btnPlannedReception:hover, .btnPreparedReception:hover, .btnExecutedReception:hover {
  color: var(--main-dark-50);
}
</style>