<template>
<!-- MODAL Edit Device -->
  <div id="editDeviceModal"
  class="col-12 justify-content-center pe-6"
  style="padding: 15vh 20%">
    <div class="col-12 p-0 m-0 bg-caja section-container rounded-5">
<!-- HEAD -->
      <div class="row overflow-hidden col-12 m-0 p-0 text-white bg-clomos rounded-top-5 pb-1 h-100">
        <div class="col-10">
          <h4 class="tx-left tx-clomos-white mb-2 ps-1 pt-2">
            <ion-icon name="hardware-chip-outline" class="tx-27 me-2 align-bottom"></ion-icon>
            {{dictionary['Editar dispositivo'][session.user.language]+": "+editDeviceData.label}}
            <span class="tx-14"> {{ "/ "+editDeviceData.typeName }} </span>
          </h4>
        </div>

        <div class="col-2 m-0 p-0" style="display: flex; justify-content: end;"
        @click="$emit('toggleEditDeviceModal')">	
          <ion-icon name="close" class="ms-auto tx-46 tx-white align-bottom"></ion-icon>
        </div>
      </div>

<!-- CONTENT Edit Device -->
      <div class="col-12 p-0 w-100">
        <div style="overflow: scroll; overflow-x: hidden; max-height: 60vh;">
          <div class="card-body">

            <form class="form-material pt-0 overflow-hidden">

              <div class="form-group border-1 col-12 d-flex flex-wrap">

                <div class="col-lg-6 col-md-6 col-12">
                  <label class="col-12 p-0 pt-3 tx-bold tx-clomos-midtone">
                    {{dictionary['Nombre'][session.user.language]}} *
                  </label>
                  <input v-model="editDeviceData.label" id="inputEditDeviceName" type="text" 
                  class="form-control form-control-line" style="color:black;">
                </div>
                
                <div class="col-lg-6 col-md-6 col-12">
                  <label class="col-12 p-0 pt-3 tx-bold tx-clomos-midtone">
                    {{dictionary['Posición'][session.user.language]}}
                  </label>
                  <input v-model="editDeviceData.emplacement" id="inputEditDevicePosition" type="text" 
                  class="form-control form-control-line" style="color:black;">
                </div>

                <div class="col-lg-6 col-12 p-0 m-0">
                </div>

                <div class="col-lg-6 col-12 pb-3">
                  <div class="col-12 mt-4 p-0">
<!-- SUBMIT DEVICE DATA-->
                    <button type="button" id="btnSubmitCreateEditDevice" class="btn btn-clomos w-100"
                    @click="setEditDeviceData">
                      {{dictionary['Guardar cambios'][session.user.language]}}
                    </button>
                  </div>
                </div>

              </div>

              <div class="form-group border-1 mt-3 col-12 d-flex flex-wrap">

                <div v-for="variable in editDeviceData.variables.analog" :key="variable.analogVariableId"
                class="col-lg-3 col-md-6 col-12" :style="(variable.isConfiguration==='true')?'display:block':'display:none'">
                  <div class="m-0 p-0 w-100" v-if="variable.isConfiguration==='true'">
                    <label class="col-12 p-0 pt-3 tx-bold tx-clomos-midtone">
                      {{ variable.label }} 
                      <span class="tx-light text-nowrap ms-auto" v-if="variable.unit != undefined && variable.unit != ''"> {{ "("+variable.unit+")" }} </span>
                    </label>
                    <input v-model="editDeviceData.variables.analog[variable.label].value" :id="'inputEditDevice'+variable.label" type="text" 
                    :data-id="editDeviceData.variables.analog[variable.label].variableId"
                    :data-type="editDeviceData.variables.analog[variable.label].type" class="inputEditDevice form-control form-control-line" style="color:black;">
                  </div>
                </div>
                <div class="col-12"></div>

                <div v-for="variable in editDeviceData.variables.digital" :key="variable.digitalVariableId"
                class="col-lg-3 col-md-6 col-12">
                  <div class="m-0 p-0 w-100" v-if="variable.isConfiguration==='true'">
                    <label class="col-12 p-0 pt-3 tx-bold tx-clomos-midtone">
                      {{variable.label}}
                    </label>
                    <div :id="'inputEditDevice'+variable.label" 
                    :data-id="editDeviceData.variables.digital[variable.label].variableId"
                    :data-type="editDeviceData.variables.digital[variable.label].type"
                    :data-value="editDeviceData.variables.digital[variable.label].value"
                    class="inputEditDevice form-control form-control-line border-0 tx-bold"
                    :class="editDeviceData.variables.digital[variable.label].value"
                    @click="(editDeviceData.variables.digital[variable.label].value==='true')?editDeviceData.variables.digital[variable.label].value='false':editDeviceData.variables.digital[variable.label].value='true'">
                      {{ dictionary[editDeviceData.variables.digital[variable.label].value][session.user.language] }}
                    </div>
                  </div>
                </div>
                <div class="col-12"></div>

                <div v-for="variable in editDeviceData.variables.dynamic" :key="variable.dynamicVariableId"
                class="col-lg-3 col-md-6 col-12">
                  <div class="m-0 p-0 w-100" v-if="variable.isConfiguration==='true'">
                    <label class="col-12 p-0 pt-3 tx-bold tx-clomos-midtone">
                      {{variable.label}}
                    </label>
                    <input v-model="editDeviceData.variables.dynamic[variable.label].value" 
                    :id="'inputEditDevice'+variable.label" type="text" 
                    :data-id="editDeviceData.variables.dynamic[variable.label].variableId"
                    :data-type="editDeviceData.variables.dynamic[variable.label].type" class="inputEditDevice form-control form-control-line" style="color:black;">
                  </div>
                </div>
                <div class="col-12"></div>

                <div class="col-lg-6 col-12 p-0 m-0">
                </div>

                <div class="form-group col-lg-6 col-12 p-0 pb-3">
                  <div class="col-12 mt-4">
<!-- SUBMIT SETTINGS-->
                  <button type="button" id="btnSubmitCreateEditDevice" class="btn btn-clomos w-100"
                  @click="setDeviceVariables">
                    {{dictionary['Guardar configuración'][session.user.language]}}
                  </button>
                </div>
              </div>

<!-- FORM My Profile (End) -->
              </div>  
            
            </form>
              
          </div>
        </div>
      </div>
      
    </div>
  </div>

</template>

<script>
import $ from 'jquery';
// Clomos
import api from '@/services/api';

export default {
  name: "EditDeviceModal",
  props: {
    session: {
      type: Object,
      requiered: true,
    },
    dictionary: {
      type: Object,
      requiered: true,
    },
    countries: {
      type: Object,
      requiered: true,
    },
    timeZones: {
      type: Object,
      requiered: true,
    },
    deviceId: {
      type: String,
      requiered: true,
    },
  },
  data() {
    return {
      editDeviceData : {
        id: "",
        nifCif: "",
        dataConsumed: "",
        label: "",
        dclId: "",
        dclTypeId: "",
        dclClass: "",
        typeName: "",
        distributionGroup: "",
        parent: "",
        isBase: "",
        isPublic: 0,
        token: "",
        imgSrc: "",
        emplacement: "",
        position: "",
        fechaUltimaComunicacion: "",
        tieneAlarmaActivada: "",
        version: "",
        variables: {
          analog: {},
          digital: {},
          dynamic: {},
        }
      }
    }
  },
  emits: ["toggleEditDeviceModal"],
  methods: {
    // SET
    async setEditDeviceData() {
      let response = await api.setDeviceData(this.editDeviceData);
      if (response["status-code"] === "200") {
        window.alert("El dispositivo ha sido modificado satisfactoriamente.");
      }
      else {
        window.alert("Error al modificar el dispositivo.");
      }
      this.getEditDeviceData();
    },
    async setDeviceVariables() {
      let variablesData = {
        deviceId : this.editDeviceData.id
      };
      $(".inputEditDevice").get().forEach(input => {
        variablesData[input.id.replace("inputEditDevice","")] = {
          value: (input.getAttribute("data-type")==="0")
            ?(input.getAttribute("data-value")==="true")
              ?1
              :0
            :input.value,
          id: input.getAttribute("data-id"),
          type: input.getAttribute("data-type")}
      });
      let response = await api.setDeviceVariables(variablesData);
      if (response["status-code"] === "200") {
        window.alert("El dispositivo ha sido modificado satisfactoriamente.");
      }
      else {
        window.alert("Error al modificar el dispositivo.");
      }
      this.getEditDeviceData();
    },
    // GET 
    async getEditDeviceData(callback) {
      let response = await api.getDeviceData(this.deviceId);
      this.editDeviceData = api.parseDeviceFromResponse(response);
      if (callback && typeof callback === "function") callback(this.deviceData);
    }
  },
  async created() {
    await this.getEditDeviceData();
  }
}
</script>

<style>

#editDeviceModal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99999;
  background-color: rgba(0, 0, 0, 0.5);
  width: 100vw;
  height: 100vh;
}
</style>