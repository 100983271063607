<template>
  <!-- MODAL Edit Bundle -->
    <div id="editBundleModal"
    class="col-12 justify-content-center pe-6"
    style="padding: 15vh 20%">
      <div class="col-12 p-0 m-0 bg-caja section-container rounded-5">
  <!-- HEAD -->
        <div class="row overflow-hidden col-12 m-0 p-0 text-white bg-clomos rounded-top-5 pb-1 h-100">
          <div class="col-10">
            <h4 class="tx-left tx-clomos-white mb-2 ps-1 pt-2">
              <ion-icon name="grid-outline" class="tx-27 me-2 align-bottom"></ion-icon>
              {{dictionary['Editar conjunto'][session.user.language]+": "+editBundleData.label}}
              <span class="tx-14"> {{ "/ "+editBundleData.typeName }} </span>
            </h4>
          </div>
  
          <div class="col-2 m-0 p-0" style="display: flex; justify-content: end;"
          @click="$emit('toggleEditBundleModal')">	
            <ion-icon name="close" class="ms-auto tx-46 tx-white align-bottom"></ion-icon>
          </div>
        </div>
  
  <!-- CONTENT Edit Bundle -->
        <div class="col-12 p-0 w-100">
          <div style="overflow: scroll; overflow-x: hidden; max-height: 60vh;">
            <div class="card-body">
  
              <form class="form-material pt-0 overflow-hidden">
  
                <div class="form-group border-1 col-12 d-flex flex-wrap">
  
                  <!-- NOMBRE -->
                  <div class="form-group col-md-6 col-12 p-1">
                    <label class="col-12 tx-bold tx-clomos-midtone">
                      {{dictionary['Nombre'][session.user.language]}}
                    </label>
                    <div class="col-md-12">
                      <input readonly v-model="editBundleData.label" id="inputEditBundleId" type="text" 
                      class="form-control form-control-line" style="color:black;">
                    </div>
                  </div>
                  
                  <!-- TIPO -->
                  <div class="form-group col-md-6 col-12 p-1">
                    <label class="col-12 tx-bold tx-clomos-midtone">
                      {{dictionary['Tipo'][session.user.language]}}
                    </label>
                    <div class="col-md-12">
                      <input readonly v-model="editBundleData.typeName" id="inputEditBundleId" type="text" 
                      class="form-control form-control-line" style="color:black;">
                    </div>
                  </div>

                  <!-- POSICIÓN -->
                  <div class="form-group col-md-3 col-12 p-1">
                    <label class="col-12 tx-bold tx-clomos-midtone">
                      {{dictionary['Fila'][session.user.language]}}
                    </label>
                    <div class="col-md-12">
                      <input v-model="editBundleData.geoJSON.geometry.coordinates[0]" id="inputEditBundleId" type="number" min=0
                      class="form-control form-control-line" style="color:black;">
                    </div>
                  </div>
                  <div class="form-group col-md-3 col-12 p-1">
                    <label class="col-12 tx-bold tx-clomos-midtone">
                      {{dictionary['Columna'][session.user.language]}}
                    </label>
                    <div class="col-md-12">
                      <input v-model="editBundleData.geoJSON.geometry.coordinates[1]" id="inputEditBundleId" type="number" min=0
                      class="form-control form-control-line" style="color:black;">
                    </div>
                  </div>

                  <!-- MÁQUINA -->
                  <div class="form-group col-md-6 col-12 p-1">
                    <label class="col-12 tx-bold tx-clomos-midtone">
                      {{dictionary['Máquina'][session.user.language]}}
                    </label>
                    <div class="col-md-12">
                      <input readonly v-model="editBundleData.machineLabel" id="inputEditBundleId" type="text" 
                      class="form-control form-control-line" style="color:black;">
                    </div>
                  </div>
  
                  <div class="form-group col-lg-6 col-12 p-0 pb-3 ms-auto">
                    <div class="col-12 mt-4">
  <!-- SUBMIT SETTINGS-->
                      <button type="button" id="btnSubmitCreateEditBundle" class="btn btn-clomos w-100"
                      @click="setEditBundleData">
                        {{dictionary['Guardar cambios'][session.user.language]}}
                      </button>
                    </div>
                  </div>

                </div>
  
                <div class="form-group border-1 mt-3 pb-3 col-12 d-flex flex-wrap">
  
                  <div v-for="variable in editBundleData.variables.analog" :key="variable.analogVariableId"
                  class="col-lg-3 col-md-6 col-12" :style="(variable.isConfiguration==='false')?'display:block':'display:none'">
                    <div class="m-0 p-0 w-100" v-if="variable.isConfiguration==='false'">
                      <label class="col-12 p-0 pt-3 tx-bold tx-clomos-midtone">
                        {{variable.label}}
                      </label>
                      <select v-if="variable.label=='estado'" readonly 
                      v-model="editBundleData.variables.analog[variable.label].value" :id="'inputEditBundle'+variable.label"
                      :data-id="editBundleData.variables.analog[variable.label].variableId"
                      :data-type="editBundleData.variables.analog[variable.label].type" class="inputEditBundle form-control form-control-line" style="color:black;">
                        <option value="0">
                          {{dictionary['estadoConjunto0'][session.user.language]}}
                        </option>
                        <option value="1">
                          {{dictionary['estadoConjunto1'][session.user.language]}}
                        </option>
                        <option value="2">
                          {{dictionary['estadoConjunto2'][session.user.language]}}
                        </option>
                        <option value="0">
                          {{dictionary['estadoConjunto3'][session.user.language]}}
                        </option>
                      </select>
                      <input v-else readonly 
                      v-model="editBundleData.variables.analog[variable.label].value" :id="'inputEditBundle'+variable.label" type="text" 
                      :data-id="editBundleData.variables.analog[variable.label].variableId"
                      :data-type="editBundleData.variables.analog[variable.label].type" class="inputEditBundle form-control form-control-line" style="color:black;">
                    </div>
                  </div>
<!--                  
                  <div class="form-group col-lg-6 col-12 p-0 pb-3 ms-auto">
                    <div class="col-12 mt-4">
-->
<!-- SUBMIT VARIABLES-->
<!--                  
                      <button type="button" id="btnSubmitCreateEditBundle" class="btn btn-clomos w-100"
                      @click="setBundleVariables">
                        {{dictionary['Guardar configuración'][session.user.language]}}
                      </button>
                    </div>
                  </div>
-->

                  <div class="col-12"></div>
  
                  <div v-for="variable in editBundleData.variables.digital" :key="variable.digitalVariableId"
                  class="col-lg-3 col-md-6 col-12">
                    <div class="m-0 p-0 w-100" v-if="variable.isConfiguration==='true'">
                      <label class="col-12 p-0 pt-3 tx-bold tx-clomos-midtone">
                        {{variable.label}}
                      </label>
                      <div :id="'inputEditBundle'+variable.label" 
                      :data-id="editBundleData.variables.digital[variable.label].variableId"
                      :data-type="editBundleData.variables.digital[variable.label].type"
                      :data-value="editBundleData.variables.digital[variable.label].value"
                      class="inputEditBundle form-control form-control-line border-0 tx-bold"
                      :class="editBundleData.variables.digital[variable.label].value"
                      @click="(editBundleData.variables.digital[variable.label].value==='true')?editBundleData.variables.digital[variable.label].value='false':editBundleData.variables.digital[variable.label].value='true'">
                        {{ dictionary[editBundleData.variables.digital[variable.label].value][session.user.language] }}
                      </div>
                    </div>
                  </div>

                  <div class="col-12"></div>
  
                  <div v-for="variable in editBundleData.variables.dynamic" :key="variable.dynamicVariableId"
                  class="col-lg-3 col-md-6 col-12">
                    <div class="m-0 p-0 w-100" v-if="variable.isConfiguration==='true'">
                      <label class="col-12 p-0 pt-3 tx-bold tx-clomos-midtone">
                        {{variable.label}}
                      </label>
                      <input v-model="editBundleData.variables.dynamic[variable.label].value" 
                      :id="'inputEditBundle'+variable.label" type="text" 
                      :data-id="editBundleData.variables.dynamic[variable.label].variableId"
                      :data-type="editBundleData.variables.dynamic[variable.label].type" class="inputEditBundle form-control form-control-line" style="color:black;">
                    </div>
                  </div>
  
  <!-- FORM My Profile (End) -->
                </div>  
              <!--VARIABLE NO CONFIGURACION-->    
                  <div class="form-group border-1 mt-3 pb-3 col-12 d-flex flex-wrap">
                    <div v-for="variable in editBundleData.variables.analog" :key="variable.analogVariableId"
                    class="col-lg-3 col-md-6 col-12" :style="(variable.isConfiguration==='true')?'display:block':'display:none'">
                      <div class="m-0 p-0 w-100" v-if="variable.isConfiguration==='true'">
                        <label class="col-12 p-0 pt-3 tx-bold tx-clomos-midtone">
                          {{variable.label}}
                        </label>
                        <input readonly v-model="editBundleData.variables.analog[variable.label].value" :id="'inputEditBundle'+variable.label" type="text" 
                        :data-id="editBundleData.variables.analog[variable.label].variableId"
                        :data-type="editBundleData.variables.analog[variable.label].type" class="inputEditBundle form-control form-control-line" style="color:black;">
                      </div>
                    </div>
                    <div class="col-lg-6 col-12 p-0 m-0"></div>
                  </div>
              </form>
                
            </div>
          </div>
        </div>
        
      </div>
    </div>
  
  </template>
  
  <script>
  import $ from 'jquery';
  // Clomos
  import api from '@/services/api';
  
  export default {
    name: "EditBundleModal",
    props: {
      session: {
        type: Object,
        requiered: true,
      },
      dictionary: {
        type: Object,
        requiered: true,
      },
      countries: {
        type: Object,
        requiered: true,
      },
      timeZones: {
        type: Object,
        requiered: true,
      },
      bundleId: {
        type: String,
        requiered: true,
      },
      targetMachineId: {
        type: String,
        requiered: true,
      },
    },
    data() {
      return {
        editBundleData : {
          id: "",
          nifCif: "",
          dataConsumed: "",
          label: "",
          dclId: "",
          dclTypeId: "",
          dclClass: "",
          typeName: "",
          distributionGroup: "",
          parent: "",
          machineLabel: "",
          isBase: "",
          isPublic: 0,
          token: "",
          imgSrc: "",
          emplacement: "",
          fechaUltimaComunicacion: "",
          tieneAlarmaActivada: "",
          version: "",
          geoJSON: { 
            type: "Feature",
            geometry: {
              type: "Point",
              coordinates: [0,0]
            }
          },
          variables: {
            analog: {},
            digital: {},
            dynamic: {},
          }
        },
        
      }
    },
    emits: ["toggleEditBundleModal"],
    methods: {
      // SET
      async setEditBundleData() {
        let response = await api.setBundleData(this.editBundleData);
        if (response["status-code"] === "200") {
          window.alert("El conjunto ha sido modificado satisfactoriamente.");
        }
        else {
          window.alert("Error al modificar el conjunto.");
        }
        this.getEditBundleData();
      },
      async setBundleVariables() {
        let variablesData = {
          bundleId : this.editBundleData.id
        };
        $(".inputEditBundle").get().forEach(input => {
          variablesData[input.id.replace("inputEditBundle","")] = {
            value: (input.getAttribute("data-type")==="0")
              ?(input.getAttribute("data-value")==="true")
                ?1
                :0
              :input.value,
            id: input.getAttribute("data-id"),
            type: input.getAttribute("data-type")}
        });
        let response = await api.setBundleVariables(variablesData);
        if (response["status-code"] === "200") {
          window.alert("El conjunto ha sido modificado satisfactoriamente.");
        }
        else {
          window.alert("Error al modificar el conjunto.");
        }
        this.getEditBundleData();
      },
      // GET 
      async getEditBundleData(callback) {
        let response = await api.getBundleData(this.bundleId);
        this.editBundleData = api.parseBundleFromResponse(response);
        let responseMachine = await api.getMachineData(this.editBundleData.parent);
        this.editBundleData.machineLabel = responseMachine.maquina.etiqueta;
        if (callback && typeof callback === "function") callback(this.editBundleData);
      }
    },
    async created() {
      await this.getEditBundleData();
    }
  }
  
  </script>
  
  <style>
  
  #editBundleModal {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 99999;
    background-color: rgba(0, 0, 0, 0.5);
    width: 100vw;
    height: 100vh;
  }
  </style>