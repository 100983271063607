<template>
<!-- SECTION SiteMap -->
  <div id="siteMapSection" class="col-12 justify-content-center pe-3"
  :class="(visibility.sideMenu)?'section-container-sm' :'section-container-lg'">
    <div class="row col-12 p-0 m-0 bg-caja section-container rounded-5">
      
      <div class="row overflow-hidden col-12 m-0 p-0 text-white bg-clomos rounded-top-5 pb-1 h-100" >
        <!--<ion-icon v-if="session.user.bookmark==='emplacements'" name="bookmark" class="bookmark-button"></ion-icon>
        <ion-icon v-else name="bookmark-outline" class="bookmark-button"></ion-icon>-->

        <div class="col-lg-4 col-md-12 col-12 justify-content-center" style="display: inherit">
          <!-- HEADER Map -->
          <h4 v-if="displayType==='map'" class="tx-left tx-clomos-white text-nowrap mb-2 ps-1 pt-2">
            <ion-icon name="location-outline" class="tx-27 align-bottom"></ion-icon>
            {{dictionary["Emplazamientos"][session.user.language] +": "+ dictionary["Mapa"][session.user.language]}}
          </h4>
          <!-- HEADER List -->
          <h4 v-if="displayType==='list'" class="tx-left tx-clomos-white text-nowrap mb-2 ps-1 pt-2">
            <ion-icon name="location-outline" class="tx-27 align-bottom"></ion-icon>
            {{dictionary["Emplazamientos"][session.user.language]+": "+dictionary["Listado"][session.user.language]}}
          </h4>
          <!-- HEADER Cards -->
          <h4 v-if="displayType==='cards'" class="tx-left tx-clomos-white text-nowrap mb-2 ps-1 pt-2">
            <ion-icon name="location-outline" class="tx-27 align-bottom"></ion-icon>
            {{dictionary["Emplazamientos"][session.user.language] +": "+ dictionary["Tarjetas"][session.user.language]}}
          </h4>
        </div>

        <!-- HEADER Display Type -->
        <div class="d-flex justify-content-between col-lg-2 col-md-6 col-12 p-0 ps-1 pe-1 m-0">	
          <div class="w-third p-1 pt-2">	
            <button @click="displayType='map'"
            :style="(displayType==='map')?'border-color: coral' :'border-color: white'"
            class="col-xl-4 col-md-4 col-sm-12 header-tab-button btn bg-clomos rounded p-0 text-nowrap">
              <ion-icon name="map-outline" 
              :style="(displayType==='map')?'color: coral':'color: white'"
              class="tx-23" style="vertical-align: middle; margin-top: -0.25rem;"></ion-icon>
            </button>
          </div>
          <div class="w-third p-1 pt-2">	
            <button @click="displayType='list'"
            :style="(displayType==='list')?'border-color: coral' :'border-color: white'"
            class="col-xl-4 col-md-4 col-sm-12 header-tab-button btn bg-clomos rounded p-0 text-nowrap">
              <ion-icon name="list-outline" 
              :style="(displayType==='list')?'color: coral':'color: white'"
              class="tx-23" style="vertical-align: middle; margin-top: -0.25rem;"></ion-icon>
            </button>
          </div>
          <div class="w-third p-1 pt-2">	
            <button @click="displayType='cards'"
            :style="(displayType==='cards')?'border-color: coral' :'border-color: white'"
            class="col-xl-4 col-md-4 col-sm-12 header-tab-button btn bg-clomos rounded p-0 text-nowrap">
              <ion-icon name="documents-outline" 
              :style="(displayType==='cards')?'color: coral':'color: white'"
              class="tx-23" style="vertical-align: middle; margin-top: -0.25rem;"></ion-icon>
            </button>
          </div>
        </div>

        <div class="col-lg-3 col-md-6 col-12 p-2 pb-1 justify-content-center">	
          <select
          :value="currentCompany.nifCif"
          class="form-control h-100 text-nowrap bg-white" 
          style="border: solid 2px white ; color: black;"
          @change="onCurrentCompanyChanged">
            <option v-if="availableCompanies[0]!=undefined" value="showAll"> {{ dictionary['Mostrar todo'][session.user.language] }} </option>
            <option v-for="company in availableCompanies" :key="company.nifCif"
            :v-if="company.nifCif != session.user.nifCif
            && company.relation != undefined
            && company.relation.referenceCompany === this.session.user.nifCif"
            :value="company.nifCif">
              {{(company.nifCif===session.user.nifCif)?"✪ "+company.name :company.name}}
            </option>
          </select>
        </div>


        <div class="col-lg-3 col-md-12 col-12 p-2 pb-1">	
          <button id="btnDisplayNewDeviceModal"
          class="btn btn-clomos bg-white rounded p-1 inner-border fw-normal text-nowrap"
          style="width: 100%; height:100%; vertical-align: middle; border: solid 2px white"
          @click="toggleNewEmplacementModal">
            <ion-icon name="add-circle-outline" class="tx-clomos-dark me-2 tx-20" style="vertical-align: middle; margin-top: -0.25rem;"></ion-icon>
            <span  class="ms-auto tx-clomos-dark">
              {{dictionary["Añadir emplazamiento"][session.user.language]}}
            </span>
          </button>
        </div>
      </div>

      <!-- CONTENT Map -->
      <GoogleMap v-show="displayType==='map'"
      api-key="AIzaSyD1IQjTF3ZPy1I-xfKIytnl0eQ28VuFw-o" 
      :center="viewpoint.center" 
      :zoom="viewpoint.zoom"
      id="emplacementsMapContent" class="m-0 p-3" style="width: 100%; height: 500px">
        <InfoWindow v-for="emplacement in emplacementList" :key="emplacement"
        :options="{ position: { 
          lat: parseFloat(emplacement.geoJSON.geometry.coordinates[0]), 
          lng: parseFloat(emplacement.geoJSON.geometry.coordinates[1])  } }">
          <div class="d-flex flex-column" style="min-width: 9rem;">
            <text :data-id="emplacement.id" class="btnTargetEmplacement btn tx-bold tx-grey m-0 p-0">
              {{emplacement.label}}
            </text>
            <div class="m-0 p-0 d-flex flex-row justify-content-between">
              <text class="w-100"> {{emplacement.location}} </text>
              <ion-icon name="create-outline" :data-id="emplacement.id" class="btnEditEmplacement col-2 m-0 ps-1 p-0 btn tx-24 tx-clomos-midtone float-end"></ion-icon>
              <ion-icon name="trash-outline" :data-id="emplacement.id" class="btnDeleteEmplacement col-2 m-0 p-0 btn tx-24 tx-warning float-end"></ion-icon>
            </div>
          </div>
        </InfoWindow>
      </GoogleMap>

      <!-- CONTENT List -->
      <div v-show="displayType==='list'"
      id="listContent" class="m-0 p-3 overflow-auto">
        <table id="emplacementsDataTable" class="display w-100 border-1 p-0 m-0 rounded-3">
          <thead class="bg-clomos-light">
            <tr>
              <th v-for="column in emplacementsColumns" :key="column.data"
              :width="column.width" class="tx-black fw-medium text-nowrap">
                {{dictionary[column.text][session.user.language]}}
              </th>
            </tr>
          </thead>
        </table>
      </div>

      <!-- CONTENT Cards -->
      <div v-show="displayType==='cards'&&this.targetEmplacementId!=''"
      id="siteCardsContent" class="col-12 row m-0 p-2">
        <div v-for="emplacement in emplacementList" :key="emplacement"
        class="col-xl-3 col-md-6 col-12 p-2">
          <h5 class="bg-clomos-midtone rounded-top-3 p-1 mb-0 tx-clomos-white text-nowrap overflow-hidden">
            {{emplacement.label}}
          </h5>

          <form class="col-12 form-material border-1 rounded-bottom-3 p-3">
            <img :src="displayMyEmplacementImage(emplacement.imgSrc)" alt="Emplacement Card Picture" class="col-12 m-0 p-0 mb-3">
            <div class="col-12 m-0 p-0 d-flex space-between">
              <text :data-id="emplacement.id" class='btnTargetEmplacement btn col-8 m-0 p-0 tx-bold tx-clomos-midtone'>
                {{emplacement.id}}
              </text>
              <ion-icon name="create-outline" :data-id="emplacement.id" class="btnEditEmplacement col-2 p-0 m-0 btn tx-24 tx-clomos-midtone"></ion-icon>
              <ion-icon name="trash-outline" :data-id="emplacement.id" class="btnDeleteEmplacement col-2 p-0 m-0 btn tx-24 tx-warning"></ion-icon>
            </div>
            <hr class="col-12 mt-3 mb-3">
            <div class="col-12 m-0 p-0 m-auto d-flex space-between">
              <text class="col-12">
                {{emplacement.location}}
              </text>
            </div>
          </form>
        </div>
      </div>

      <div v-show="displayType==='cards'&&this.targetEmplacementId===''" class="p-3">
        <span class="tx-18 tx-bold tx-clomos-midtone"> 
          {{dictionary['No hay emplazamientos disponibles'][session.user.language]}} 
        </span> 
      </div>

    </div>   	
  </div>

<!-- SECTION Devices -->
  <MachinesComponent v-if="this.$route.params.currentDisplay==='emplacements' && session.authorisation.availableModules.includes('machines')"
  :session="session"
  :dictionary="dictionary"
  :countries="countries"
  :visibility="visibility"
  :currentCompany="currentCompany"
  :targetEmplacementId="targetEmplacementId"
  :dataTableOptions="dataTableOptions">
  </MachinesComponent>

<!-- SECTION Devices -->
  <DevicesComponent v-else-if="this.$route.params.currentDisplay==='emplacements' && session.authorisation.availableModules.includes('devices')"
  :session="session"
  :dictionary="dictionary"
  :countries="countries"
  :visibility="visibility"
  :currentCompany="currentCompany"
  :targetEmplacementId="targetEmplacementId"
  :dataTableOptions="dataTableOptions">
  </DevicesComponent>

<!-- MODAL New Emplacement -->
  <NewEmplacementModal
  v-if="display.newEmplacementModal"
  :session="session"
  :dictionary="dictionary"
  :countries="countries"
  :currentCompany="currentCompany"
  v-on:toggleNewEmplacementModal="toggleNewEmplacementModal">
  </NewEmplacementModal>

<!-- MODAL Edit Emplacement -->
  <EditEmplacementModal
  v-if="display.editEmplacementModal"
  :session="session"
  :dictionary="dictionary"
  :countries="countries"
  :currentCompany="currentCompany"
  :emplacementId="targetEmplacementId"
  v-on:toggleEditEmplacementModal="toggleEditEmplacementModal">
  </EditEmplacementModal>

<!-- MODAL Delete Text Alert -->
  <TextAlertModal
  v-if="display.deleteEmplacementModal"
  :session="session"
  :dictionary="dictionary"
  :countries="countries"
  :alertHeader="dictionary['Emplazamiento a eliminar'][session.user.language] + ': '"
  :alertTarget="targetEmplacementId"
  v-on:acceptAlert="onDeleteEmplacement"
  v-on:dismissAlert="toggleDeleteEmplacementModal">
  </TextAlertModal>

</template>

<script>
import { GoogleMap, InfoWindow } from 'vue3-google-map';
import $ from 'jquery';
import MachinesComponent from '@/components/machines/MachinesComponent';
import DevicesComponent from '@/components/devices/DevicesComponent';
import NewEmplacementModal from '@/components/emplacements/NewEmplacementModal';
import EditEmplacementModal from '@/components/emplacements/EditEmplacementModal';
import TextAlertModal from '@/components/other/TextAlertModal';
// Clomos
import api from '@/services/api';

export default {
  name: "EmplacementsComponent",
  components: { 
    GoogleMap, InfoWindow, MachinesComponent, DevicesComponent, NewEmplacementModal, EditEmplacementModal, TextAlertModal
  },
  props: {
    session: {
      type: Object,
      requiered: true,
    },
    dictionary: {
      type: Object,
      requiered: true,
    },
    countries: {
      type: Object,
      requiered: true,
    },
    visibility: {
      type: Object,
      requiered: true,
    },
    currentCompany: {
      type: Object,
      requiered: true,
    },
    availableCompanies: {
      type: Object,
      requiered: true,
    },
    dataTableOptions: {
      type: Object,
      requiered: true,
    }
  },
  watch: { 
    currentCompany: async function() {
      await this.getEmplacementList();
      this.$emit('getAvailableCompanies',(this.displayEmplacementsDataTable));
      if (this.emplacementList[0] != undefined) this.targetEmplacementId = this.emplacementList[0].id;
      else this.targetEmplacementId = "";
      this.setButtonFunctions();
    }
  },
  data() {
    return {
      // MAP
      emplacementsColumns: [
        {text:"Identificador", data: "emplacementId", width: "20%",
          render: (data) => {
            return "<span data-id="+data+" class='btnTargetEmplacement btn tx-bold tx-clomos-midtone text-nowrap'>"+data
            +"<ion-icon name='copy-outline' data-id="+data+" class='btnCopyId btn tx-24 tx-clomos-midtone'></ion-icon></span>";
          }
        },
        {text:"Nombre", data: "emplacementLabel", width: "20%"},
        {text:"Localización", data: "emplacementLocation", width: "20%"},
        {text:"Tipo", data: "emplacementType", width: "15%"},
        {text:"Coordenadas", data: "emplacementGeoJSON", width: "15%",
          render: (data) => {
            if (data.geometry!=undefined && data.geometry.type==="Point") return "<span class='tx-clomos-midtone'>Lat:</span> "+Number(data.geometry.coordinates[0]).toFixed(3)+"</br> <span class='tx-clomos-midtone'>Lon:</span> "+Number(data.geometry.coordinates[1]).toFixed(3)
            else return "<span class='tx-clomos-midtone'>Lat:</span> "+0+"</br> <span class='tx-clomos-midtone'>Lon:</span> "+0
          }
        },
        {text:"Editar", data: "emplacementId", width: "5%",
          render: (data) => {
            return "<ion-icon name='create-outline' data-id="+data+" class='btnEditEmplacement btn tx-24 tx-clomos-midtone'></ion-icon>"
          }
        },
        {text:"Eliminar", data: "emplacementId", width: "5%",
          render: (data) => {
            return "<ion-icon name='trash-outline' data-id="+data+" class='btnDeleteEmplacement btn tx-24 tx-warning'></ion-icon>"
          }
        }
      ],
      targetEmplacementId: this.$route.params.targetEntity,
      emplacementList: [],
      displayType : 'map',
      display: {
        newEmplacementModal: false,
        editEmplacementModal: false,
        deleteEmplacementModal: false
      }
    }
  },
  computed: {
    viewpoint() {
      let viewpoint = {
        center: {lng: 0, lat:0},
        zoom: 5,
      }
      let l = undefined;
      let r = undefined;
      let t = undefined;
      let b = undefined;
      if (this.emplacementList[1] != undefined) { // Multiple emplacements
        for (let i in this.emplacementList) if (this.emplacementList[i].geoJSON.geometry!=undefined && this.emplacementList[i].geoJSON.geometry.type==="Point") {
          if (this.emplacementList[i].geoJSON.geometry.coordinates[0] >= t || t === undefined) {
            t = Number(this.emplacementList[i].geoJSON.geometry.coordinates[0]) 
          }
          if(this.emplacementList[i].geoJSON.geometry.coordinates[0] <= b || b === undefined) {
            b = Number(this.emplacementList[i].geoJSON.geometry.coordinates[0]);
          }
          if (this.emplacementList[i].geoJSON.geometry.coordinates[1] >= r || r === undefined) {
            r = Number(this.emplacementList[i].geoJSON.geometry.coordinates[1]) 
          }
          if(this.emplacementList[i].geoJSON.geometry.coordinates[1] <= l || l=== undefined) {
            l = Number(this.emplacementList[i].geoJSON.geometry.coordinates[1]);
          }
        }
        // CENTER
        viewpoint.center.lng = (r+l)/2;
        viewpoint.center.lat = (t+b)/2;
        // ZOOM
        if (Math.abs(t-b) < 10 || Math.abs(r-l) < 10)
          viewpoint.zoom = 6;
        if (Math.abs(t-b) > 10 || Math.abs(r-l) > 10)
          viewpoint.zoom = 5;
        if (Math.abs(t-b) > 20 || Math.abs(r-l) > 20)
          viewpoint.zoom = 4.5;
        if (Math.abs(t-b) > 30 || Math.abs(r-l) > 30)
          viewpoint.zoom = 4;
        if (Math.abs(t-b) > 40 || Math.abs(r-l) > 40)
          viewpoint.zoom = 3.5;
        if (Math.abs(t-b) > 50 || Math.abs(r-l) > 50)
          viewpoint.zoom = 3;
        if (Math.abs(t-b) > 60 || Math.abs(r-l) > 60)
          viewpoint.zoom = 2.5;
        if (Math.abs(t-b) > 70 || Math.abs(r-l) > 70)
          viewpoint.zoom = 2;
        if (Math.abs(t-b) > 80 || Math.abs(r-l) > 80)
          viewpoint.zoom = 1.5;
        if (Math.abs(t-b) > 90 || Math.abs(r-l) > 90)
          viewpoint.zoom = 1;
        if (Math.abs(t-b) > 100 || Math.abs(r-l) > 100)
          viewpoint.zoom = 0.75;
      }
      else if (this.emplacementList[0] != undefined) { // Single emplacement
        viewpoint.center = {lng: parseFloat(this.emplacementList[0].geoJSON.geometry.coordinates[0]), lat:parseFloat(this.emplacementList[0].geoJSON.geometry.coordinates[1])};
        viewpoint.zoom = 10;
      }
      console.log(viewpoint);
      return viewpoint;
    }
  },
  emits: ["getAvailableCompanies","setCurrentCompany"],
  methods: {
    // DISPLAY
    onCurrentCompanyChanged(event) {
      let value = event.target.value;
      this.$router.replace({params: {currentCompanyNifCif: value}})
      this.$emit('setCurrentCompany',value);
    },
    displayEmplacementsDataTable() {
      $("#emplacementsDataTable").DataTable().clear()
      for (let i in this.emplacementList) {
        $("#emplacementsDataTable").DataTable().rows.add([{
          emplacementId: this.emplacementList[i].id, 
          emplacementLabel: this.emplacementList[i].label, 
          emplacementLocation: this.emplacementList[i].location, 
          emplacementType: this.emplacementList[i].typeName, 
          emplacementGeoJSON: this.emplacementList[i].geoJSON,
        }]);
      }
      $("#emplacementsDataTable").DataTable().draw();
      this.setButtonFunctions();
      if($(".dataTables_paginate").get()[0] != undefined) $(".dataTables_paginate").get()[0].addEventListener('click', this.setButtonFunctions);
      $(".sorting").get().forEach((btn)=> { btn.addEventListener('click', this.setButtonFunctions)});
    },
    async toggleNewEmplacementModal() {
      if (this.display.newEmplacementModal) {
        await this.getEmplacementList();
        this.displayEmplacementsDataTable();
      }
      this.display.newEmplacementModal = !this.display.newEmplacementModal;
    },
    async toggleEditEmplacementModal() {
      if (this.display.editEmplacementModal) {
        await this.getEmplacementList();
        this.displayEmplacementsDataTable();
      }
      this.display.editEmplacementModal = !this.display.editEmplacementModal;
    },
    async toggleDeleteEmplacementModal() {
      if (this.display.deleteEmplacementModal) {
        await this.getEmplacementList();
        this.displayEmplacementsDataTable();
        if (this.emplacementList[0] != undefined) this.targetEmplacementId = this.emplacementList[0].id;
        else this.targetEmplacementId = "";
      }
      this.display.deleteEmplacementModal = !this.display.deleteEmplacementModal;
    },
    displayMyEmplacementImage(src) {
      if (src!=undefined && src!='') { 
        try {
          return this.$clientConfig.api + '/ficheros/ficheros.php?fichero='+src+'&identificadorusuario='+this.session.user.id+'&Token='+localStorage.getItem("ClomosAuthToken");
        }
        catch(e) {
          console.warn(e);
        }
      }
      return require('/src/assets/img/placeholder_image.jpg');
    },
    // BUTTONS 
    setButtonFunctions() {
      setTimeout(()=>{
        $(".btnTargetEmplacement").get().forEach(btn => {
          btn.addEventListener('click', this.onTargetEmplacement);
        });
        $(".btnEditEmplacement").get().forEach(btn => {
          btn.addEventListener('click', this.onEditEmplacement);
        });
        $(".btnDeleteEmplacement").get().forEach(btn => {
          btn.addEventListener('click', this.onDeleteEmplacement);
        });
        $(".btnCopyId").get().forEach(btn => {
          btn.addEventListener('click', this.onCopyId);
        });
      }, 300);
    },
    onCopyId(event) {
      navigator.clipboard.writeText(event.target.getAttribute('data-id'));
    },
    // GET
    async getEmplacementList(callback) {
      let request = {
        nifCif: this.currentCompany.nifCif,
        limit: 0,
        offset: 0,  
        where: "",
      };
      let response = await api.getEmplacementList(request);
      this.emplacementList = api.parseEmplacementsFromResponse(response);
      if (callback && typeof callback === "function") callback(this.emplacementList);
    },
    // TARGET 
    onTargetEmplacement(event) {
      console.log('TARGET WHERE ID:');
      console.log(event.target.getAttribute('data-id'));
      this.targetEmplacementId = event.target.getAttribute('data-id')
      this.$router.replace({params: {targetEntity: this.targetEmplacementId}});
    },
    // SET
    onEditEmplacement(event) {
      console.log('SHOW WHERE ID:');
      console.log(event.target.getAttribute('data-id'));
      this.targetEmplacementId = event.target.getAttribute('data-id');
      this.$router.replace({params: {targetEntity: this.targetEmplacementId}});
      this.toggleEditEmplacementModal();
    },
    // DELETE
    async onDeleteEmplacement(event) {
      if (!this.display.deleteEmplacementModal) {
        console.log('DELETE WHERE ID:');
        console.log(event.target.getAttribute('data-id'));
        this.targetEmplacementId = event.target.getAttribute('data-id');
        this.$router.replace({params: {targetEntity: this.targetEmplacementId}});
      }
      else {
        let response = await api.deleteEmplacement(this.targetEmplacementId);
        console.log(response);
        }
      await this.toggleDeleteEmplacementModal();
    }
  },
  async created() {
    await this.getEmplacementList()
    this.$emit('getAvailableCompanies',(this.displayEmplacementsDataTable));
    if (this.emplacementList[0] != undefined && this.targetEmplacementId === "") this.targetEmplacementId = this.emplacementList[0].id;
    this.setButtonFunctions();
  },
  mounted() {
    $("#emplacementsDataTable").DataTable(
      {
        pagingType: 'full_numbers',
        pageLength: 10,
        processing: true,
        dom: 'Bfrtip',
        buttons: [
          { "extend": 'colvis', "className": 'btn' },
          { "extend": 'copy', "className": 'btn ms-auto' },
          { "extend": 'csv', "className": 'btn' },
          { "extend": 'print', "className": 'btn me-0' },
        ],
        columns: this.emplacementsColumns,
        select: this.dataTableOptions.select,
        language: this.dataTableOptions.language[this.session.user.language],
        stateSave: this.dataTableOptions.stateSave,
      }
    )
    $(".dataTables_filter").get()[0].getElementsByTagName('input')[0].addEventListener('blur', this.setButtonFunctions);
  },
  updated() {
    this.setButtonFunctions();
  }
}
</script>

<style>
.btonCopyId:hover, .btnTargetEmplacement:hover, .btnEditEmplacement:hover, .btnDeleteEmplacement:hover {
  color: var(--main-dark);
}

.gm-ui-hover-effect {
  display: none !important;
}
</style>