  <template>
  <!-- MODAL Execute Reception -->
    <div
    id="prepareReceptionModal"
    class="col-12 justify-content-center pe-6"
    style="padding: 15vh 10%;">
      <div class="col-12 p-0 m-0 bg-caja section-container rounded-5">
        
        <div class="row overflow-hidden col-12 m-0 p-0 text-white bg-clomos rounded-top-5 pb-1 h-100" >
  
          <div class="col-10" style="display: inherit">
            <h4 class="tx-left tx-clomos-white mb-2 ps-1 pt-2">
              <ion-icon name="arrow-redo-outline" class="tx-27 me-2 align-bottom"></ion-icon>
              {{dictionary["Preparación"][session.user.language]}}
              <span class="tx-14"> {{ (isProcessed===undefined)?"/ "+dictionary["Sin procesar"][session.user.language]:(!isProcessed)?"/ "+dictionary["Procesando"][session.user.language]:'' }} </span>
            </h4>
          </div>
  
          <div class="col-2 m-0 p-0" style="display: flex; justify-content: center;"
          @click="$emit('togglePrepareReceptionModal')">	
            <ion-icon name="close" class="ms-auto tx-46 tx-white align-bottom"></ion-icon>
          </div>
        </div>
  
        <div class="form-group col-12 p-3">
          
          <div class="p-3 border-1" style="overflow: auto; max-height: 60vh;">
  
            <div v-if="receptionData.params != undefined" id="receptionCheckListContainer">
            
              <div class="col-12 tx-bold tx-left text-nowrap p-0 m-0">
                <span class="tx-clomos-dark">
                  {{dictionary['Recepción'][session.user.language] + ": "}}
                </span>
                <p class="ms-3">
                  {{ this.receptionData.id }}
                </p>
              </div>
  
              <div class="col-12 p-0 m-0">
                <hr class="tx-clomos-dark">
              </div>
  
              <table id="receptionCheckList" cellspacing="0" border="0" class="col-12 m-0 p-0">
                <tr class="border-bottom">
                  <td class="tx-bold gap-1" style="display:flex; flex-direction:row; justify-content-space-between;">
                    <div v-if="receptionData.status==='1'||receptionData.status==='2'"
                    class="bg-clomos rounded-3 tx-white m-0" style="margin:auto; cursor:pointer; width: 30px; height: 30px; padding: 5px 5px;"
                    :class="(isRequesting)?'opacity-50':''"
                    @click="(!isRequesting)?getReceptionData():''">
                      <ion-icon name="reload-outline" class="loader tx-20 tx-white align-bottom" :class="(isRequesting===true)?'requesting':''"></ion-icon>
                    </div>
                    <span class="m-auto pt-1"> {{dictionary['Identificador'][session.user.language]}} </span>
                  </td>
                  <td class="tx-bold"> {{dictionary['Nombre'][session.user.language]}} </td>
                  <!--
                  <td class="tx-bold"> {{dictionary['Talla'][session.user.language]}} </td>
                  
                  <td class="tx-bold"> {{dictionary['Color'][session.user.language]}} </td>
                  -->
                  <td class="tx-bold"> {{dictionary['Caducidad'][session.user.language]}} </td>
                  
                  <td class="tx-bold"> {{dictionary['Zona'][session.user.language]}} </td>
                  
                  <td class="tx-bold"> {{dictionary['Unidades'][session.user.language]}} </td>
                  
                  <td class="tx-bold text-nowrap"> {{dictionary['Encontrados'][session.user.language] +' / '+ dictionary['Procesados'][session.user.language] +' / '+ dictionary['Hueco'][session.user.language]}} </td>
                  
                  <td class="tx-bold" v-if="receptionData.status==='1'||receptionData.status==='2'"> {{dictionary['Editar'][session.user.language]}} </td>
                </tr>
                
                <tr class="border-bottom" v-for="product,index of receptionData.params['productos']" :key="product" :id="'rowNumber'+index+'For'+product.id">
                  
                  <td class="p-1"> 
                    {{ (product.id!=undefined)?product.id:'-' }} 
                  </td>
  
                  <td class="p-1"> 
                    {{ (product.etiqueta!=undefined)?product.etiqueta:'-' }}
                  </td>
  
                  <!--
                  <td class="p-1"> 
                    {{ (product.talla!=undefined)?product.talla:'-' }}
                  </td>
                  <td class="p-1"> 
                    {{ (product.color!=undefined)?product.color:'-' }}
                  </td>
                  -->
                  
                  <td class="p-1"> 
                    {{ (product.fechaCaducidad!=undefined)?product.fechaCaducidad:'-' }}
                  </td>
  
                  <td class="p-1"> 
                    <span v-if="(product.zona==='automatico')"> Automático </span>
                    <span v-if="(product.zona==='locker')"> Locker </span>
                    <span v-if="(product.zona==='libre')"> Libre </span>
                    <span v-if="(product.zona!=undefined && product.zona!='automatico' && product.zona!='locker' && product.zona!='libre')"> {{product.zona}} </span>
                    <span v-if="(product.zona===undefined)"> - </span>
                  </td>
  
                  <td class="p-1"> 
                    {{ (product.unidades!=undefined)?product.unidades:'-' }}
                  </td>
  
                  <td class="m-0 p-0 border-start border-end">
                    <div v-if="receptionData.params['procesadosRaw']!=undefined
                    && receptionData.params['procesadosRaw'][product.id]!=undefined">
                      <div v-for="productRaw,rawIndex of receptionData.params['procesadosRaw'][product.id]" :key="productRaw"
                      class="p-0 m-0">
                        <tr v-if="(product.lote === undefined || productRaw.lote == product.lote)
                        && (product.numeroSerie === undefined || productRaw.numeroSerie == product.numeroSerie)
                        && (product.fechaCaducidad === undefined || productRaw.fechaCaducidad == product.fechaCaducidad)
                        && (product.talla === undefined || productRaw.talla == product.talla)
                        && (product.color === undefined || productRaw.color == product.color)"
                        class="d-flex h-100 w-100 p-1 border-bottom">
                          <td
                          :class="((receptionData.status==='1'||receptionData.status==='2') && product.zona==='libre')?'':'opacity-50'"
                          class="p-0"> 
                            <div class="h-100 align-content-center"
                            @click="((receptionData.status==='1'||receptionData.status==='2') && product.zona==='libre')?removeRaw(productRaw,rawIndex):''"
                            style="margin:auto; cursor:pointer; padding:auto;">
                              <ion-icon name="remove-circle-outline" class="tx-32 tx-clomos-dark align-middle m-auto"></ion-icon>
                            </div>
                          </td>
  
                          <td class="m-auto">
                            <span class="m-auto h-100 text-nowrap"> 
                              {{ (productRaw!=undefined&&productRaw.unidadesProcesables!=undefined&&productRaw.unidadesProcesables!="")?productRaw.unidadesProcesables:"-" }} 
                            </span>
                          </td>
  
                          <td class="m-auto">
                            <span class="m-auto h-100 text-nowrap" :class="(processedOk(product)===false)?'tx-warning':''"> 
                              {{ (productRaw!=undefined&&processedOk(product)!=undefined)?(productRaw!=undefined&&processedOk(product)===false)?productRaw.unidades+' '+product.unidad+' ⚠':productRaw.unidades+' '+product.unidad:"-" }} 
                            </span>
                            <div class="m-0 p-0">
                              <input v-if="(product.zona==='locker'||product.zona==='libre') && (receptionData.status==='1'||receptionData.status==='2')"
                              :disabled="(receptionData.status!='1'&&receptionData.status!='2')"
                              :id="'inputProcessedUnits'+(Number(index)+Number(rawIndex))+'For'+product.id" value="" :placeholder="product.unidad" type="number" min=0
                              class="w-100 tx-14 border-1 p-1 rounded-3 m-auto text-center" style="color:black">
                            </div>
                          </td>
  
                          <td class="m-auto"> 
                            <span class="m-auto h-100" style="white-space: pre-line">
                              {{ processedSlot(product,rawIndex).label + "\n" + processedSlot(product,rawIndex).id }}
                            </span>
                            <select v-if="(receptionData.status==='1'||receptionData.status==='2') && product.zona==='libre'" :id="'selectSlotId'+(Number(index)+Number(rawIndex))+'For'+product.id" 
                            :disabled="(receptionData.status!='1'&&receptionData.status!='2')"
                            class="form-control w-100 m-auto" style="color:black"> 
                              <option v-for="slot in freeSlotList" :key="slot" :value="slot.id"> {{slot.label}} </option>
                            </select>
                          </td>
                        </tr>
                      </div>
                    </div>
                    <div
                    :class="((receptionData.status==='1'||receptionData.status==='2') && product.zona==='libre')?'':'opacity-50'"
                    class=" p-1"
                    @click="((receptionData.status==='1'||receptionData.status==='2') && product.zona==='libre')?addRaw(product):''"
                    style="margin:auto; cursor:pointer;">
                      <ion-icon name="add-circle-outline" class="tx-32 tx-clomos-dark align-middle"></ion-icon>
                    </div>
                  </td>
  
                  <td class="p-auto m-auto" v-if="(receptionData.status==='1'||receptionData.status==='2')"
                  style="display:flex; flex-direction:row; gap:1rem;">
                    <div :class="((product.zona==='locker'||product.zona==='libre')
                    &&receptionData.params['procesadosRaw']!=undefined
                    &&receptionData.params['procesadosRaw'][product.id]!=undefined
                    &&receptionData.params['procesadosRaw'][product.id].length>0)?'':'opacity-50'"
                    @click="((product.zona==='locker'||product.zona==='libre')
                    &&receptionData.params['procesadosRaw']!=undefined
                    &&receptionData.params['procesadosRaw'][product.id]!=undefined
                    &&receptionData.params['procesadosRaw'][product.id].length>0)?process(product,index):''"
                    class="bg-clomos rounded-3 tx-white m-auto"
                    style="width:30px; height:30px; margin:auto; cursor:pointer; padding:auto; padding: 3px 0 0 2px">
                      <ion-icon name="create-outline" class="tx-20 tx-white align-middle"></ion-icon>
                    </div>                    
                  </td>
  
                </tr>
  
              </table>
  
            </div>
  
            <div v-if="receptionData.status==='1'||receptionData.status==='2'" class="col-12 p-0 m-0">
              <hr class="tx-clomos-dark">
            </div>
            
            <div v-if="receptionData.status==='1'||receptionData.status==='2'" class="w-100 m-0 p-0 row">
  
  <!-- SUBMIT -->
              <button type="button" id="btnSubmitReception" class="btn col-lg-7 col-12 p-2"
              :class="(isProcessed)?'btn-clomos':'btn-clomos-disabled'"
              @click="this.prepareReception()">
                {{dictionary['Preparar'][session.user.language]}}
              </button>
  
              <div class="col-lg-1 col-12 p-2"></div>
  
              <button type="button" id="btnCancelReception" class="btn col-lg-3 col-12 p-2 ms-auto"
              :class="(!isProcessed)?'btn-clomos':'btn-clomos-disabled'"
              @click="this.cancelReception()">
                {{dictionary['Cancelar'][session.user.language]}}
              </button>
  
            </div>
          </div>
  
        </div>
  
      </div>
    </div>
  </template>
  
  <script>
  import $ from 'jquery';
  // Clomos
  import api from '@/services/api';
  
  export default {
    name: "ReceptionsComponent",
    props: {
      session: {
        type: Object,
        requiered: true,
      },
      dictionary: {
        type: Object,
        requiered: true,
      },
      countries: {
        type: Object,
        requiered: true,
      },
      timeZones: {
        type: Object,
        requiered: true,
      },
      currentCompany: {
        type: Object,
        requiered: true,
      },
      availableCompanies: {
        type: Object,
        requiered: true,
      },
      visibility: {
        type: Object,
        requiered: true,
      },
      dataTableOptions: {
        type: Object,
        requiered: true,
      },
      receptionId: {
        type: String,
        requiered: true,
      },
    },
    data() {
      return {
        receptionData: {},
        freeSlotList: [],
        lockerSlotList: [],
        bulgeModel: {
          boxType: "",
          volume: {
            height: 0,
            width: 0,
            depth: 0
          },
        },
        isRequesting: false
      }
    },
    emits: ["togglePrepareReceptionModal", "setCurrentCompany"],
    computed: {
      isProcessed() {
        let isProcessed = undefined;
        if (this.receptionData.params != undefined && this.receptionData.params["procesadosRaw"] != undefined) {
          isProcessed = true;
          for (let i in this.receptionData.params['productos']) {
            if (Number(this.receptionData.params['productos'][i].unidades) != this.processedUnits(this.receptionData.params['productos'][i])) {
              isProcessed = false;
            }
          }
        }
        return isProcessed;
      }
    },
    methods: {
      // DISPLAY
      onCurrentCompanyChanged(event) {
        let value = event.target.value;
        this.$router.replace({params: {currentCompanyNifCif: value}})
        this.$emit('setCurrentCompany',value);
      },
      processedOk(product) {
        let ok = undefined;
        let n = 0; 
        if (this.receptionData.params["procesadosRaw"] != undefined) {
          for (let i in this.receptionData.params["procesadosRaw"]) {
            for (let r in this.receptionData.params["procesadosRaw"][i]) {
              if (this.receptionData.params["procesadosRaw"][i][r]!=null 
              && this.receptionData.params["procesadosRaw"][i][r]!=undefined 
              && this.receptionData.params["procesadosRaw"][i][r].id == product.id
              && (product.lote === undefined || this.receptionData.params["procesadosRaw"][i][r].lote == product.lote)
              && (product.numeroSerie === undefined || this.receptionData.params["procesadosRaw"][i][r].numeroSerie == product.numeroSerie)
              && (product.fechaCaducidad === undefined || this.receptionData.params["procesadosRaw"][i][r].fechaCaducidad == product.fechaCaducidad)
              && (product.talla === undefined || this.receptionData.params["procesadosRaw"][i][r].talla == product.talla)
              && (product.color === undefined || this.receptionData.params["procesadosRaw"][i][r].color == product.color)) {
                ok = false;
                n += Number(this.receptionData.params["procesadosRaw"][i][r].unidades);
              }
            }
          }
        }
        if (Number(product.unidades) === n)
          ok = true;
        return ok;
      },
      processedUnits(product) {
        let units = 0;
        if (this.receptionData.params["procesadosRaw"] != undefined) {
          for (let i in this.receptionData.params["procesadosRaw"]) {
            for (let r in this.receptionData.params["procesadosRaw"][i]) {
              if (this.receptionData.params["procesadosRaw"][i][r]!=null 
              && this.receptionData.params["procesadosRaw"][i][r]!=undefined 
              && this.receptionData.params["procesadosRaw"][i][r].id == product.id
              && (product.lote === undefined || this.receptionData.params["procesadosRaw"][i][r].lote == product.lote)
              && (product.numeroSerie === undefined || this.receptionData.params["procesadosRaw"][i][r].numeroSerie == product.numeroSerie)
              && (product.fechaCaducidad === undefined || this.receptionData.params["procesadosRaw"][i][r].fechaCaducidad == product.fechaCaducidad)
              && (product.talla === undefined || this.receptionData.params["procesadosRaw"][i][r].talla == product.talla)
              && (product.color === undefined || this.receptionData.params["procesadosRaw"][i][r].color == product.color)) {
                units += this.receptionData.params["procesadosRaw"][i][r].unidades;
              }
            }
          }
        }
        return units;
      },
      processedSlot(product,r) {
        let slot = "-";
        if (this.receptionData.params["procesadosRaw"] != undefined) {
          for (let i in this.receptionData.params["procesadosRaw"]) {
            if (this.receptionData.params["procesadosRaw"][i][r]!=null 
            && this.receptionData.params["procesadosRaw"][i][r]!=undefined 
            && this.receptionData.params["procesadosRaw"][i][r].id == product.id
            && (product.lote === undefined || this.receptionData.params["procesadosRaw"][i][r].lote == product.lote)
            && (product.numeroSerie === undefined || this.receptionData.params["procesadosRaw"][i][r].numeroSerie == product.numeroSerie)
            && (product.fechaCaducidad === undefined || this.receptionData.params["procesadosRaw"][i][r].fechaCaducidad == product.fechaCaducidad)
            && (product.talla === undefined || this.receptionData.params["procesadosRaw"][i][r].talla == product.talla)
            && (product.color === undefined || this.receptionData.params["procesadosRaw"][i][r].color == product.color)
            && (this.receptionData.params["procesadosRaw"][i][r].idHueco != undefined && this.receptionData.params["procesadosRaw"][i][r].idHueco != "")) {
              for (slot in this.freeSlotList) {
                if (this.freeSlotList[slot].id === this.receptionData.params["procesadosRaw"][i][r].idHueco) {
                  return {id: this.freeSlotList[slot].id, label: this.freeSlotList[slot].label};
                }
              }
              for (slot in this.lockerSlotList) {
                if (this.lockerSlotList[slot].id === this.receptionData.params["procesadosRaw"][i][r].idHueco) {
                  return {id: this.lockerSlotList[slot].id, label: this.lockerSlotList[slot].label};
                }
              }
              return {id:this.receptionData.params["procesadosRaw"][i][r].idHueco, label: "-"};
            }
          }
        }
        return slot;
      },
      // GET
      async getReceptionData(callback) {
        this.isRequesting = true;
        let response = await api.getOrderData(this.receptionId);
        this.receptionData = api.parseOrderFromResponse(response);
        this.isRequesting = false;
        this.receptionData.userId = this.session.user.id;
        await this.getAvailableSlots();
        if (callback && typeof callback === "function") callback(this.receptionData);
      },
      async getAvailableSlots() {
        this.freeSlotList = [];
        let requestFreeSlotData = {
          machineId: "ca675489-5961-4a49-9d49-e77b719cbc38", //ID ZONA LIBRE RELEASE
          limit: 0,
          offset: 0,
          where: ""
        }
        let freeSlotListResponse = await api.getBundleListByMachine(requestFreeSlotData);
        let parsedFreeSlotList = api.parseBundlesFromResponse(freeSlotListResponse);
        for (let i in parsedFreeSlotList)
          this.freeSlotList.push(parsedFreeSlotList[i]);
        
        this.lockerSlotList = [];
        let lockerSlotListResponse;
        let parsedLockerSlotList;
        let requestLockerSlotData;
  
        requestLockerSlotData = {
          machineId: "593f425f-0f7f-4ca7-a918-f0a8069a3723", //ID ZONA LOCKER 1
          limit: 0,
          offset: 0,
          where: ""
        }
        lockerSlotListResponse = await api.getBundleListByMachine(requestLockerSlotData);
        parsedLockerSlotList = api.parseBundlesFromResponse(lockerSlotListResponse);
        for (let i in parsedLockerSlotList)
          this.lockerSlotList.push(parsedLockerSlotList[i]);

        requestLockerSlotData = {
          machineId: "7d3e8a7e-8f49-46c5-adc9-44a785b2c5d6", //ID ZONA LOCKER 2
          limit: 0,
          offset: 0,
          where: ""
        }
        lockerSlotListResponse = await api.getBundleListByMachine(requestLockerSlotData);
        parsedLockerSlotList = api.parseBundlesFromResponse(lockerSlotListResponse);
        for (let i in parsedLockerSlotList)
          this.lockerSlotList.push(parsedLockerSlotList[i]);

        requestLockerSlotData = {
          machineId: "b36b1260-6b04-4535-b9ff-1dc8f4baa1af", //ID ZONA LOCKER 3
          limit: 0,
          offset: 0,
          where: ""
        }
        lockerSlotListResponse = await api.getBundleListByMachine(requestLockerSlotData);
        parsedLockerSlotList = api.parseBundlesFromResponse(lockerSlotListResponse);
        for (let i in parsedLockerSlotList)
          this.lockerSlotList.push(parsedLockerSlotList[i]);

        requestLockerSlotData = {
          machineId: "66325b48-f488-46d6-b495-0ad01ae449b9", //ID ZONA LOCKER 4
          limit: 0,
          offset: 0,
          where: ""
        }
        lockerSlotListResponse = await api.getBundleListByMachine(requestLockerSlotData);
        parsedLockerSlotList = api.parseBundlesFromResponse(lockerSlotListResponse);
        for (let i in parsedLockerSlotList)
          this.lockerSlotList.push(parsedLockerSlotList[i]);
        
        requestLockerSlotData = {
          machineId: "aff231b1-5341-4483-b8f4-dfde55bd6fed", //ID ZONA LOCKER 5
          limit: 0,
          offset: 0,
          where: ""
        }
        lockerSlotListResponse = await api.getBundleListByMachine(requestLockerSlotData);
        parsedLockerSlotList = api.parseBundlesFromResponse(lockerSlotListResponse);
        for (let i in parsedLockerSlotList)
          this.lockerSlotList.push(parsedLockerSlotList[i]);
      },
      // SET
      addRaw(product) {
        if (this.receptionData.params['procesadosRaw'] === undefined)
          this.receptionData.params['procesadosRaw'] = {};
        if (this.receptionData.params['procesadosRaw'][product.id] === undefined) 
          this.receptionData.params['procesadosRaw'][product.id] = [];
        let raw = {
          id: (product.id!=undefined)?product.id:"",
          zona: (product.zona!=undefined)?product.zona:"",
          lote: (product.lote!=undefined)?product.lote:"",
          numeroSerie: (product.numeroSerie!=undefined)?product.numeroSerie:"",
          fechaCaducidad: (product.fechaCaducidad!=undefined)?product.fechaCaducidad:"",
          talla: (product.talla!=undefined)?product.talla:"",
          color: (product.color!=undefined)?product.color:"",
          unidad: (product.unidad!=undefined)?product.unidad:"",
          unidadesPedidas: (product.unidades!=undefined)?product.unidades:"",
          unidadesProcesables: "",
          unidades: 0,
          idHueco: "",
        }
        this.receptionData.params['procesadosRaw'][product.id].push(raw);
      },
      removeRaw(product, raw) {
        if (product.zona!='locker' && product.zona!='libre') return;
        if (this.receptionData.params['procesadosRaw'][product.id][raw] != undefined) {
          this.receptionData.params['procesadosRaw'][product.id].splice(raw,1);
        }
      },
      async process(product,index) {
        // Procesados Raw
        if (this.receptionData.params['procesadosRaw'] === undefined)
          this.receptionData.params['procesadosRaw'] = {};
        if (this.receptionData.params['procesadosRaw'][product.id] === undefined) {
          this.receptionData.params['procesadosRaw'][product.id] = [];
          let raw = {
            id: product.id,
            zona: product.zona,
            lote: product.lote,
            numeroSerie: product.numeroSerie,
            fechaCaducidad: product.fechaCaducidad,
            talla: product.talla,
            color: product.color,
            unidadesPedidas: product.unidades,
            unidadesProcesables: ""
          }
          this.receptionData.params['procesadosRaw'][product.id].push(raw);
          for (let rawIndex in this.receptionData.params['procesadosRaw'][product.id]) {
            if (product.zona==='libre')
              this.receptionData.params['procesadosRaw'][product.id][rawIndex].idHueco = $("#selectSlotId"+(Number(rawIndex)+Number(index))+"For"+product.id).get()[0].value;
            if (product.zona==='locker'||product.zona==='libre')
              if ($("#inputProcessedUnits"+(Number(rawIndex)+Number(index))+"For"+product.id).get()[0].value != "")
                this.receptionData.params['procesadosRaw'][product.id][rawIndex].unidades = Number($("#inputProcessedUnits"+(Number(rawIndex)+Number(index))+"For"+product.id).get()[0].value);
          }
        }
        else {
          for (let rawIndex in this.receptionData.params['procesadosRaw'][product.id]) {
            if (this.receptionData.params['procesadosRaw'][product.id][rawIndex] === null )
              this.receptionData.params['procesadosRaw'][product.id].splice(rawIndex,1);
            else if (this.receptionData.params['procesadosRaw'][product.id][rawIndex].id === product.id
            && (product.lote === undefined || this.receptionData.params["procesadosRaw"][product.id][rawIndex].lote == product.lote)
            && (product.numeroSerie === undefined || this.receptionData.params["procesadosRaw"][product.id][rawIndex].numeroSerie == product.numeroSerie)
            && (product.fechaCaducidad === undefined || this.receptionData.params["procesadosRaw"][product.id][rawIndex].fechaCaducidad == product.fechaCaducidad)
            && (product.talla === undefined || this.receptionData.params["procesadosRaw"][product.id][rawIndex].talla == product.talla)
            && (product.color === undefined || this.receptionData.params["procesadosRaw"][product.id][rawIndex].color == product.color)) {
              if (product.zona==='libre') 
                this.receptionData.params['procesadosRaw'][product.id][rawIndex].idHueco = $("#selectSlotId"+(Number(rawIndex)+Number(index))+"For"+product.id).get()[0].value;
                if (product.zona==='locker'||product.zona==='libre')
                if ($("#inputProcessedUnits"+(Number(rawIndex)+Number(index))+"For"+product.id).get()[0].value != "")
                  this.receptionData.params['procesadosRaw'][product.id][rawIndex].unidades = Number($("#inputProcessedUnits"+(Number(rawIndex)+Number(index))+"For"+product.id).get()[0].value);
            }
          }
        }
        let orderData = {
          id: this.receptionData.id,
          userId: this.session.user.id,
          params: this.receptionData.params,
        };
        let response = await api.setOrderData(orderData);
        if (response["status-code"] === "200") {
          window.alert("El producto ha sido procesado satisfactoriamente.");
        }
        else if (response["status-code"] === "304") {
          window.alert("El producto no ha sido modificado.");
        }
        else {
          window.alert("Error al procesar el producto.");
        }
      },
      async prepareReception() {
        // Procesados
        if (this.receptionData.params['procesados'] === undefined)
          this.receptionData.params['procesados'] = {};
        for (let productId in this.receptionData.params['procesadosRaw']) {
          let entry = this.receptionData.params['procesadosRaw'][productId];
          for (let rawIndex in entry) {
            let productRaw = entry[rawIndex];
            let dominantKey;
            if (productRaw.zona==="locker" || productRaw.zona==="libre") {
              if (this.receptionData.params['procesados'][productRaw.idStock]!=undefined) 
                dominantKey = productRaw.idStock;
              else
                dominantKey = String(Object.keys(this.receptionData.params['procesados']).length);
              if (this.receptionData.params['procesados'][dominantKey] === undefined) {
                this.receptionData.params['procesados'][dominantKey] = {
                  id: productRaw.id,
                  unidades: productRaw.unidades, 
                  fechaEntrada: api.parseDateTimeToUTCString(Date.now()),
                  lote: (productRaw.lote!=undefined)?productRaw.lote:"",
                  numeroSerie: (productRaw.numeroSerie!=undefined)?productRaw.numeroSerie:"",
                  fechaCaducidad: (productRaw.fechaCaducidad!=undefined)?productRaw.fechaCaducidad:"",
                  talla: (productRaw.talla!=undefined)?productRaw.talla:"",
                  color: (productRaw.color!=undefined)?productRaw.color:"",
                  idHueco: (productRaw.idHueco!=undefined)?productRaw.idHueco:"", 
                  idStock: (productRaw.idStock!=undefined)?productRaw.idStock:"", 
                }
              }
              else {
                this.receptionData.params['procesados'][dominantKey].unidades += productRaw.unidades;
              }
            }
          }
        }
        let orderData = {
          id: this.receptionData.id,
          userId: this.session.user.id,
          params: this.receptionData.params,
        };
        let processResponse = await api.setOrderData(orderData);
        if (processResponse["status-code"] === "200") {
          let statusData = {
            orderId : this.receptionId,
            dateTime : new Date(),
            status : 3
          }
          let prepareResponse = await api.setOrderStatus(statusData);
          if (prepareResponse["status-code"] === "200") {
            window.alert("La recepción ha sido preparada satisfactoriamente.");
            this.$emit('togglePrepareReceptionModal');
          }
          else {
            window.alert("Error al preparar la recepción.");
          }
        }
        else {
          window.alert("Error al procesar el producto.");
        }
        
      },
      // CANCEL
      async cancelReception() {
        if (this.receptionData.status == 2) {
          let statusData = {
            orderId : this.receptionId,
            dateTime : new Date(),
            status : 5
          }
          let response = await api.setOrderStatus(statusData);
          if (response["status-code"] === "200") {
            window.alert("La recepción ha sido cancelada satisfactoriamente.");
            this.$emit('togglePrepareReceptionModal');
          }
          else {
            window.alert("Error al cancelar la recepción.");
          }
        }
      }
    },
    async created() {
      await this.getReceptionData();    
      if (this.receptionData.status == 1) {
        let statusData = {
          orderId : this.receptionId,
          dateTime : new Date(),
          status : 2
        }
        let response = await api.setOrderStatus(statusData);
          if (response["status-code"] === "200") {
            window.alert("La recepción está siendo ejecutada.");
          }
      }
    }
  }
  </script>
  <style>
  #prepareReceptionModal {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 99999;
    background-color: rgba(0, 0, 0, 0.5);
    width: 100vw;
    height: 100vh;
  }
  
  .inputAmmountPerBulge {
    color:black;
    background:none;
    min-width:65px;
    max-width:65px;
    padding: 3px 15px 1px 1px;
  }
  
  .inputAmmountPerBulge.missing {
    box-shadow: inset 0px 0px 0px 2px #ce7d0a;
  }
  
  .inputAmmountPerBulge::-webkit-outer-spin-button,
  .inputAmmountPerBulge::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  
  .inputAmmountPerBulge[type=number] {
    -moz-appearance: textfield;
  }
  
  .order-button {
    color: var(--main-dark);
  }
  
  .order-button:hover {
    color: var(--main-dark-50);
  }
  
  .order-button-disabled {
    cursor: none;
    color: var(--main-dark-50);
    pointer-events: none
    ;
  }
  
  .loader {
    color: white !important;
    visibility: visible;
    transition: all 0.3s;
  }
  
  .requesting {
    animation: spin 3s infinite linear;
    transition: all 0.3s;
  }
  
  @keyframes spin {
      from {
          transform: rotate(0deg);
      }
      to {
          transform: rotate(360deg);
      }
  }
  </style>