<template>
<!-- Cabeza -->
  <section id="wrapper" class="login-sidebar">
    <div class="d-flex">
      <div id="ocultar" class="col-lg-9 col-md-12 col-12 m-0 p-0 justify-content-center d-flex bg-clomos-portada p-5 ">
        <img title="Hexagonal Composition" src="@/assets/img/hexagonos.png" class="img-fluid m-auto">
      </div>
      <div class="col-lg-3 col-md-12 col-12 m-0 p-0" style="height: 100vh; background:transparent;">
        <div class="login-box card rounded-0 bg-clomos" style=" width:100%;height: calc(100% + 0rem); color:#FFF;">
          <div class="card-body pt-5 pl-6 pr-6">

<!-- Language -->
            <div class="col-md-12 ">
              <div class="form-group text-center">
                <div class="justify-content-center ps-3 pe-3 w-100">
                  <img title="Logo" src="@/assets/img/logo.png" class="img-fluid m-auto">
                </div>
              </div>
            </div>

<!-- FORM Iniciar Sesion -->
            <form v-if="visibility.formLogIn" 
            class="form-horizontal form-material text-center" id="formLogIn">
              
              <div class="form-group p-3" style="margin-top: 80px;">
              <span class="tx-14">{{errorMsg}}</span><br>
                <div class="col-xs-10">
                  <input v-model="formLogIn.userId" id="userId" type="text" autofocus
                  class="form-control" style="padding: 0.65rem 0.75rem; border: solid 1px white"
                  :placeholder="dictionary['Nombre de usuario'][session.user.language]+'/'+dictionary['E-mail'][session.user.language]">
                </div>
              </div>

              <div class="form-group p-3">
                <div class="col-xs-10">
                  <input v-model="formLogIn.password" id="password" type="password" autocomplete="off"
                  class="form-control" style="padding: 0.65rem 0.75rem; border: solid 1px white"
                  @keydown.enter="onLogInRequest"
                  :placeholder="dictionary['Contraseña'][session.user.language]">
                </div>
              </div>

              <input v-model="formLogIn.rememberMe" @click="formLogIn.rememberMe=!formLogIn.rememberMe" id="rememberMe" type="checkbox">
              <label for="rememberMe" class="ps-1">{{dictionary['Recuérdame'][session.user.language]}}</label>

              <div class="form-group text-center mt-3">
                <div class="col-xs-12 justify-content-center d-flex">
                  <button id="btnLogin" @click="onLogInRequest" type="button"
                  class="btn btn-clomos btn-lg border-white text-uppercase btn-rounded " style="max-width:90%">
                    {{dictionary["Iniciar sesión"][session.user.language]}}
                  </button>
                </div>
              </div>

              <div class="form-group row">
                <div class="col-md-12">
                  <div class="row align-items-center">

                    <div class="ps-0 pt-3">
                      <a @click="toggleRecoveryForm" id="to-recover" class="tx-white"><ion-icon name="lock-open" class="menu-item-icon tx-14"></ion-icon>
                        {{dictionary["¿Has olvidado tu contraseña?"][session.user.language]}}
                      </a>
                    </div>

                    <div class="ps-0 pt-3">
                      <a @click="toggleValidateClientForm" id="to-validate" class="tx-white"><ion-icon name="checkmark-circle" class="menu-item-icon tx-14"></ion-icon>
                        {{dictionary["Validar cuenta"][session.user.language]}}
                      </a> 
                    </div>
                    
                  </div>  						
                </div>
              </div>

              <div class="form-group text-center">
                <div class="mt-5 p-3">
                  <label for="language" class="w-100 p-1">
                    {{dictionary["Idioma"][session.user.language]}}
                  </label>
                  <select 
                  :value="session.user.language"
                  @change="toggleLanguage" 
                  name="languageSelector" title="Language" 
                  id="languageSelector" 
                  class="form-control form-control-lang"
                  style="border: solid 1px white; color; white">
                    <option value="es">
                      {{dictionary["Español"][session.user.language]}}
                    </option>
                    <option value="en">
                      {{dictionary["Inglés"][session.user.language]}}
                    </option>
                  </select>
                </div>
              </div>

              <!--
              <div class="mt-3">
                <p>
                  <a href="http://clomos.es" style="color:#FFF" target="blank"><small>Copyright &copy; 2022. Ayuntamiento de León.</small></a>
                </p>
              </div>
              -->

            </form>

<!-- FORM Recuperar password -->
            <form v-if="visibility.formPasswordLost" 
            class="form-horizontal" id="formPasswordLost">

              <div class="form-group ">
                <div class="col-xs-12">
                  <h3 class="tx-white">
                    {{dictionary["Recuperar contraseña"][session.user.language]}}
                  </h3>
                  <p class="tx-white">
                    {{dictionary["Introduce un correo electrónico al que enviar instrucciones."][session.user.language]}}
                  </p>
                </div>
              </div>

              <div class="form-group ">
                <div class="col-md-12 p-3">
                  <input v-model="formPasswordLost.email" placeholder="Email"
                   id="inputPasswordLost" type="text" required
                  class="p-1 form-control"
                  style="border: solid 1px white; color; white">
                </div>
              </div>
              
              <div class="form-group text-center m-t-20">
                <div class="col-md-12 col-12 justify-content-center d-flex">
                  <button id="btnRePass" class="btn btn-clomos btn-lg border-white text-uppercase waves-effect waves-light mt-3" type="button"
                  @click="onPasswordLost">
                    {{dictionary["Recuperar contraseña"][session.user.language]}}
                  </button>
                </div>
                <div class="col-md-12 justify-content-center d-flex" style="padding:30px;">
                  <button id="btnBackToLogIn" @click="toggleRecoveryForm" class="btn btn-clomos btn-lg border-white text-uppercase waves-effect waves-light" type="button">
                    {{dictionary["Volver"][session.user.language]}}
                  </button>
                </div>
              </div>

            </form>			

<!-- FORM Validar email -->
            <form v-if="visibility.formValidateClient" 
            class="form-horizontal" id="formValidateClient">

              <div class="form-group ">
                <div class="col-md-12 col-12 justify-content-center">
                    <h3 class="tx-white">
                      {{dictionary["Validar cuenta"][session.user.language]}}
                    </h3>
                </div>
              </div>

              <div class="form-group ">
                <div class="mb-2 col-md-12">
                  <p>
                    {{dictionary["Completa el siguiente formulario para proceder con tu validación como usuario."][session.user.language]}}
                  </p>
                </div>
              </div>
                
              <div class="mt-2 p-3">
                <div class="form-label-group">
                  <input v-model="formValidateClient.token" :placeholder="dictionary['Token'][session.user.language]" id="inputToken" type="text"
                  class="p-1 form-control"
                  style="border: solid 1px white; color; white">
                  <label for="inputToken"></label>
                </div>

                <div class="form-label-group">
                  <input v-model="formValidateClient.password" :placeholder="dictionary['Contraseña'][session.user.language]" id="tokenPass" type="password"
                  class="p-1 form-control" required
                  style="border: solid 1px white; color; white">
                  <label for="tokenPass"></label>
                </div>

                <div class="form-label-group">
                    <input v-model="formValidateClient.passwordRe" :placeholder="dictionary['Repite la contraseña'][session.user.language]" id="tokenRePass" type="password" required
                    class="p-1 form-control"
                    style="border: solid 1px white; color; white">
                    <label for="tokenRePass"></label>
                </div>
                
                <div class="col-md-12 col-12 justify-content-center d-flex mt-2">
                  <button id="btnValidarCuenta" class="btn btn-clomos btn-lg border-white text-uppercase waves-effect waves-light" type="button"
                  @click="onValidateAccount">
                    {{dictionary["Validar cuenta"][session.user.language]}}
                  </button>
                </div>

                <div class="col-md-12 col-12 justify-content-center d-flex mt-3">
                  <button id="btnBackToLogIn" @click="toggleValidateClientForm" type="button" class="btn btn-clomos btn-lg border-white text-uppercase waves-effect waves-light mt-3">
                    {{dictionary["Volver"][session.user.language]}}
                  </button>
                </div> 
              </div>

            </form>
<!-- Pié -->
          </div>
        </div>
      </div>
    </div>
  </section>

</template>

<script>
// Clomos
import api from '@/services/api';

export default {
  name: "LogInView",
  props: {
    session: {
      type: Object,
      requiered: true,
    },
    dictionary: {
      type: Object,
      requiered: true,
    }
  },
  data() {
    return {
      errorMsg: "",
      formLogIn: {
        userId: localStorage.getItem("ClomosUserId") ||"",
        password: "",
        rememberMe: localStorage.getItem("ClomosRememberMe") ||true,
      },
      formPasswordLost: {
        email: "",
      },
      formValidateClient: {
        token: "",
        password: "",
        passwordRe: "",
      },
      visibility: {
        formLogIn: true,
        formPasswordLost: false,
        formValidateClient: false,
      }
    }
  },
  emits: ["logInRequest", "toggleLanguage"],
  methods: {
    // DISPLAY
    toggleLanguage(event) {
      this.$emit('toggleLanguage',event.target.value);
    },
    toggleRecoveryForm() {
      if (this.visibility.formPasswordLost) {
        this.visibility.formPasswordLost = false;
        this.visibility.formValidateClient = false;
        this.visibility.formLogIn = true;
      } 
      else {
        this.visibility.formPasswordLost = true;
        this.visibility.formValidateClient = false;
        this.visibility.formLogIn = false;
      }
    },
    toggleValidateClientForm() {
      if (this.visibility.formValidateClient) {
        this.visibility.formPasswordLost = false;
        this.visibility.formValidateClient = false;
        this.visibility.formLogIn = true;
      } 
      else {
        this.visibility.formValidateClient = true;
        this.visibility.formPasswordLost = false;
        this.visibility.formLogIn = false;
      }
    },
    // GET
    onLogInRequest() {
      this.$emit('logInRequest',this.formLogIn, (response) => {
        if (response["status-code"] == undefined) {
          this.formLogIn.password = "";
          this.errorMsg = "Error de conexión.";
        }
        else if (response.session.user != undefined) {
          let path = "/"+response.session.user.language+'/'+response.session.user.nifCif+'/companies/';
          this.$router.replace({path: path});
        }
        else if (response["status-code"] === "401") {
          this.formLogIn.password = "";
          this.errorMsg = "Credenciales incorrectas."
        }
        else if (response["status-code"] === "500") {
          this.formLogIn.password = "";
          this.errorMsg = "Error en el servidor.";
        }
        else {
          this.formLogIn.password = "";
          this.errorMsg = "Límite de tiempo agotado.";
        }
        clearTimeout(this.errorMsgTimeout);
        this.errorMsgTimeout = setTimeout(()=>{
          this.errorMsg="";
        },5000)
      });
    },
    // SET
    async onValidateAccount() {
      if(this.formValidateClient.password != this.formValidateClient.passwordRe) return;
      let response = await api.validateAccount(this.formValidateClient);
      if (response) {
        this.visibility.formPasswordLost = false;
        this.visibility.formValidateClient = false;
        this.visibility.formLogIn = true;
      }
    },
    async onPasswordLost() {
      let response = await api.passwordLost(this.formPasswordLost.email);
      if (response) {
        this.visibility.formPasswordLost = false;
        this.visibility.formValidateClient = false;
        this.visibility.formLogIn = true;
      }
    },
  }
}
</script>


