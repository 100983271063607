<template>
<!-- MODAL New User -->
  <div id="newUserModal"
  class="col-12 justify-content-center pe-6"
  style="padding: 15vh 20%">
    <div class="col-12 p-0 m-0 bg-caja section-container rounded-5">
      
<!-- HEAD New User -->
      <div class="row overflow-hidden col-12 m-0 p-0 text-white bg-clomos rounded-top-5 pb-1 h-100">
        <div class="col-10">
          <h4 class="tx-left tx-clomos-white mb-2 ps-1 pt-2">
            <ion-icon name="person" class="tx-27 me-2 align-bottom"></ion-icon>
            {{dictionary['Nuevo usuario'][session.user.language]}}
          </h4>
        </div>

        <div class="col-2 m-0 p-0" style="display: flex; justify-content: end;"
        @click="$emit('toggleNewUserModal')">	
          <ion-icon name="close" class="ms-auto tx-46 tx-white align-bottom"></ion-icon>
        </div>
      </div>

<!-- CONTENT New User -->
      <div class="col-12 p-0 w-100">
        <div style="overflow: scroll; overflow-x: hidden; max-height: 60vh;">
          <div class="card-body">

            <form class="form-material border-1 p-3 pt-0 overflow-hidden">
              <div class="container-fluid p-3">
                
                <div class="row">

<!-- NIF CIF -->
                  <div class="form-group col-md-6 col-12 p-1">
                    <label class="col-12 tx-bold tx-clomos-midtone text-nowrap">
                      {{dictionary['Empresa'][session.user.language]}}
                    </label>
                    <div class="col-md-12">
                      <select
                      v-model="newUserData.nifCif"
                      class="form-control form-control-line" style="color:black;">
                        <option v-for="company in availableCompanies" :key="company.nifCif"
                        :v-if="company.nifCif != session.user.nifCif
                        && company.relation != undefined
                        && company.relation.referenceCompany === this.session.user.nifCif"
                        :value="company.nifCif">
                          {{(company.nifCif===session.user.nifCif)?"✪ "+company.name :company.name}}
                        </option>
                      </select>
                    </div>
                  </div>

                </div>

                <div class="row">
                  
<!-- NOMBRE -->
                  <div class="form-group col-md-6 col-12 p-1">
                    <label class="col-12 tx-bold tx-clomos-midtone">
                      {{dictionary['Nombre'][session.user.language]}} *
                    </label>
                    <div class="col-md-12">
                      <input v-model="newUserData.name" id="inputNewUserName" type="text" 
                      class="form-control form-control-line" style="color:black;">
                    </div>
                  </div>

<!-- APELLIDOS -->
                  <div class="form-group col-md-6 col-12 p-1">
                    <label class="col-12 tx-bold tx-clomos-midtone">
                      {{dictionary['Apellidos'][session.user.language]}} *
                    </label>
                    <div class="col-md-12">
                      <input v-model="newUserData.lastName" id="inputNewUserLastName" type="text" 
                      class="form-control form-control-line" style="color:black;">
                    </div>
                  </div>

                </div>
              
                <div class="row">
                
<!-- CORREO -->
                  <div class="form-group col-md-12 col-12 p-1">
                    <label class="col-12 tx-bold tx-clomos-midtone">
                      {{dictionary['Correo electrónico'][session.user.language]}} *
                    </label>
                    <div class="col-md-12">
                      <input v-model="newUserData.email" id="inputNewUserEmail" type="text" 
                      class="form-control form-control-line" style="color:black;">
                    </div>
                  </div>
                
                </div>

                <div class="row">
                
<!-- CORREO RE -->
                  <div class="form-group col-md-12 col-12 p-1">
                    <label class="col-12 tx-bold tx-clomos-midtone">
                      {{dictionary['Repetir correo'][session.user.language]}} *
                    </label>
                    <div class="col-md-12">
                      <input v-model="newUserData.emailRe" id="inputNewUserEmailRe" type="text" 
                      class="form-control form-control-line" style="color:black;">
                    </div>
                  </div>
                
                </div>

                <div class="row">

<!-- ROL -->
                  <div class="form-group col-md-6 col-12 p-1">
                    <label class="col-12 tx-bold tx-clomos-midtone">
                      {{dictionary['Rol'][session.user.language]}} *
                    </label>
                    <div class="col-md-12">
                      <select v-model="newUserData.rol"
                      id="selectNewUserRol"
                      class="form-control form-control-line" style="color:black;">
                        <option value="255"> 
                          {{dictionary['Administrador'][session.user.language]}}
                        </option>
                        <option value="128">
                          {{this.dictionary['Administrador de la empresa'][this.session.user.language]}}
                        </option>
                        <option value="32">
                          {{this.dictionary['Autorizado'][this.session.user.language]+"/"+this.dictionary['Editor'][this.session.user.language]}}
                        </option>
                        <option value="2">
                          {{dictionary['Cliente'][session.user.language]}}
                        </option>
                        <option value="1">
                          {{dictionary['Analista'][session.user.language]}}
                        </option>
                      </select>
                    </div>
                  </div>

<!-- TELÉFONO -->
                  <div class="form-group col-md-6 col-12 p-1">
                    <label class="col-12 tx-bold tx-clomos-midtone">
                      {{dictionary['Teléfono'][session.user.language]}}
                    </label>
                    <div class="col-12 d-flex flex-row">
                      <div class="col-5 ps-0">
                        <select v-model="newUserData.telephonePrefix"
                        id="selectNewUserTelephonePrefix"
                        class="form-control form-control-line" style="color:black;">
                          <option v-for="country in countries" :key="country['dialCode']" :value="country['dialCode']">
                            {{country['code']+' '+country['dialCode']}}
                          </option>
                        </select>
                      </div>
                      <div class="w-100">
                        <input v-model="newUserData.telephone" id="inputNewUserTelephone" type="tel"
                        class="form-control form-control-line" style="color:black;">
                      </div>
                    </div>
                      
                  </div>

                </div>

                <div class="row">

<!-- PROVINCIA -->
                  <div class="form-group col-md-6 col-12 p-1">
                    <label class="col-12 tx-bold tx-clomos-midtone">
                      {{dictionary['Provincia'][session.user.language]}}
                    </label>
                    <div class="col-md-12">
                      <input v-model="newUserData.province" id="inputNewUserState" type="text" 
                      class="form-control form-control-line" style="color:black;">
                    </div>
                  </div>

<!-- PAÍS -->
                  <div class="form-group col-md-6 col-12 p-1">
                    <label class="col-12 tx-bold tx-clomos-midtone">
                      {{dictionary['País'][session.user.language]}}
                    </label>
                    <div class="col-sm-12">
                      <select v-model="newUserData.country"
                      id="selectNewUserCountry"
                      class="form-control form-control-line" style="color:black;">
                        <option v-for="country in countries" :key="country['code']" :value="country['code']">
                          {{country['name'][session.user.language]}}
                        </option>
                      </select>
                    </div>
                  </div>

                </div>

                <div class="row">

<!-- DIRECCIÓN -->
                  <div class="form-group col-md-6 col-12 p-1">
                    <label class="col-12 tx-bold tx-clomos-midtone">
                      {{dictionary['Dirección'][session.user.language]}} 1
                    </label>
                    <div class="col-12">
                      <textarea v-model="newUserData.adress1" id="inputEditUserAdress1" rows="1"
                    class="form-control" style="color: black; padding: 0.7rem;"/>
                    </div>
                  </div>

                  <div class="form-group col-md-6 col-12 p-1">
                    <label class="col-12 tx-bold tx-clomos-midtone">
                      {{dictionary['Dirección'][session.user.language]}} 2
                    </label>
                    <div class="col-12">
                      <textarea v-model="newUserData.adress2" id="inputEditUserAdress2" rows="1"
                    class="form-control" style="color: black; padding: 0.7rem;"/>
                    </div>
                  </div>

                </div>

                <div class="row">

<!--MODULOS-->
                  <div class="form-group col-12 p-1">
                    <label class="col-12 tx-bold tx-clomos-midtone">
                      {{dictionary['Módulos permitidos'][session.user.language]}}
                    </label>
                    <div class="col-12 d-flex flex-wrap">
                      <div v-for="m in session.authorisation.availableModules" :key="m" 
                      class="btn modules-button w-auto mb-2"
                      :class="(newUserData.authorisation.availableModules[m] != undefined)?'active': ''"
                      @click="(newUserData.authorisation.availableModules[m] != undefined)? delete newUserData.authorisation.availableModules[m] : newUserData.authorisation.availableModules[m]=1">
                        {{ m.substring(0,1).toUpperCase()+m.substring(1,m.length) }}
                      </div>
                    </div>
                  </div>

                </div>
        
                <div class="col-12 p-0 ps-2 ms-1 pe-3">
                  <hr class="tx-clomos-dark">
                </div>

                <div class="row">

                  <div class="form-group col-md-6 col-12 p-1 pt-0">
                    <label class="col-12 tx-bold  tx-clomos-dark">
                      {{dictionary['Preferencias'][session.user.language]}}:
                    </label>
                  </div>
                  
                </div>


                <div class="row">

<!-- IDIOMA -->
                  <div class="form-group col-md-6 col-12 p-1">
                    <label class="col-12 tx-bold tx-clomos-midtone">
                      {{dictionary['Idioma'][session.user.language]}} *
                    </label>
                    <div class="col-md-12">
                      <select v-model="newUserData.language"
                      id="selectNewUserLanguage"
                      class="form-control form-control-line" style="color:black;">
                        <option value="es">
                          {{dictionary['Español'][session.user.language]}}
                        </option>
                        <option value="en">
                          {{dictionary['Inglés'][session.user.language]}}
                        </option>
                      </select>
                    </div>
                  </div>

<!-- SEPARADOR DE COMAS CSV -->
                  <div class="form-group col-md-6 col-12 p-1">
                    <label class="col-12 tx-bold tx-clomos-midtone">
                      {{dictionary['Separador de comas CSV'][session.user.language]}}
                    </label>
                    <div class="col-sm-12">
                      <select v-model="newUserData.fieldSeparator"
                      id="slectNewUserFieldSeparator"
                      class="form-control form-control-line" style="color:black;">
                        <option value=",">
                          {{dictionary['Coma'][session.user.language]}} ","
                        </option>
                        <option value=";">
                          {{dictionary['Punto y coma'][session.user.language]}} ";"
                        </option>
                      </select>
                    </div>
                  </div>

                </div>

                <div class="row">

<!-- TEMPERATURA -->
                  <div class="form-group col-md-6 col-12 p-1">
                    <label class="col-12 tx-bold tx-clomos-midtone">
                      {{dictionary['Temperatura'][session.user.language]}}
                    </label>
                    <div class="col-sm-12">
                      <select v-model="newUserData.unitTemperature"
                      id="selectNewUserTemperature"
                      class="form-control form-control-line" style="color:black;">
                        <option value="C">Cº (Celsius)</option>
                        <option value="F">Fº (Fahrenheit)</option>
                      </select>
                    </div>
                  </div>
                  
<!-- FORMATO -->
                  <div class="form-group col-md-6 col-12 p-1">
                      <label class="col-12 tx-bold tx-clomos-midtone">
                        {{dictionary['Formato'][session.user.language]}}
                      </label>
                      <div class="col-12">
                        <select v-model='newUserData.dateTimeFormat'
                        id="selectNewUserDateTimeFormat"
                        class="form-control form-control-line" style="color:black;">
                          <option value="DD/MM/YYYY">DD/MM/YYYY</option>
                          <option value="MM/DD/YYYY">MM/DD/YYYY</option>
                        </select>
                      </div>
                    </div>

                </div>

                <div class="row">

<!-- ZONA HORARIA -->
                  <div class="form-group col-md-6 col-12 p-1">
                    <label class="col-12 tx-bold tx-clomos-midtone">
                      {{dictionary['Zona horaria'][session.user.language]}}
                    </label>
                    <div class="col-12">
                      <select v-model="newUserData.dateTimeZone"
                      id="selectNewUserDateTimeZone"
                      class="form-control form-control-line" style="color:black;">
                        <option v-for="timeZone in timeZones" :key="timeZone['value']" :value="timeZone['value']">
                          {{timeZone['value'].replace("_"," ")}}
                        </option>
                      </select>
                    </div>
                  </div>

                  <div class="form-group col-md-6 col-12 p-1">
                    <div class="col-12 mt-4">
<!-- SUBMIT -->
                      <button type="button" @click="onCreateNewUser"
                      id="btnSubmitCreateNewUser" class="btn btn-clomos w-100">
                        {{dictionary['Crear nuevo usuario'][session.user.language]}}
                      </button>
                    </div>
                  </div>

                </div>
              </div>
            </form>
              
          </div>
        </div>
      </div>
      
    </div>
  </div>

</template>

<script>
import $ from 'jquery';
// Clomos
import api from '@/services/api';

export default {
  name: "NewUserModal",
  props: {
    session: {
      type: Object,
      requiered: true,
    },
    dictionary: {
      type: Object,
      requiered: true,
    },
    countries: {
      type: Object,
      requiered: true,
    },
    timeZones: {
      type: Object,
      requiered: true,
    },
    currentCompany: {
      type: Object,
      requiered: true,
    },
    availableCompanies: {
      type: Object,
      requiered: true,
    }
  },
  data() {
    return {
      newUserData: {
        nifCif: this.$route.params.currentCompanyNifCif,
        name: "",
        lastName: "",
        email: "",
        emailRe: "",
        telephone: "",
        city: "",
        province: "",
        country: "",
        rol: "",
        language: this.session.user.language,
        fieldSeparator: this.session.user.fieldSeparator,
        unitTemperature: this.session.user.unitTemperature,
        dateTimeFormat: this.session.user.dateTimeFormat,
        dateTimeZone: this.session.user.dateTimeZone,
        authorisation: {
          availableModules: []
        }
      },
    }
  },
  emits: ["toggleNewUserModal"],
  methods: {
    async onCreateNewUser() {
      let valid = true;
      if (this.newUserData.email != this.newUserData.emailRe
      || this.newUserData.email === ""
      || this.newUserData.emailRe === "") {
        $("#inputNewUserEmail").get()[0].classList.add("missing")
        $("#inputNewUserEmailRe").get()[0].classList.add("missing")
        valid = false;
      }
      if (this.newUserData.name === "") {
        $("#inputNewUserName").get()[0].classList.add("missing")
        valid = false;
      }
      if (this.newUserData.lastName === "") {
        $("#inputNewUserLastName").get()[0].classList.add("missing")
        valid = false;
      }
      if (this.newUserData.lastName === "") {
        $("#selectNewUserRol").get()[0].classList.add("missing")
        valid = false;
      }
      if (!valid) {
        setTimeout(() => {
          $("#inputNewUserName").get()[0].classList.remove("missing")
          $("#inputNewUserLastName").get()[0].classList.remove("missing")
          $("#inputNewUserEmail").get()[0].classList.remove("missing")
          $("#inputNewUserEmailRe").get()[0].classList.remove("missing")
          $("#selectNewUserRol").get()[0].classList.remove("missing")
        }, 3000)
        return;
      }
      let response = await api.postNewUser(this.newUserData);
      if (response["email"] === this.newUserData.email) {
        this.$emit('toggleNewUserModal');
        window.alert("El usuario ha sido creado satisfactoriamente.");
      }
      else {
        window.alert("Error al crear el usuario.");
      }
    },
  },
}
</script>

<style>

#newUserModal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99999;
  background-color: rgba(0, 0, 0, 0.5);
  width: 100vw;
  height: 100vh;
}
</style>