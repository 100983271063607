<template>
<!-- MODAL New Machine -->
  <div id="newMachineModal"
  class="col-12 justify-content-center pe-6"
  style="padding: 15vh 20%">
    <div class="col-12 p-0 m-0 bg-caja section-container rounded-5">
      
<!-- HEAD New Machine-->
      <div class="row overflow-hidden col-12 m-0 p-0 text-white bg-clomos rounded-top-5 pb-1 h-100">
        <div class="col-10">
          <h4 class="tx-left tx-clomos-white mb-2 ps-1 pt-2">
            <ion-icon name="cog-outline" class="tx-27 me-2 align-bottom"></ion-icon>
            {{dictionary['Nueva máquina'][session.user.language]}}
          </h4>
        </div>

        <div class="col-2 m-0 p-0" style="display: flex; justify-content: end;"
        @click="$emit('toggleNewMachineModal')">	
          <ion-icon name="close" class="ms-auto tx-46 tx-white align-bottom"></ion-icon>
        </div>
      </div>

<!-- CONTENT New Machine -->

      <div class="col-12 p-0 w-100">
        <div style="overflow: scroll; overflow-x: hidden; max-height: 60vh;">
          <div class="card-body">

            <form class="form-material border-1 p-3 pt-0 overflow-hidden">
              <div class="container-fluid p-3">
                
                <div class="row">
                  
<!-- NOMBRE -->
                  <div class="form-group col-md-6 col-12 p-1">
                    <label class="col-12 tx-bold tx-clomos-midtone">
                      {{dictionary['Nombre'][session.user.language]}} *
                    </label>
                    <div class="col-md-12">
                      <input v-model="newMachineData.label" id="inputNewMachineLabel" type="text" 
                      class="form-control form-control-line" style="color:black;">
                    </div>
                  </div>

<!-- TIPO -->
                  <div class="form-group col-md-6 col-12 p-1">
                    <label class="col-12 tx-bold tx-clomos-midtone">
                      {{dictionary['Tipo'][session.user.language]}} *
                    </label>
                    <div class="col-md-12">
                      <select v-model="newMachineData.typeId" 
                      id="selectNewMachineType"
                      class="form-control form-control-line" style="color:black;">
                        <option v-for="type in machineTypeList" :key="type.id" :value="type.id">{{type.label}}</option>
                      </select>
                    </div>
                  </div>

                </div>
      
                <div class="row">
                  
<!-- EMPLAZAMIENTO -->
                  <div class="form-group col-md-6 col-12 p-1">
                    <label class="col-12 tx-bold tx-clomos-midtone">
                      {{dictionary['Emplazamiento'][session.user.language]}} *
                    </label>
                    <div class="col-md-12">
                      <select v-model="newMachineData.emplacementId" 
                      id="selectNewMachineType"
                      class="form-control form-control-line" style="color:black;">
                        <option v-for="emplacement in emplacementList" :key="emplacement.id" :value="emplacement.id">{{emplacement.label}}</option>
                      </select>
                    </div>
                  </div>

                </div>
              
                <div class="row">
                
                  <div class="form-group col-md-6 col-12 p-1">
                  </div>

                  <div class="form-group col-md-6 col-12 p-1">
                    <div class="col-12 mt-4">
<!-- SUBMIT -->
                      <button type="button" id="btnSubmitCreateNewMachine" class="btn btn-clomos w-100"
                      @click="onCreateNewMachine">
                        {{dictionary['Crear nueva máquina'][session.user.language]}}
                      </button>
                    </div>
                  </div>

                </div>
              </div>
            </form>
              
          </div>
        </div>
      </div>
      
    </div>
  </div>

</template>

<script>
import $ from 'jquery';
// Clomos
import api from '@/services/api';

export default {
  name: "NewMachineModal",
  props: {
    session: {
      type: Object,
      requiered: true,
    },
    dictionary: {
      type: Object,
      requiered: true,
    },
    countries: {
      type: Object,
      requiered: true,
    },
    timeZones: {
      type: Object,
      requiered: true,
    },
    currentCompany: {
      type: Object,
      requiered: true,
    },
  },
  data() {
    return {
      newMachineData: {
        nifCif: this.currentCompany.nifCif,
        label: "",
        typeId: "",
        emplacementId: "",
      },
      machineTypeList: [],
      emplacementList: []
    }
  },
  methods: {
    async onCreateNewMachine() {
      let valid = true;
      if (this.newMachineData.label === "") {
        $("#inputNewMachineLabel").get()[0].classList.add("missing")
        valid = false;
      }
      if (this.newMachineData.label === "") {
        $("#selectNewMachineType").get()[0].classList.add("missing")
        valid = false;
      }
      if (!valid) {
        setTimeout(() => {
          $("#inputNewMachineLabel").get()[0].classList.remove("missing")
          $("#selectNewMachineType").get()[0].classList.remove("missing")
        }, 3000)
        return;
      }
      let response = await api.postNewMachine(this.newMachineData);
      if (response["status-code"] === "201") {
        this.$emit('toggleNewMachineModal');
        window.alert("La máquina ha sido creado satisfactoriamente.");
      }
      else {
        window.alert("Error al crear la máquina.");
      }
    },
    async getMachineTypeList() {
      let request = {
        nifCif: this.currentCompany.nifCif,
        limit: 0,
        offset: 0,  
        where: "",
      };
      let response = await api.getMachineTypeList(request);
      for (let i in response.tiposDcl) [
        this.machineTypeList[i] = {
          id: response.tiposDcl[i].idTipoDcl,
          label: response.tiposDcl[i].etiqueta,
        }
      ]
    },
    async getEmplacementList() {
      let request = {
        nifCif: this.currentCompany.nifCif,
        limit: 0,
        offset: 0,  
        where: "",
      };
      let response = await api.getEmplacementList(request);
      for (let i in response.plantas) [
        this.emplacementList[i] = {
          id: response.plantas[i].idDcl,
          label: response.plantas[i].etiqueta,
        }
      ]
    }
  },
  async created() {
    await this.getMachineTypeList();
    await this.getEmplacementList();
  }
}
</script>

<style>

#newMachineModal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99999;
  background-color: rgba(0, 0, 0, 0.5);
  width: 100vw;
  height: 100vh;
}
</style>