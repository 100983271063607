import requestHandler from "./requestHandler";
import parser from "./parser";
import Sha256 from "./sha256";
import Dayjs from "/node_modules/dayjs";
var utc = require('dayjs/plugin/utc')
var timezone = require('dayjs/plugin/timezone')
Dayjs.extend(utc);
Dayjs.extend(timezone);

export default {
  ...requestHandler,
  ...parser,

  // DATA HOLDERS
  authToken: (localStorage.getItem("ClomosAuthToken") !=  null) 
    ? localStorage.getItem("ClomosAuthToken")
    : "",

  // AUTH
  async logInRequest(logInData) {
    let data = {
      "username": logInData.userId
    };
    let challangeResponse = await this.post("/api/auth/index.php", data, true);
    if (challangeResponse["status-code"] == 200
      && challangeResponse["desafio"] != undefined) {
      let challange = challangeResponse["desafio"]
      this.authToken = Sha256.hash(Sha256.hash(logInData.password) + challange);
      localStorage.setItem("ClomosPass",Sha256.hash(logInData.password));
      localStorage.setItem("ClomosAuthToken",this.authToken);
      let logInResponse = await this.getSessionData(logInData.userId);
      return logInResponse;
    }
  },

  async persistenceLogInRequest(userId) {
    let data = {
      "username": userId
    };
    let challangeResponse = await this.post("/api/auth/index.php", data, true);
    if (challangeResponse["status-code"] == 200
      && challangeResponse["desafio"] != undefined) {
      let challange = challangeResponse["desafio"]
      this.authToken = Sha256.hash(localStorage.getItem("ClomosPass") + challange);
      localStorage.setItem("ClomosAuthToken",this.authToken);
      let logInResponse = await this.getSessionData(userId);
      return logInResponse;
    }
  },

  async logOutRequest(userId) {
    let data = {
      "username": userId
    };
    let logOutResponse = await this.delete("/api/auth/index.php", data, true);
    if (logOutResponse["status-code"] == 200) {
      this.authToken = undefined;
      localStorage.removeItem("ClomosPass");
      localStorage.removeItem("ClomosAuthToken");
      return true;
    }
    else {
      return false;
    }
  },

  async getSessionData(userId) {
    let data = {
      "username": userId
    };
    let userData = await this.get("/api/auth/index.php", data);
    return userData;
  },

  async validateAccount(validationData) {
    let data = {
      "comandoARealizar":"validarCuenta",
      "pass": Sha256.hash(validationData.password),
      "token": validationData.token
    }
    let validationResponse = await this.put("/api/auth/index.php", data, true);
    if (validationResponse["status-code"] == 200) {
      return true;
    }
    else {
      return false;
    }
  },
  
  async passwordLost(email) {
    let data = {
      "comandoARealizar":"passolvidado",
      "email": email
    }
    let passwordLostResponse = await this.put("/api/auth/index.php", data, true);
    if (passwordLostResponse["status-code"] == 200) {
      return true;
    }
    else {
      return false;
    }
  },

  // GET 
  async getUserData(userId) {
    if (userId === "" || userId === undefined) return;
    let data = {
      "id": userId
    };
    let userData = await this.get("/api/usuarios/", data);
    return userData;
  },

  async getUserList(requestData) {
    if (requestData.nifCif === "" || requestData.nifCif === undefined) return;
    if (requestData.nifCif === "showAll") requestData.nifCif = "";
    let data = {
      "comandoARealizar": "getUsuarioEmpresa",
      "identificadorFiscal": requestData.nifCif || "",
      "limit": requestData.limit || 0,
      "offset": requestData.offset || 0,
      "where": requestData.where || "",
    };
    let userList = await this.get("/api/usuarios/", data);
    return userList;
  },

  async getCompanyData(nifCif) {
    if (nifCif === "" || nifCif === undefined) return;
    let data = {
      "identificadorFiscal": nifCif
    };
    let companyData = await this.get("/api/empresas/", data);
    return companyData;
  },

  async getCompanyList(requestData) {
    if (requestData.nifCif === "" || requestData.nifCif === undefined) return;
    if (requestData.nifCif === "showAll") requestData.nifCif = ""
    let data = {
      "comandoARealizar": "getLista",
      "identificadorFiscal": requestData.nifCif || "",
      "limit": requestData.limit || 0,
      "offset": requestData.offset || 0,
      "where": requestData.where || "",
    };
    let companyList = await this.get("/api/empresas/", data);
    return companyList;
  },

  async getEmplacementData(emplacementId) {
    if (emplacementId === "" || emplacementId === undefined) return;
    let data = {
      "idPlanta": emplacementId
    };
    let emplacementData = await this.get("/api/activos/plantas/", data);
    return emplacementData;
  },

  async getEmplacementList(requestData) {
    if (requestData.nifCif === "" || requestData.nifCif === undefined) return;
    if (requestData.nifCif === "showAll") requestData.nifCif = ""
    let data = {
      "comandoARealizar": "getPlantas",
      "identificadorFiscal": requestData.nifCif || "",
      "limit": requestData.limit || 0,
      "offset": requestData.offset || 0,
      "where": requestData.where || "",
    };
    let emplacementList = await this.get("/api/activos/plantas/", data);
    return emplacementList;
  },

  async getBundleData(bundleId) {
    if (bundleId === "" || bundleId === undefined) return;
    let data = {
      "idConjunto": bundleId
    };
    let bundleData = await this.get("/api/activos/conjuntos/", data);
    return bundleData;
  },

  async getBundleListByCompany(requestData) {
    if (requestData.nifCif === "" || requestData.nifCif === undefined) return;
    if (requestData.nifCif === "showAll") requestData.nifCif = ""
    let data = {
      "comandoARealizar": "getListaConjuntos",
      "identificadorFiscal": requestData.nifCif || "",
      "limit": requestData.limit || 0,
      "offset": requestData.offset || 0,
      "where": requestData.where || "",
    };
    let bundleList = await this.get("/api/activos/conjuntos/", data);
    return bundleList;
  },
    
  async getBundleListByMachine(requestData) {
    if (requestData.machineId === "" || requestData.machineId === undefined) return;
    let data = {
      "comandoARealizar": "getListaConjuntosMaquina",
      "idMaquina": requestData.machineId || "",
      "limit": requestData.limit || 0,
      "offset": requestData.offset || 0,
      "where": requestData.where || "",
    };
    let bundleList = await this.get("/api/activos/conjuntos/", data);
    return bundleList;
  },
  
  async getBundleTypeList(requestData) {
    if (requestData.nifCif === "" || requestData.nifCif === undefined) return;
    let data = {
      "comandoARealizar": "getTiposClaseDcl",
      "clase": 4,
      "identificadorFiscal": requestData.nifCif || "",
      "limit": requestData.limit || 0,
      "offset": requestData.offset || 0,
      "where": requestData.where || "",
    };
    let bundleTypeList = await this.get("/api/catalogo/", data);
    return bundleTypeList;
  },
  
  async getMachineListByCompany(requestData) {
    if (requestData.nifCif === "" || requestData.nifCif === undefined) return;
    if (requestData.nifCif === "showAll") requestData.nifCif = ""
    let data = {
      "comandoARealizar": "getListaSimple",
      "identificadorFiscal": requestData.nifCif || "",
      "limit": requestData.limit || 0,
      "offset": requestData.offset || 0,
      "where": requestData.where || "",
    };
    let machineList = await this.get("/api/activos/maquinas/", data);
    return machineList;
  },

  async getMachineListByEmplacement(requestData) {
    if (requestData.emplacementId === "" || requestData.emplacementId === undefined) return;
    let data = {
      "comandoARealizar": "getMaquinasPlanta",
      "idPlanta": requestData.emplacementId || "",
      "limit": requestData.limit || 0,
      "offset": requestData.offset || 0,
      "where": requestData.where || "",
    };
    let machineList = await this.get("/api/activos/maquinas/", data);
    return machineList;
  },
    
  async getMachineTypeList(requestData) {
    if (requestData.nifCif === "" || requestData.nifCif === undefined) return;
    let data = {
      "comandoARealizar": "getTiposClaseDcl",
      "identificadorFiscal": requestData.nifCif || "",
      "clase": 2,
      "limit": requestData.limit || 0,
      "offset": requestData.offset || 0,
      "where": requestData.where || "",
    };
    let machineTypeList = await this.get("/api/catalogo/", data);
    return machineTypeList;
  },

  async getMachineData(deviceId) {
    if (deviceId === "" || deviceId === undefined) return;
    let data = {
      "idMaquina": deviceId
    };
    let deviceData = await this.get("/api/activos/maquinas/", data);
    return deviceData;
  },

  async getDeviceData(deviceId) {
    if (deviceId === "" || deviceId === undefined) return;
    let data = {
      "idDispositivo": deviceId
    };
    let deviceData = await this.get("/api/activos/dispositivos/", data);
    return deviceData;
  },

  async getDeviceListByCompany(requestData) {
    if (requestData.nifCif === "" || requestData.nifCif === undefined) return;
    if (requestData.nifCif === "showAll") requestData.nifCif = ""
    let data = {
      "comandoARealizar": "getListaSimple",
      "identificadorFiscal": requestData.nifCif || "",
      "limit": requestData.limit || 0,
      "offset": requestData.offset || 0,
      "where": requestData.where || "",
    };
    let deviceList = await this.get("/api/activos/dispositivos/", data);
    return deviceList;
  },

  async getDeviceListByEmplacement(requestData) {
    if (requestData.emplacementId === "" || requestData.emplacementId === undefined) return;
    let data = {
      "comandoARealizar": "getListaSimpleDispositivosPlanta",
      "idPlanta": requestData.emplacementId || "",
      "limit": requestData.limit || 0,
      "offset": requestData.offset || 0,
      "where": requestData.where || "",
    };
    let deviceList = await this.get("/api/activos/dispositivos/", data);
    return deviceList;
  },
  
  async getDeviceTypeList(requestData) {
    if (requestData.nifCif === "" || requestData.nifCif === undefined) return;
    let data = {
      "comandoARealizar": "getTiposClaseDcl",
      "identificadorFiscal": requestData.nifCif || "",
      "clase": 3,
      "limit": requestData.limit || 0,
      "offset": requestData.offset || 0,
      "where": requestData.where || "",
    };
    let deviceTypeList = await this.get("/api/catalogo/", data);
    return deviceTypeList;
  },
  
  async getDeviceVariableHistory(requestData) {
    if (requestData.id === "" || requestData.id === undefined) return;
    let data = {
      "comandoARealizar": "getHistorico",
      "idDispositivo": requestData.id || "",
      "etiqueta": requestData.variableLabel || "",
      "tipoVariable": requestData.variableType || "",
      "limit": requestData.limit || 0,
      "offset": requestData.offset || 0,
      "fechaInicial": requestData.startDateTime || 0,
      "fechaFinal": requestData.endDateTime || 0,
    };
    let deviceVariableHistory = await this.get("/api/activos/dispositivos/historico/", data);
    return deviceVariableHistory;
  },

  async getStockTypeData(stockTypeId) {
    if (stockTypeId === "" || stockTypeId === undefined) return;
    let data = {
      "comandoARealizar": "getTipoDcl",
      "idTipoDcl": stockTypeId,
      "clase": 7
    };
    let stockTypeData = await this.get("/api/catalogo/", data);
    return stockTypeData;
  },

  async getStockList(requestData) {
    if (requestData.nifCif === "" || requestData.nifCif === undefined) return;
    if (requestData.nifCif === "showAll") requestData.nifCif = "";
    let data = {
      "comandoARealizar": "getListaStocksEmpresa",
      "identificadorFiscal": requestData.nifCif || "",
      "limit": requestData.limit || 0,
      "offset": requestData.offset || 0,
      "where": requestData.where || "",
    };
    let stocktList = await this.get("/api/activos/stocks/", data);
    return stocktList;
  },

  async getProductStockList(requestData) {
    if (requestData.nifCif === "" || requestData.nifCif === undefined) return;
    if (requestData.nifCif === "showAll") requestData.nifCif = "";
    let data = {
      "comandoARealizar": "getListaStocksProducto",
      "identificadorFiscal": requestData.nifCif || "",
      "idTipoDCL": requestData.productId,
      "limit": requestData.limit || 0,
      "offset": requestData.offset || 0,
      "where": requestData.where || "",
    };
    let stocktList = await this.get("/api/activos/stocks/", data);
    return stocktList;
  },

  async getStockTypeList(requestData) {
    if (requestData.nifCif === "" || requestData.nifCif === undefined) return;
    if (requestData.nifCif === "showAll") requestData.nifCif = "";
    let data = {
      "comandoARealizar": "getTiposClaseDcl",
      "identificadorFiscal": requestData.nifCif || "",
      "clase": 7,
      "limit": requestData.limit || 0,
      "offset": requestData.offset || 0,
      "where": requestData.where || "",
    };
    let productList = await this.get("/api/catalogo/", data);
    return productList;
  },

  async getOrderData(orderId) {
    if (orderId === "" || orderId === undefined) return;
    let data = {
      "comandoARealizar": "getOrden",
      "idOrden": orderId
    };
    let orderData = await this.get("/api/ordenes/", data);
    return orderData;
  },

  async getReceptionList(requestData) {
    if (requestData.nifCif === "" || requestData.nifCif === undefined) return;
    let data = { 
      "comandoARealizar": "getListaOrdenes",
      "limit": requestData.limit || 0,
      "offset": requestData.offset || 0,
      "where": JSON.stringify({
        "tipoOrden": {
          "operando": "=",
          "valor": 0
        }
      }),
    };
    let orderList = await this.get("/api/ordenes/", data);
    return orderList;
  },
 
  async getExpeditionList(requestData) {
    if (requestData.nifCif === "" || requestData.nifCif === undefined) return;
    let data = {
      "comandoARealizar": "getListaOrdenes",
      "limit": requestData.limit || 0,
      "offset": requestData.offset || 0,
      "where": JSON.stringify({
        "tipoOrden": {
          "operando": "=",
          "valor": 1
        }
      }),
    };
    let orderList = await this.get("/api/ordenes/", data);
    return orderList;
  },
  
    async getRefundList(requestData) {
      if (requestData.nifCif === "" || requestData.nifCif === undefined) return;
      let data = {
        "comandoARealizar": "getListaOrdenes",
        "limit": requestData.limit || 0,
        "offset": requestData.offset || 0,
        "where": JSON.stringify({
          "tipoOrden": {
            "operando": "=",
            "valor": 3
          }
        }),
      };
      let orderList = await this.get("/api/ordenes/", data);
      return orderList;
    },
  
  async getOrderList(requestData) {
    if (requestData.nifCif === "" || requestData.nifCif === undefined) return;
    let data = {
      "comandoARealizar": "getListaOrdenes",
      "limit": requestData.limit || 0,
      "offset": requestData.offset || 0,
      "where": JSON.stringify({
        "tipoOrden": {
          "operando": "=",
          "valor": 4
        }
      }),
    };
    let orderList = await this.get("/api/ordenes/", data);
    return orderList;
  },

  async getDashboardOrderList(requestData={}) {
    let orderList = []
    let data;
    let dateToday = new Date().setHours(0,0,0,0);
    let typeStatusObj= {
      0: [0,1,2],
      1: [0,1,2],
      3: [0,1,2],
      4: [0,1,2]
    };
    for (let k in typeStatusObj) {
      for (let i in typeStatusObj[k]) {
          data = {
            "comandoARealizar": "getListaOrdenes",
            "limit": requestData.limit || 0,
            "offset": requestData.offset || 0,
            "where": JSON.stringify({
              "tipoOrden": {
                "operando": "=",
                "valor": k
              },
              "estado": {
                "operando": "=",
                "valor": i
              },
              "fechaPlanificada": {
                "operando": ">",
                "valor": '"'+this.parseDateTimeToUTCString(dateToday)+'"'
              }
            })
          };
          orderList.push(await this.get("/api/ordenes/", data));
      }
    }
    return orderList;
  },

  async getUserOrderList(requestData) {
    if (requestData.userId === "" || requestData.userId === undefined) return;
    let data = {
      "comandoARealizar": "getListaOrdenes",
      "idUsuario": requestData.userId,
      "limit": requestData.limit || 0,
      "offset": requestData.offset || 0,
      "where": ""
    };
    let orderList = await this.get("/api/ordenes/", data);
    return orderList;
  },

  async getProductOrderList(requestData) {
    if (requestData.productId === "" || requestData.productId === undefined) return;
    let data = {
      "comandoARealizar": "getListaOrdenes",
      "idDcl": requestData.productId,
      "limit": requestData.limit || 0,
      "offset": requestData.offset || 0,
      "where": ""
    };
    let orderList = await this.get("/api/ordenes/", data);
    return orderList;
  },

  async getWarning(warningId) {
    if (warningId === "" || warningId === undefined) return;
    let data = {
      "idAviso": warningId,
    };
    let companyData = await this.get("/api/avisos/", data);
    return companyData;
  },
  
  async getWarningList(requestData) {
    if (requestData.nifCif === "" || requestData.nifCif === undefined) return;
    if (requestData.nifCif === "showAll") requestData.nifCif = ""
    let data = {
      "comandoARealizar": "getListaAvisos",
      "identificadorFiscal": requestData.nifCif || "",
      "limit": requestData.limit || 0,
      "offset": requestData.offset || 0,
      "where": requestData.where || "",
    };
    let companyData = await this.get("/api/avisos/", data);
    return companyData;
  },
  

  // SET
  async setUserData(userData) {
    let data = {
      "usuario":{
        "idUsuario": userData.id,
        "nombre": userData.name,
        "apellido": userData.lastName,
        "identificacionFiscal": userData.nifCif,
        "email": userData.email,
        "telefono": userData.telephone,
        "rol": userData.rol,
        "provincia": userData.province,
        "estado": userData.state,
        "pais": userData.country,
        "direccion1": userData.adress1,
        "direccion2": userData.adress2,
        "idioma": userData.language,
        "unidadTemperatura": userData.unitTemperature,
        "unidadDistancia": userData.unitDistance,
        "zonaHoraria": userData.dateTimeZone,
        "formatoFecha": userData.dateTimeFormat,
        "separadorCampos": userData.fieldSeparator
      },
    };
    if (userData.authorisation != undefined) {
      data["autorizacion"] = {
        "modulosPermitidos": {}
      };
      for (let m in userData.authorisation.availableModules) {
        data.autorizacion.modulosPermitidos[m] = 1;
      }
    }

    let response = await this.put("/api/usuarios/index.php", data, true);
    return response;
  },
  
  async setUserPassword(passwordData) {
    let data = {
      "comandoARealizar": "changePass",
      "new": Sha256.hash(passwordData.newPassword),
      "old": Sha256.hash(passwordData.oldPassword),
    }

    let setUserPasswordResponse = await this.put("/api/usuarios/index.php", data, true);
    if (setUserPasswordResponse["status-code"] == 200) {
      return true;
    }
    else {
      return false;
    }
  },

  async setUserPasswordUnverified(passwordData) {
    let data = {
      "comandoARealizar": "changePass",
      "new": Sha256.hash(passwordData.newPassword),
      "idUsuario": passwordData.userId,
    }

    let setUserPasswordUnverifiedResponse = await this.put("/api/usuarios/index.php", data, true);
    if (setUserPasswordUnverifiedResponse["status-code"] == 200) {
      return true;
    }
    else {
      return false;
    }
  },

  async setCompanyData(companyData) {
    let data = {
        "identificadorFiscal": companyData.nifCif,
        "nombre": companyData.name,
        "emailContacto": companyData.email,
        "telContacto": companyData.telephone,
        "direccion": companyData.adress,
        "pais": companyData.country,
        "sector": companyData.sector,
        "tipoEquipamiento": companyData.equipmentType,
        "parametros": companyData.params,
        "logo":"echarts.png",
    };
    let response = await this.put("/api/empresas/index.php", data, true);
    return response;
  },
  
  async setCompanyImage(companyImageData) {
    let fileData = new FormData();
    fileData.append("fichero", companyImageData.file);
    let writeFileResponse = await this.post("/ficheros/ficheros.php", fileData, true)
    let data;
    let setResponse;
    if (writeFileResponse["nombreInicial"] === companyImageData.file.name) {
      data = {
          "identificadorFiscal": companyImageData.nifCif,
          "logo": writeFileResponse["nombre"]
      };
      setResponse = await this.put("/api/empresas/index.php", data, true);
      console.log(setResponse);
      return true;
    }
    else {
      return false;
    }
  },
  
  async setEmplacementData(emplacementData) {
    let data = {
      "idPlanta": emplacementData.id,
      "etiqueta": emplacementData.label,
      "emplazamiento": emplacementData.location,
      "posicion": emplacementData.geoJSON,

    };
    let response = await this.put("/api/activos/plantas/index.php", data, true);
    return response;
  },

  async setEmplacementImage(emplacementImageData) {
    let fileData = new FormData();
    fileData.append("fichero", emplacementImageData.file);
    let writeFileResponse = await this.post("/ficheros/ficheros.php", fileData, true)
    let data;
    let setResponse;
    if (writeFileResponse["nombreInicial"] === emplacementImageData.file.name) {
      data = {
        "idPlanta": emplacementImageData.id,
        "urlImagen": writeFileResponse["nombre"]
      };
      setResponse = await this.put("/api/activos/plantas/index.php", data, true);
      console.log(setResponse);
      return true;
    }
    else {
      return false;
    }
  },

  async setMachineData(machineData) {
    let data = {
      "idMaquina": machineData.id,
      "etiqueta": machineData.label,
      "emplazamiento": machineData.emplacement
    }
    let response = await this.put("/api/activos/maquinas/index.php", data, true);
    return response;
  },
  
  async setMachineVariables(variablesData) {
    let data = {
      "comandoARealizar": "updateVariablesMaquina",
      "variables": {}
    };
    for (let variable in variablesData) {
      if (variable==="machineId") continue;
      data["variables"][variable]= {
        "idVariable": variablesData[variable].id,
        "idElemento": variablesData.machineId,
        "etiqueta": variable,
        "tipo": variablesData[variable].type,
        "fechaValor": this.parseDateTimeToUTCString(new Date()),
        "valor": variablesData[variable].value,
      }
    }
    let response = await this.put("/api/activos/maquinas/variables/index.php", data, true);
    return response;
  },

  async setMachineParent(linkData) {
    let data = {
      "comandoARealizar": "updatePadreMaquina",
      "idMaquina": linkData.machineId,
      "idPlanta": linkData.emplacementId
    };
    let response = await this.put("/api/activos/maquinas/index.php", data, true);
    return response;
  },

  async setBundleData(bundleData) {
    let data = {
      "idConjunto": bundleData.id,
      "etiqueta": bundleData.label,
      "emplazamiento": bundleData.emplacement,
      "posicion": bundleData.geoJSON
    }
    let response = await this.put("/api/activos/conjuntos/index.php", data, true);
    return response;
  },
  
  async setBundleVariables(variablesData) {
    let data = {
      "comandoARealizar": "updateVariablesConjunto",
      "variables": {}
    };
    for (let variable in variablesData) {
      if (variable==="bundleId") continue;
      data["variables"][variable]= {
        "idVariable": variablesData[variable].id,
        "idElemento": variablesData.bundleId,
        "etiqueta": variable,
        "tipo": variablesData[variable].type,
        "fechaValor": this.parseDateTimeToUTCString(new Date()),
        "valor": variablesData[variable].value,
      }
    }
    let response = await this.put("/api/activos/conjuntos/variables/index.php", data, true);
    return response;
  },

  async setBundleParent(linkData) {
    let data = {
      "comandoARealizar": "updatePadreConjunto",
      "idConjunto": linkData.machineId,
      "idPlanta": linkData.emplacementId
    };
    let response = await this.put("/api/activos/conjuntos/index.php", data, true);
    return response;
  },

  async setDeviceData(deviceData) {
    let data = {
      "idDispositivo": deviceData.id,
      "etiqueta": deviceData.label,
      "emplazamiento": deviceData.emplacement
    }
    let response = await this.put("/api/activos/dispositivos/index.php", data, true);
    return response;
  },
  
  async setDeviceVariables(variablesData) {
    let data = {
      "comandoARealizar": "updateValorVariableDispositivo",
      "variables": {}
    };
    for (let variable in variablesData) {
      if (variable==="deviceId") continue;
      data["variables"][variable]= {
        "idVariable": variablesData[variable].id,
        "idElemento": variablesData.deviceId,
        "etiqueta": variable,
        "tipo": variablesData[variable].type,
        "fechaValor": this.parseDateTimeToUTCString(new Date()),
        "valor": variablesData[variable].value,
      }
    }
    let response = await this.put("/api/activos/dispositivos/variables/index.php", data, true);
    return response;
  },

  async setDeviceParent(linkData) {
    let data = {
      "comandoARealizar": "updatePadreDispositivo",
      "idDispositivo": linkData.deviceId,
      "idPlanta": linkData.emplacementId
    };
    let response = await this.put("/api/activos/dispositivos/index.php", data, true);
    return response;
  },

  async validateProductData(productData) {
    let now = this.parseDateTimeToUTCString(new Date());
    let data = {
      "comandoARealizar": "updateValorVariableStock",
      "variables": {
        "perfilLogistico" : {
          "idVariable": productData.variableIdList["logisticProfile"],
          "idStock": productData.id,
          "etiquetaVariable": "perfilLogistico",
          "tipoVariable": "2",
          "fechaValor": now,
          "valor": productData["logisticProfile"],
        },
        "zona" : {
          "idVariable": productData.variableIdList["zone"],
          "idStock": productData.id,
          "etiquetaVariable": "zona",
          "tipoVariable": "2",
          "fechaValor": now,
          "valor": productData["zone"],
        },
        "huecosHabilitados" : {
          "idVariable": productData.variableIdList["enabledSlots"],
          "idStock": productData.id,
          "etiquetaVariable": "huecosHabilitados",
          "tipoVariable": "2",
          "fechaValor": now,
          "valor": JSON.stringify(productData["enabledSlots"]),
        }
      }
    }
    let response = await this.put("/api/catalogo/index.php", data, true);
    return response;
  },

  async setProductData(productData) {
    let now = this.parseDateTimeToUTCString(new Date());
    let data = {
      "identificadorFiscal": productData.nifCif,
      "idTipoDcl": productData.id,
      "clase": 7,
      "esBase": 0,
      "etiqueta": productData.label,
      "categoria": productData.category
    }
    let response = await this.put("/api/catalogo/index.php", data, true);
    if (response["status-code"]=="200") {
      data = {
        "comandoARealizar": "updateValorVariableStock",
        "variables": {
          "apilable" : {
            "idVariable": productData.variableIdList["stackable"],
            "idStock": productData.id,
            "etiquetaVariable": "apilable",
            "tipoVariable": "0",
            "fechaValor": now,
            "valor": (productData["stackable"]==='true')?1:0,
          },
          "descatalogado" : {
            "idVariable": productData.variableIdList["discontinued"],
            "idStock": productData.id,
            "etiquetaVariable": "descatalogado",
            "tipoVariable": "0",
            "fechaValor": now,
            "valor": (productData["discontinued"]==='true')?1:0,
          },
          "pesoPorUnidad" : {
            "idVariable": productData.variableIdList["unitWeight"],
            "idStock": productData.id,
            "etiquetaVariable": "pesoPorUnidad",
            "tipoVariable": "1",
            "fechaValor": now,
            "valor": productData["unitWeight"],
          },
          "referencia" : {
            "idVariable": productData.variableIdList["reference"],
            "idStock": productData.id,
            "etiquetaVariable": "referencia",
            "tipoVariable": "2",
            "fechaValor": now,
            "valor": productData["reference"],
          },
          "descripcion" : {
            "idVariable": productData.variableIdList["description"],
            "idStock": productData.id,
            "etiquetaVariable": "descripcion",
            "tipoVariable": "2",
            "fechaValor": now,
            "valor": productData["description"],
          },
          "unidad" : {
            "idVariable": productData.variableIdList["unit"],
            "idStock": productData.id,
            "etiquetaVariable": "unidad",
            "tipoVariable": "2",
            "fechaValor": now,
            "valor": productData["unit"],
          },
          "volumen" : {
            "idVariable": productData.variableIdList["volume"],
            "idStock": productData.id,
            "etiquetaVariable": "volumen",
            "tipoVariable": "2",
            "fechaValor": now,
            "valor": JSON.stringify({alto: productData["volume"].height, ancho: productData["volume"].width, fondo: productData["volume"].depth},),
          },
          "temperaturaConservacion" : {
            "idVariable": productData.variableIdList["conservationTemperature"],
            "idStock": productData.id,
            "etiquetaVariable": "temperaturaConservacion",
            "tipoVariable": "2",
            "fechaValor": now,
            "valor": productData["conservationTemperature"],
          },
          "prioridadSalida" : {
            "idVariable": productData.variableIdList["priority"],
            "idStock": productData.id,
            "etiquetaVariable": "prioridadSalida",
            "tipoVariable": "2",
            "fechaValor": now,
            "valor": productData["priority"],
          }
        }
      }
    }
    response = await this.put("/api/catalogo/index.php", data, true);
    return response;
  },

  async setProductImage(productImageData) {
    let fileData = new FormData();
    fileData.append("fichero", productImageData.file);
    let writeFileResponse = await this.post("/ficheros/ficheros.php", fileData, true)
    let data;
    let setResponse;
    if (writeFileResponse["nombreInicial"] === productImageData.file.name) {
      data = {
        "comandoARealizar": "updateValorVariableStock",
        "variables": {
          "urlImagen" : {
            "idVariable": productImageData.variableId,
            "idStock": productImageData.productId,
            "etiquetaVariable": "urlImagen",
            "tipoVariable": "2",
            "valor": writeFileResponse["nombre"],
          }
        }
      };
      setResponse = await this.put("/api/catalogo/index.php", data, true);
      console.log(setResponse);
      return true;
    }
    else {
      return false;
    }
  },

  async setOrderData(orderData) {
    let data = {
      "comandoARealizar": "updateOrden",
      "orden":{
        "idOrden": orderData.id,
        "ejecutor": orderData.userId,
        "parametros": JSON.stringify(orderData.params)
      }
    };
    let response = await this.put("/api/ordenes/", data, true);
      return response;
  },

  async setOrderStatus(statusData) {
    let data = {
      "comandoARealizar":"cambiarEstado",
      "idOrden": statusData.orderId,
      "fecha": this.parseDateTimeToUTCString(statusData.dateTime),
      "estado":statusData.status
  };
    let response = await this.put("/api/ordenes/", data, true);
      return response;
  },

  async setStockData(stockData) {
    let data = {
      "comandoARealizar": "updateStock",
      "data": stockData
    };
    let response = await this.put("/api/activos/dispositivos/index.php", data, true);
    return response;
  },


  // POST
  async postNewUser(userData) {
    let data = {
      "comandoARealizar":"crearUsuario",
      "usuario":{
        "idUsuario": userData.email,
        "nombreUsuario": userData.name,
        "apellido": userData.lastName,
        "identificacionFiscal": userData.nifCif,
        "email": userData.email,
        "telefono": userData.telephonePrefix + userData.telephone,
        "rol": userData.rol,
        "provincia": userData.province,
        "estado": userData.state,
        "pais": userData.country,
        "direccion1": userData.adress1,
        "direccion2": userData.adress2,
        "idioma": userData.language,
        "unidadTemperatura": userData.unitTemperature,
        "unidadDistancia": userData.unitDistance,
        "zonaHoraria": userData.dateTimeZone,
        "formatoFecha": userData.dateTimeFormat,
        "separadorCampos": userData.fieldSeparator
      }
    }
    if (userData.authorisation != undefined) {
      data["autorizacion"] = {
        "modulosPermitidos": {}
      };
      for (let m in userData.authorisation.availableModules) {
        data.autorizacion.modulosPermitidos[m] = 1;
      }
    }
    let response = await this.post("/api/usuarios/", data, true);
    return response;
  },
  
  async postNewWarning(warningData) {
    let data = {
      "identificadorFiscal": warningData.nifCif,
      "etiqueta": warningData.label,
      "descripcion": warningData.description,
      "idTipo": 0,
      "permisoLectura": 0,
      "enviarEmail": 0,
      "enviarSms": 0,
      "esAlarma": 0,
      "esNotificacion": 1,
      "estaActivado": 0,
      "estadoAviso": 0,
      "eventos": "",
      "fechaActivacion": "",
      "fechaPreactivo": "",
      "fechaPostactivo": "",
      "fechaNotificacion": "",
      "fechaLeido": "",
      "fechaSilenciado": "",
      "grupoDistribucion": 0,
      "tiempoEnPreactivo": 0,
      "tiempoEnPostactivo": 0,
      "tiempoRepeticionNotificacion": 0,
    };
    let response = await this.post("/api/empresas/", data, true);
    return response;
  },

  async postNewEmplacement(emplacementData) {
    let data = {
      "identificadorFiscal": emplacementData.nifCif,
      "etiqueta": emplacementData.label,
      "emplazamiento": emplacementData.location,
      "posicion": emplacementData.geoJSON,
      "idTipo": "emplazamiento",
      "padre": "",
      "isPublic": 0
    };
    let response = await this.post("/api/activos/plantas/", data, true);
    return response;
  },

  async postNewMachine(machineData) {
    let data = {
      "identificadorFiscal": machineData.nifCif,
      "etiqueta": machineData.label,
      "idTipo": machineData.typeId,
      "padre": machineData.emplacementId,
      "isPublic": 0,
      "posicion": {}
    };
    let response = await this.post("/api/activos/maquinas/", data, true);
    return response;
  },

  async postNewBundle(bundleData) {
    let data = {
      "identificadorFiscal": bundleData.nifCif,
      "etiqueta": bundleData.label,
      "idTipo": bundleData.typeId,
      "padre": bundleData.machineId,
      "isPublic": 0,
      "posicion": bundleData.geoJSON
    };
    let response = await this.post("/api/activos/conjuntos/", data, true);
    return response;
  },

  async postNewDevice(deviceData) {
    let data = {
      "identificadorFiscal": deviceData.nifCif,
      "etiqueta": deviceData.label,
      "idTipo": deviceData.typeId,
      "padre": "",
      "isPublic": 0,
      "posicion": {}
    };
    let response = await this.post("/api/activos/dispositivos/", data, true);
    return response;
  },

  async postNewProduct(productData) {
    let now = this.parseDateTimeToUTCString(new Date());
    let data = {
      "identificadorFiscal": productData.nifCif,
      "clase": 7,
      "esBase": 1,
      "etiqueta": productData.label,
      "categoria": productData.category,
      "variablesAnalogicas": [
        {"etiqueta":"pesoPorUnidad","accesoLectura":"130","accesoEscritura":"255","esConfiguracion":"0","guardarHistorico":"0","grupoDistribucion":"0","direccion":"","accesoExterno":"1",
          "valor":productData.unitWeight,"fechaValor":now },
        {"etiqueta":"stockFisico","accesoLectura":"130","accesoEscritura":"255","esConfiguracion":"0", "guardarHistorico":"1","grupoDistribucion":"0","direccion":"","accesoExterno":"1",
          "valor":"0","fechaValor":now },
        {"etiqueta":"stockNeto","accesoLectura":"130","accesoEscritura":"255","esConfiguracion":"0", "guardarHistorico":"1","grupoDistribucion":"0","direccion":"","accesoExterno":"1",
          "valor":"0","fechaValor":now },
        {"etiqueta":"stockDisponible","accesoLectura":"130","accesoEscritura":"255","esConfiguracion":"0", "guardarHistorico":"1","grupoDistribucion":"0","direccion":"","accesoExterno":"1",
          "valor":"0","fechaValor":now },
      ],
      "variablesDigitales": [
        {"etiqueta": "apilable","accesoLectura": "255","accesoEscritura": "130","esConfiguracion":"0","guardarHistorico": "0", "grupoDistribucion": "0", "direccion": "", "accesoExterno": "",
          "valor":(productData.stackable==='true')?1:0,"fechaValor":now },
        {"etiqueta": "bloqueado","accesoLectura": "255","accesoEscritura": "130","esConfiguracion":"0","guardarHistorico": "0", "grupoDistribucion": "0", "direccion": "", "accesoExterno": "",
          "valor":"0","fechaValor":now },
        {"etiqueta": "descatalogado","accesoLectura": "255","accesoEscritura": "130","esConfiguracion":"0","guardarHistorico": "0", "grupoDistribucion": "0", "direccion": "", "accesoExterno": "",
          "valor":"0","fechaValor":now }
      ],
      "variablesDinamicas": [
        {"etiqueta":"referencia","accesoLectura":"130","accesoEscritura":"255","esConfiguracion":"0","guardarHistorico":"0","tipoVar":"string","grupoDistribucion":"0","direccion":"","accesoExterno":"1",
          "valor":productData.reference,"fechaValor":now },
        {"etiqueta":"descripcion","accesoLectura":"130","accesoEscritura":"255","esConfiguracion":"0","guardarHistorico":"0","tipoVar":"string","grupoDistribucion":"0","direccion":"","accesoExterno":"1",
          "valor":productData.description,"fechaValor":now },
        {"etiqueta":"unidad","accesoLectura":"130","accesoEscritura":"255","esConfiguracion":"0","guardarHistorico":"0","tipoVar":"string","grupoDistribucion":"0","direccion":"","accesoExterno":"1",
          "valor":productData.unit,"fechaValor":now },
        {"etiqueta":"volumen","accesoLectura":"130","accesoEscritura":"255","esConfiguracion":"0","guardarHistorico":"0","tipoVar": "json","grupoDistribucion":"0","direccion":"","accesoExterno":"1",
          "valor":JSON.stringify({alto: productData.volume.height, ancho: productData.volume.width, fondo: productData.volume.depth}),"fechaValor":now },
        {"etiqueta":"urlImagen","accesoLectura":"130","accesoEscritura":"255","esConfiguracion":"0","guardarHistorico":"0","tipoVar": "json","grupoDistribucion":"0","direccion":"","accesoExterno":"1",
          "valor":"", "fechaValor":now },
        {"etiqueta":"temperaturaConservacion","accesoLectura":"130","accesoEscritura":"255","esConfiguracion":"0","guardarHistorico":"0","tipoVar":"string","grupoDistribucion":"0","direccion":"","accesoExterno":"1",
          "valor":productData.conservationTemperature,"fechaValor":now },
        {"etiqueta":"prioridadSalida","accesoLectura":"130","accesoEscritura":"255","esConfiguracion":"0","guardarHistorico":"0","tipoVar":"string","grupoDistribucion":"0","direccion":"","accesoExterno":"1",
          "valor":productData.priority,"fechaValor":now },
        
        {"etiqueta":"zona","accesoLectura":"130","accesoEscritura":"255","esConfiguracion":"0","guardarHistorico":"0","tipoVar":"string","grupoDistribucion":"0","direccion":"","accesoExterno":"1",
            "valor":"","fechaValor":now },
        {"etiqueta":"huecosHabilitados","accesoLectura":"130","accesoEscritura":"255","esConfiguracion":"0","guardarHistorico":"0","tipoVar":"string","grupoDistribucion":"0","direccion":"","accesoExterno":"1",
          "valor":"","fechaValor":now },
        {"etiqueta":"perfilLogistico","accesoLectura":"130","accesoEscritura":"255","esConfiguracion":"0","guardarHistorico":"0","tipoVar":"string","grupoDistribucion":"0","direccion":"","accesoExterno":"1",
          "valor":"","fechaValor":now },
      ]
    };
    // if (productData.expirationDate === "true" || productData.expirationDate === "important")
      data["variablesDinamicas"].push({"etiqueta":"fechaCaducidad","accesoLectura":"255","accesoEscritura":"130","esConfiguracion":"1","guardarHistorico":"0","tipoVar":"fecha","grupoDistribucion":"0","direccion":"","accesoExterno":"1"});
    // if (productData.size === "true" || productData.size === "important")
      data["variablesDinamicas"].push({"etiqueta":"talla","accesoLectura":"255","accesoEscritura":"130","esConfiguracion":"1","guardarHistorico":"0","tipoVar":"string","grupoDistribucion":"0","direccion":"","accesoExterno":"1"});
    // if (productData.color === "true" || productData.color === "important")
      data["variablesDinamicas"].push({"etiqueta":"color","accesoLectura":"255","accesoEscritura":"130","esConfiguracion":"1","guardarHistorico":"0","tipoVar":"string","grupoDistribucion":"0","direccion":"","accesoExterno":"1"});
    // if (productData.batch === "true" || productData.batch === "important")
      data["variablesDinamicas"].push({"etiqueta":"lote","accesoLectura":"255","accesoEscritura":"130","esConfiguracion":"1","guardarHistorico":"0","tipoVar":"string","grupoDistribucion":"0","direccion":"","accesoExterno":"1"});
    // if (productData.serialNumber === "true" || productData.serialNumber === "important")
      data["variablesDinamicas"].push({"etiqueta":"numeroSerie","accesoLectura":"255","accesoEscritura":"130","esConfiguracion":"1","guardarHistorico":"0","tipoVar":"string","grupoDistribucion":"0","direccion":"","accesoExterno":"1"});
    let response = await this.post("/api/catalogo/", data, true);
    return response;
  },

  async postNewReception(orderData) {
    let setData = {
      "comandoARealizar":"createOrden",
      "orden":{
        //"idOrden": orderData.id,
        "ejecutor": orderData.userId,
        "rolesEjecucion": 255,
        "tipoOrden": 0,
        "operacionesAEjecutar":{
          "OnCreate":[],
          "OnExecute":[],
          "OnEndExecute":[{"nombreOperacion":"ModificarStock","modificarFisico":1,"modificarNeto":1,"modificarDisponible":0,"operacion":"+"}],
          "OnFinish":[],
          "OnToPlan":[{"nombreOperacion":"PlanificarEntradaStock"},{"nombreOperacion": "InformarEntrada"}],
          "OnCancel":[]
        },
        "parametros": {
          "productos": {},
          "bultos": {},
        },
      }
    };
    for (let target in orderData.targetList) {
      setData.orden.parametros.productos[target] = {
        "id": orderData.targetList[target].id, 
        "referencia": orderData.targetList[target].reference, 
        "etiqueta": orderData.targetList[target].label, 
        "perfilLogistico": orderData.targetList[target].logisticProfile, 
        "zona": orderData.targetList[target].zone, 
        "unidad": orderData.targetList[target].unit, 
        "unidades": orderData.targetList[target].ammount, 
        "bultos": [] 
      };
      if(orderData.targetList[target].batch != undefined)setData.orden.parametros.productos[target]["talla"] = orderData.targetList[target].batch;
      if(orderData.targetList[target].serialNumber != undefined)setData.orden.parametros.productos[target]["talla"] = orderData.targetList[target].serialNumber;
      if(orderData.targetList[target].expirationDate != undefined)setData.orden.parametros.productos[target]["fechaCaducidad"] = (orderData.targetList[target].expirationDate!="")?this.parseDateTimeToUTCString(orderData.targetList[target].expirationDate):"";
      if(orderData.targetList[target].size != undefined)setData.orden.parametros.productos[target]["talla"] = orderData.targetList[target].size;
      if(orderData.targetList[target].color != undefined)setData.orden.parametros.productos[target]["color"] = orderData.targetList[target].color;
    }
    setData.orden.parametros = JSON.stringify(setData.orden.parametros);
    setData.orden.operacionesAEjecutar = JSON.stringify(setData.orden.operacionesAEjecutar);
    let response = await this.post("/api/ordenes/", setData, true);
    return response;
  },

  async postNewExpedition(orderData) {
    let setData = {
      "comandoARealizar":"createOrden",
      "orden":{
        //"idOrden": orderData.id,
        "ejecutor": orderData.userId,
        "rolesEjecucion": 255,
        "tipoOrden": 1,
        "operacionesAEjecutar": {
          "OnCreate":[{"nombreOperacion":"ModificarStock","modificarNeto":1,"operacion":"-"}],
          "OnExecute":[],
          "OnEndExecute":[{"nombreOperacion":"ModificarStock","modificarFisico":1,"modificarDisponible":1,"operacion":"-"},{"nombreOperacion":"ActualizarEstadoPedidoSGA","cancelar":"0"}],
          "OnFinish":[],
          "OnToPlan":[{"nombreOperacion":"PlanificarSalidaStock"},{"nombreOperacion":"InformarPedido"}],
          "OnCancel":[{"nombreOperacion":"ActualizarEstadoPedidoSGA","cancelar":"1"}]
        },
        "parametros": {
          "productos": {},
          "bultos": []
        },
      }
    };
    for (let target in orderData.targetList) {
      setData.orden.parametros.productos[target] = {
        "id": orderData.targetList[target].id, 
        "referencia": orderData.targetList[target].reference, 
        "etiqueta": orderData.targetList[target].label, 
        "perfilLogistico": orderData.targetList[target].logisticProfile, 
        "zona": orderData.targetList[target].zone, 
        "unidad": orderData.targetList[target].unit, 
        "unidades": orderData.targetList[target].ammount, 
        "bultos": [] 
      };
      if(orderData.targetList[target].batch != undefined)setData.orden.parametros.productos[target]["lote"] = orderData.targetList[target].batch;
      if(orderData.targetList[target].serialNumber != undefined)setData.orden.parametros.productos[target]["numeroSerie"] = orderData.targetList[target].serialNumber;
      if(orderData.targetList[target].expirationDate != undefined)setData.orden.parametros.productos[target]["fechaCaducidad"] = (orderData.targetList[target].expirationDate!="")?this.parseDateTimeToUTCString(orderData.targetList[target].expirationDate):"";
      if(orderData.targetList[target].size != undefined)setData.orden.parametros.productos[target]["talla"] = orderData.targetList[target].size;
      if(orderData.targetList[target].color != undefined)setData.orden.parametros.productos[target]["color"] = orderData.targetList[target].color;
    }
    setData.orden.parametros = JSON.stringify(setData.orden.parametros);
    setData.orden.operacionesAEjecutar = JSON.stringify(setData.orden.operacionesAEjecutar);
    let response = await this.post("/api/ordenes/", setData, true);
    return response;
  },

  async postNewOrder(orderData) {
    let setData = {
      "comandoARealizar":"createOrden",
      "orden":{
        //"idOrden": orderData.id,
        "ejecutor": orderData.userId,
        "rolesEjecucion": 255,
        "tipoOrden": 4,
        "operacionesAEjecutar": {
          "OnExecute":[],
          "OnCreate":[{"nombreOperacion":"ModificarStock","modificarNeto":1,"operacion":"-"}],
          "OnEndExecute":[{"nombreOperacion":"ModificarStock","modificarFisico":1,"operacion":"-"},{"nombreOperacion":"ActualizarEstadoPedidoSGA","cancelar":"0"}],
          "OnFinish":[],
          "OnToPlan":[{"nombreOperacion": "InformarPedido"}],
          "OnCancel":[{"nombreOperacion":"ActualizarEstadoPedidoSGA","cancelar":"1"}]
        },
        "parametros": {
          "productos": {},
          "bultos": [],
        },
      }
    };
    for (let target in orderData.targetList) {
      setData.orden.parametros.productos[target] = {
        "id": orderData.targetList[target].id, 
        "referencia": orderData.targetList[target].reference, 
        "etiqueta": orderData.targetList[target].label, 
        "perfilLogistico": orderData.targetList[target].logisticProfile, 
        "zona": orderData.targetList[target].zone, 
        "unidad": orderData.targetList[target].unit, 
        "unidades": orderData.targetList[target].ammount, 
        "bultos": [] 
      };
      if(orderData.targetList[target].batch != undefined)setData.orden.parametros.productos[target]["lote"] = orderData.targetList[target].batch;
      if(orderData.targetList[target].serialNumber != undefined)setData.orden.parametros.productos[target]["numeroSerie"] = orderData.targetList[target].serialNumber;
      if(orderData.targetList[target].expirationDate != undefined)setData.orden.parametros.productos[target]["fechaCaducidad"] = this.parseDateTimeToUTCString(orderData.targetList[target].expirationDate);
      if(orderData.targetList[target].size != undefined)setData.orden.parametros.productos[target]["talla"] = orderData.targetList[target].size;
      if(orderData.targetList[target].color != undefined)setData.orden.parametros.productos[target]["color"] = orderData.targetList[target].color;
    }
    setData.orden.parametros = JSON.stringify(setData.orden.parametros);
    setData.orden.operacionesAEjecutar = JSON.stringify(setData.orden.operacionesAEjecutar);
    let response = await this.post("/api/ordenes/", setData, true);
    return response;
  },

  async postNewRefund(orderData) {
    let setData = {
      "comandoARealizar":"createOrden",
      "orden":{
        //"idOrden": orderData.id,
        "ejecutor": orderData.userId,
        "rolesEjecucion": 255,
        "tipoOrden": 3,
        "operacionesAEjecutar":{
          "OnCreate":[],
          "OnExecute":[],
          "OnEndExecute":[{"nombreOperacion":"ModificarStock","modificarFisico":1,"modificarNeto":1,"operacion":"+"}],
          "OnFinish":[],
          "OnToPlan":[{"nombreOperacion": "InformarEntrada"}],
          "OnCancel":[]
        },
        "parametros": {
          "productos": {},
          "bultos": [],
        },
      }
    };
    for (let target in orderData.targetList) {
      setData.orden.parametros.productos[target] = {
        "id": orderData.targetList[target].id, 
        "referencia": orderData.targetList[target].reference, 
        "etiqueta": orderData.targetList[target].label, 
        "perfilLogistico": orderData.targetList[target].logisticProfile, 
        "zona": orderData.targetList[target].zone, 
        "unidad": orderData.targetList[target].unit, 
        "unidades": orderData.targetList[target].ammount, 
        "bultos": [] 
      };
      if(orderData.targetList[target].batch != undefined)setData.orden.parametros.productos[target]["talla"] = orderData.targetList[target].batch;
      if(orderData.targetList[target].serialNumber != undefined)setData.orden.parametros.productos[target]["talla"] = orderData.targetList[target].serialNumber;
      if(orderData.targetList[target].expirationDate != undefined)setData.orden.parametros.productos[target]["fechaCaducidad"] = this.parseDateTimeToUTCString(orderData.targetList[target].expirationDate);
      if(orderData.targetList[target].size != undefined)setData.orden.parametros.productos[target]["talla"] = orderData.targetList[target].size;
      if(orderData.targetList[target].color != undefined)setData.orden.parametros.productos[target]["color"] = orderData.targetList[target].color;
    }
    setData.orden.parametros = JSON.stringify(setData.orden.parametros);
    setData.orden.operacionesAEjecutar = JSON.stringify(setData.orden.operacionesAEjecutar);
    let response = await this.post("/api/ordenes/", setData, true);
    return response;
  },
  
  async postNewRegularization(orderData) {
    let setData = {
      "comandoARealizar":"createOrden",
      "orden":{
      //"idOrden": orderData.id,
        "ejecutor": orderData.userId,
        "rolesEjecucion": 255,
        "tipoOrden": 2,
        "operacionesAEjecutar":{
          "OnCreate":[{"nombreOperacion":"RegularizarStock","modificarFisico":(orderData.targetStockReal)?1:0,"modificarNeto":(orderData.targetStockVirtual)?1:0,"modificarDisponible":(orderData.targetStockAvailable)?1:0,"operacion":orderData.operation}],
          "OnExecute":[],
          "OnEndExecute":[],
          "OnFinish":[],
          "OnToPlan":[],
          "OnCancel":[]
        },
        "parametros": {
          "idStock": orderData.stockId,
          "idHueco": orderData.slotId,
          "unidades": orderData.units
        }
      }
    };
    setData.orden.parametros = JSON.stringify(setData.orden.parametros);
    setData.orden.operacionesAEjecutar = JSON.stringify(setData.orden.operacionesAEjecutar);
    let response = await this.post("/api/ordenes/", setData, true);
    return response;
  },

  async postNewCompany(companyData) {
    let data = {
      "empresa": {
        "identificacionFiscal": companyData.nifCif,
        "nombre": companyData.name,
        "emailContacto": companyData.email,
        "telContacto": companyData.telephonePrefix + companyData.telephone,
        "direccion": companyData.adress,
        "pais": companyData.country,
        "sector": companyData.sector,
        "tipoEquipamiento": companyData.equipmentType,
        "parametros": companyData.params,
      }
    };
    let response = await this.post("/api/empresas/", data, true);
    return response;
  },

  // DELETE
  async deleteUsers(userIdList) {
    let data = {
      "comandoARealizar":"users",
      "usuarios":[]
    };
    for (let i in userIdList) {
      data["usuarios"][i] = {
          "idUsuario": userIdList[i]
      }
    }
    let response = await this.delete("/api/usuarios/index.php", data, true);
    return response;
  },

  async deleteCompany(nifCif) {
    let data = {
      "identificacionFiscal": nifCif,
    }
    let response = await this.delete("/api/empresas/index.php", data, true);
    return response;
  },
  
  async deleteEmplacement(companyId) {
    let data = {
      "idPlanta": companyId,
    }
    let response = await this.delete("/api/activos/plantas/index.php", data, true);
    return response;
  },
  
  async deleteMachine(machineId) {
    let data = {
      "idMaquina": machineId,
    }
    let response = await this.delete("/api/activos/maquinas/index.php", data, true);
    return response;
  },

  async deleteBundle(bundleId) {
    let data = {
      "idConjunto": bundleId,
    }
    let response = await this.delete("/api/activos/conjuntos/index.php", data, true);
    return response;
  },

  async deleteDevice(deviceId) {
    let data = {
      "idDispositivo": deviceId,
    }
    let response = await this.delete("/api/activos/dispositivos/index.php", data, true);
    return response;
  },
  
  async deleteProduct(productId) {
    let data = {
      "idTipo": productId,
      "clase": 7,
    }
    let response = await this.delete("/api/catalogo/index.php", data, true);
    return response;
  },

  async deleteOrder(orderId) {
    let data = {
      "idOrden": orderId,
    }
    let response = await this.delete("/api/ordenes/index.php", data, true);
    return response;
  },

  async deleteWarning(warningId) {
    let data = {
      "idAviso": warningId,
    }
    let response = await this.delete("/api/avisos/index.php", data, true);
    return response;
  }
};