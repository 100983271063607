<template>

<!-- SECTION Companies -->
  <div id="companiesSection" class="col-12 justify-content-center pe-3" 
  :class="(visibility.sideMenu)?'section-container-sm' :'section-container-lg'">
    <div class="col-12 p-0 m-0 bg-caja section-container rounded-5">

      <!-- HEADER Companies -->
      <div class="row col-12 m-0 p-0 text-white bg-clomos rounded-top-5 pb-1 h-100" >
        
        <div class="col-lg-6 overflow-hidden col-md-12 col-12 justify-content-center" style="display: inherit">
          <!--<ion-icon v-if="session.user.bookmark==='companies'" name="bookmark" class="bookmark-button"></ion-icon>
          <ion-icon v-else name="bookmark-outline" class="bookmark-button"></ion-icon>-->
          <h4 class="tx-left tx-clomos-white text-nowrap mb-2 ps-1 pt-2" style="height:40px;">

            <ion-icon name="briefcase-outline" class="tx-27 me-2 align-bottom"></ion-icon>
            {{dictionary["Empresas relacionadas"][session.user.language]}}
          </h4>
        </div>

      <!-- Selector de Sistema -->
        <div class="col-lg-3 col-md-7 col-12 p-2 pb-1 justify-content-center">	
          <select
          :value="currentCompany.nifCif"
          class="form-control h-100 text-nowrap bg-white" 
          style="border: solid 2px white ; color: black;"
          @change="onCurrentCompanyChanged">
            <option v-if="availableCompanies[0]!=undefined" value="showAll"> {{ dictionary['Mostrar todo'][session.user.language] }} </option>
            <option v-for="company in availableCompanies" :key="company.nifCif"
            :v-if="company.nifCif != session.user.nifCif
            && company.relation != undefined
            && company.relation.referenceCompany === this.session.user.nifCif"
            :value="company.nifCif">
              {{(company.nifCif===session.user.nifCif)?"✪ "+company.name :company.name}}
            </option>
          </select>
        </div>

        <div class="col-lg-3 col-md-5 col-12 ms-auto p-2 pb-1 justify-content-center">	
          <button id="btnDisplayNewCompanyModal"
          class="btn btn-clomos bg-white rounded p-1 inner-border fw-normal text-nowrap"
          style="width: 100%;s height:100%; vertical-align: middle; border: solid 2px white"
          @click="toggleNewCompanyModal">
              <ion-icon name="add-circle-outline" class="tx-clomos-dark me-2 tx-20" style="vertical-align: middle; margin-top: -0.25rem;"></ion-icon>
              <span class="ms-auto tx-clomos-dark">{{dictionary["Añadir empresa"][session.user.language]}}</span>
          </button>
        </div>
      </div>

      <div class="m-0 p-3 overflow-auto">
        <table id="companyDataTable" class="display w-100 border-1 p-0 m-0 rounded-3">
          <thead class="bg-clomos-light">
            <tr>
              <th v-for="column in companiesColumns" :key="column.data"
              :width="column.width" class="tx-black fw-medium text-nowrap">
                {{dictionary[column.text][session.user.language]}}
              </th>
            </tr>
          </thead>
        </table>
      </div>

    </div>
  </div>

  <!-- MODAL Edit Company -->
  <NewCompanyModal
  v-if="display.newCompanyModal"
  :session="session"
  :dictionary="dictionary"
  :countries="countries"
  :timeZones="timeZones"
  :currentCompany="currentCompany"
  v-on:toggleNewCompanyModal="toggleNewCompanyModal">
  </NewCompanyModal>

  <!-- MODAL New Company -->
  <EditCompanyModal
  v-if="display.editCompanyModal"
  :session="session"
  :dictionary="dictionary"
  :countries="countries"
  :timeZones="timeZones"
  :companyId="targetCompanyId"
  v-on:toggleEditCompanyModal="toggleEditCompanyModal">
  </EditCompanyModal>

  <!-- MODAL Delete Text Alert -->
  <TextAlertModal
  v-if="display.deleteCompanyModal"
  :session="session"
  :dictionary="dictionary"
  :countries="countries"
  :alertHeader="dictionary['Empresa a eliminar'][session.user.language] + ': '"
  :alertTarget="targetCompanyId"
  v-on:acceptAlert="onDeleteCompany"
  v-on:dismissAlert="toggleDeleteCompanyModal">
  </TextAlertModal>

</template>

<script>
import $ from 'jquery';
import NewCompanyModal from '@/components/companies/NewCompanyModal';
import EditCompanyModal from '@/components/companies/EditCompanyModal';
import TextAlertModal from '@/components/other/TextAlertModal';
// Clomos
import api from '@/services/api';

export default {
  name: "CompaniesComponent",
  components: {  NewCompanyModal, EditCompanyModal, TextAlertModal },
  props: {
    session: {
      type: Object,
      requiered: true,
    },
    dictionary: {
      type: Object,
      requiered: true,
    },
    countries: {
      type: Object,
      requiered: true,
    },
    timeZones: {
      type: Object,
      requiered: true,
    },
    visibility: {
      type: Object,
      requiered: true,
    },
    currentCompany: {
      type: Object,
      requiered: true,
    },
    availableCompanies: {
      type: Object,
      requiered: true,
    },
    dataTableOptions: {
      type: Object,
      requiered: true,
    }
  },
  watch: { 
    currentCompany: async function() {
      await this.getCompanyList();
      this.displayCompaniesDataTable();
    }
  },
  data() {
    return {
      companiesColumns: [
        {text:"NIF/CIF", data: "companyNifCif", width: "10%", className:"tx-left",
        render: (data) => {
            return "<span data-id="+data+" class='btnTargetCompany "+/*"btn "+*/"tx-bold tx-clomos-midtone text-nowrap'>"+data
            +"<ion-icon name='copy-outline' data-id="+data+" class='btnCopyId btn tx-24 tx-clomos-midtone'></ion-icon></span>";
          }
        },
        {text:"Nombre", data: "companyName", width: "20%", className:"tx-left"},
        {text:"Dirección", data: "companyAdress", width: "20%", className:"tx-left"},
        {text:"Sector", data: "companySector", width: "20%", className:"tx-left",
        render: (data) => {
            return data.charAt(0).toUpperCase() + data.slice(1)
          }
        },
        {text:"Relación", data: "companyRelation", width: "20%", className:"tx-left text-nowrap",
          render: (data) => {
            let ref = "";
            let dest = "";
            if (this.availableCompanies.length > 0) {
              for (let company in this.availableCompanies) {
                if (this.availableCompanies[company].nifCif === data.reference )
                  ref = this.availableCompanies[company].name;
                if (this.availableCompanies[company].nifCif === data.destination )
                  dest = this.availableCompanies[company].name;
                }
              return ref+"<span class='tx-primary text-nowrap'> ➜ </span>"+dest;
            } else {
              return data.reference+"<span class='tx-primary text-nowrap'> ➜ </span>"+data.destination;
            }
          }
        },
        {text:"Editar", data: "companyId", width: "5%",
          render: (data) => {
            return "<ion-icon name='create-outline' data-id="+data+" class='btnEditCompany btn tx-24 tx-primary  p-0 m-0'></ion-icon>"
          }
        },
        {text:"Eliminar", data: "companyId", width: "5%",
          render: (data) => {
            return "<ion-icon name='trash-outline' data-id="+data+" class='btnDeleteCompany btn tx-24 tx-primary  p-0 m-0'></ion-icon>"
          }
        }
      ],
      targetCompanyId: this.$route.params.targetEntity,
      companyList: [],
      display: {
        newCompanyModal: false,
        editCompanyModal: false,
        deleteCompanyModal: false,
      }
    }
  },
  emits: ["setCurrentCompany", "getAvailableCompanies"],
  methods: {
    // DISPLAY
    onCurrentCompanyChanged(event) {
      let value = event.target.value;
      this.$router.replace({params: {currentCompanyNifCif: value}})
      this.$emit('setCurrentCompany',value);
    },
    displayCompaniesDataTable() {
      $("#companyDataTable").DataTable().clear()
      for (let i in this.companyList) {
        $("#companyDataTable").DataTable().rows.add([{
          companyId: this.companyList[i].nifCif, 
          companyNifCif: this.companyList[i].nifCif, 
          companyName: this.companyList[i].name, 
          companyAdress: this.companyList[i].adress, 
          companySector: this.companyList[i].sector, 
          companyRelation: {reference: this.companyList[i].relation.referenceCompany, destination: this.companyList[i].relation.destinationCompany}
        }]);
      }
      $("#companyDataTable").DataTable().draw();
      this.setButtonFunctions();
      if($(".dataTables_paginate").get()[0] != undefined) $(".dataTables_paginate").get()[0].addEventListener('click', this.setButtonFunctions);
      $(".sorting").get().forEach((btn)=> { btn.addEventListener('click', this.setButtonFunctions)});
},
    async toggleNewCompanyModal() {
      if (this.display.newCompanyModal) {
        await this.getCompanyList();
        this.$emit('getAvailableCompanies',(this.displayCompaniesDataTable));
      }
      this.display.newCompanyModal = !this.display.newCompanyModal;
    },
    async toggleEditCompanyModal() {
      if (this.display.editCompanyModal) {
        await this.getCompanyList();
        this.$emit('getAvailableCompanies',(this.displayCompaniesDataTable));
      }
      this.display.editCompanyModal = !this.display.editCompanyModal;
    },
    async toggleDeleteCompanyModal() {
      if (this.display.deleteCompanyModal) {
        await this.getCompanyList();
        this.$emit('getAvailableCompanies',(this.displayCompaniesDataTable));
      }
      this.display.deleteCompanyModal = !this.display.deleteCompanyModal;
    },
    // BUTTONS 
    setButtonFunctions() {
      setTimeout(()=>{
        $(".btnTargetCompany").get().forEach(btn => {
          btn.addEventListener('click', this.onTargetCompany);
        });
        $(".btnEditCompany").get().forEach(btn => {
          btn.addEventListener('click', this.onEditCompany);
        });
        $(".btnDeleteCompany").get().forEach(btn => {
          btn.addEventListener('click', this.onDeleteCompany);
        });
        $(".btnCopyId").get().forEach(btn => {
          btn.addEventListener('click', this.onCopyId);
        });
      }, 300);
    },
    onCopyId(event) {
      navigator.clipboard.writeText(event.target.getAttribute('data-id'));
    },
    // GET
    async getCompanyList(callback) {
      if (this.currentCompany.nifCif === "" || this.currentCompany.nifCif === undefined) return;
      let request = {
        nifCif: this.currentCompany.nifCif,
        limit: 0,
        offset: 0,  
        where: "",
      };
      let response = await api.getCompanyList(request);
      this.companyList = api.parseCompaniesFromResponse(response);
      if (callback && typeof callback === "function") callback(this.companyList);
    },
    // TARGET 
    onTargetCompany(event) {
      console.log('TARGET WHERE ID:');
      console.log(event.target.getAttribute('data-id'));
      this.targetCompanyId = event.target.getAttribute('data-id');
      this.$router.replace({params: {targetEntity: this.targetCompanyId}});
    },
    // SET
    onEditCompany(event) {
      console.log('SHOW WHERE ID:');
      console.log(event.target.getAttribute('data-id'));
      this.targetCompanyId = event.target.getAttribute('data-id');
      this.$router.replace({params: {targetEntity: this.targetCompanyId}});
      this.toggleEditCompanyModal();
      },
    // DELETE
    async onDeleteCompany(event) {
      if (!this.display.deleteCompanyModal) {
        console.log('DELETE WHERE ID:');
        console.log(event.target.getAttribute('data-id'));
        this.targetCompanyId = event.target.getAttribute('data-id');
        this.$router.replace({params: {targetEntity: this.targetCompanyId}});
      }
      else {
        let response = await api.deleteCompany(this.targetCompanyId);
        console.log(response);
      }
      this.toggleDeleteCompanyModal();
    }
  },
  async created() {
    await this.getCompanyList();
    this.$emit('getAvailableCompanies',(this.displayCompaniesDataTable))
  },
  mounted() {
    $("#companyDataTable").DataTable(
      {
        pagingType: 'full_numbers',
        pageLength: 10,
        processing: true,
        dom: 'Bfrtip',
        buttons: [
          { "extend": 'colvis', "className": 'btn' },
          { "extend": 'copy', "className": 'btn ms-auto' },
          { "extend": 'csv', "className": 'btn' },
          { "extend": 'print', "className": 'btn me-0' },
        ],
        columns: this.companiesColumns,
        select: this.dataTableOptions.select,
        language: this.dataTableOptions.language[this.session.user.language],
        stateSave: this.dataTableOptions.stateSave,
      }
    );
    $(".dataTables_filter").get()[0].getElementsByTagName('input')[0].addEventListener('blur', this.setButtonFunctions);
  }
}
</script>

<style>
/*.btnTargetCompany:hover,*/ .btnCopyId:hover, .btnDeleteCompany:hover, .btnEditCompany:hover {
  color: var(--main-dark);
}
</style>