<template>
<!-- SECTION Refunds-->
  <div id="refundsSection" class="col-12 justify-content-center pe-3"
  :class="(visibility.sideMenu)?'section-container-sm' :'section-container-lg'">
    <div class="col-12 p-0 m-0 bg-caja section-container rounded-5">
  
<!-- HEADER Refunds -->

      <div class="row overflow-hidden col-12 m-0 p-0 text-white bg-clomos rounded-top-5 pb-1 h-100" >

        <div class="col-lg-6 col-md-12 col-12 justify-content-center" style="display: inherit">
          <!--<ion-icon v-if="session.user.bookmark==='refunds'" name="bookmark" class="bookmark-button"></ion-icon>
          <ion-icon v-else name="bookmark-outline" class="bookmark-button"></ion-icon>-->
          <h4 class="tx-left tx-clomos-white text-nowrap mb-2 ps-1 pt-2" style="height:40px;">

            <ion-icon name="trash-bin-outline" class="tx-27 me-2 align-bottom"></ion-icon>
            {{dictionary["Pedidos"][session.user.language]}}
          </h4>
        </div>

<!-- HEADER Display Type -->
        <div class="d-flex justify-content-between col-lg-3 col-md-6 col-12 p-0 ps-1 pe-1 m-0 ms-auto">	
          <div class="w-third p-1 pt-2">	
            <button @click="displayType='all'"
            :style="(displayType==='all')?'border-color: coral' :'border-color: white'"
            class="col-xl-4 col-md-4 col-sm-12 header-tab-button btn bg-clomos rounded p-0 text-nowrap">
              <ion-icon name="ellipsis-vertical-circle-outline" 
              :style="(displayType==='all')?'color: coral':'color: white'"
              class="tx-23" style="vertical-align: middle; margin-top: -0.25rem;"></ion-icon>
            </button>
          </div>
          <div class="w-third p-1 pt-2">	
            <button @click="displayType='toDo'"
            :style="(displayType==='toDo')?'border-color: coral' :'border-color: white'"
            class="col-xl-4 col-md-4 col-sm-12 header-tab-button btn bg-clomos rounded p-0 text-nowrap">
              <ion-icon name="time-outline" 
              :style="(displayType==='toDo')?'color: coral':'color: white'"
              class="tx-23" style="vertical-align: middle; margin-top: -0.25rem;"></ion-icon>
            </button>
          </div>
          <div class="w-third p-1 pt-2">	
            <button @click="displayType='done'"
            :style="(displayType==='done')?'border-color: coral' :'border-color: white'"
            class="col-xl-4 col-md-4 col-sm-12 header-tab-button btn bg-clomos rounded p-0 text-nowrap">
              <ion-icon name="checkmark-circle-outline" 
              :style="(displayType==='done')?'color: coral':'color: white'"
              class="tx-23" style="vertical-align: middle; margin-top: -0.25rem;"></ion-icon>
            </button>
          </div>
        </div>

      </div>

      <div class="m-0 p-3 overflow-auto">
        <table id="refundsDataTable" class="display w-100 border-1 p-0 m-0 rounded-3">
          <thead class="bg-clomos-light">
            <tr>
              <th v-for="column in refundsColumns" :key="column.data"
              :width="column.width" class="tx-black fw-medium text-nowrap">
                {{dictionary[column.text][session.user.language]}}
              </th>
            </tr>
          </thead>
        </table>
      </div>

    </div>
  </div>

</template>

<script>
import $ from 'jquery';
// Clomos
import api from '@/services/api';

export default {
  name: "RefundsComponent",
  props: {
    session: {
      type: Object,
      requiered: true,
    },
    dictionary: {
      type: Object,
      requiered: true,
    },
    countries: {
      type: Object,
      requiered: true,
    },
    timeZones: {
      type: Object,
      requiered: true,
    },
    currentCompany: {
      type: Object,
      requiered: true,
    },
    availableCompanies: {
      type: Object,
      requiered: true,
    },
    visibility: {
      type: Object,
      requiered: true,
    },
    dataTableOptions: {
      type: Object,
      requiered: true,
    }
  },
  watch: { 
    currentCompany: async function() {
      await this.getRefundList();
      this.displayRefundsDataTable();
    }
  },
  data() {
    return {
      refundsColumns: [
        {text:"Albarán", data: "refundId", width: "10%",
          render: (data) => {
            return "<ion-icon name='list-circle-outline' data-id="+data+" class='btnDetailRefund btn tx-24 tx-primary  p-0 m-0'></ion-icon>";
          }
        },
        {text:"Identificador", data: "refundId", width: "20%"},
        {text:"Empresa", data: "refundCompany", width: "20%",
          render: (data) => {
            let companyName = data;
            if (this.availableCompanies.length > 0) {
              for (let company in this.availableCompanies) 
                if (this.availableCompanies[company].nifCif === data ) 
                  companyName = this.availableCompanies[company].name;
            } 
            return companyName;
          }
        },
        {text:"Tipo", data: "refundType", width: "10%",
          render: (data) => {
            return this.dictionary[data][this.session.user.language]
          }
        },
        {text:"Fecha de ejecución", data: "refundExecutionDateTime", width: "15%"},
        {text:"Fecha de creación", data: "refundCreationDateTime", width: "15%"},
        {text:"Descargar", data: "refundId", width: "10%",
          render: (data) => {
            return "<ion-icon name='download-outline' data-id="+data+" class='btnDownloadRefund btn tx-24 tx-primary  p-0 m-0'></ion-icon>";
          }
        },
      ],
      displayType : 'all',
      targetRefundId: "",
      refundList: [],
      bulgeInput: {
        volume: {
          height: 0,
          width: 0,
          depth: 0
        },
        units: 0
      },
      bulgeList: [],
    }
  },
  methods: {
    // DISPLAY
    displayRefundsDataTable() {
      $("#refundsDataTable").DataTable().clear()
      for (let i in this.refundList) {
        $("#refundsDataTable").DataTable().rows.add([{
          refundId: this.refundList[i].id, 
          refundCompany: this.refundList[i].company, 
          refundType: this.refundList[i].type,
          refundStatus: {id: this.refundList[i].id, status: this.refundList[i].status}, 
          refundPosition: this.refundList[i].position, 
          refundExecutionDateTime: this.refundList[i].executionDateTime, 
          refundCreationDateTime: this.refundList[i].creationDateTime, 
        }]);
      }
      $("#refundsDataTable").DataTable().draw();
      this.setButtonFunctions();
      if($(".dataTables_paginate").get()[0] != undefined) $(".dataTables_paginate").get()[0].addEventListener('click', this.setButtonFunctions);
      $(".sorting").get().forEach((btn)=> { btn.addEventListener('click', this.setButtonFunctions)});
    },
    // BUTTONS
    setButtonFunctions() {
      setTimeout(()=>{
        $(".btnTargetRefund").get().forEach(btn => {
          btn.addEventListener('click', this.onTargetRefund);
        });
        $(".btnDetailRefund").get().forEach(btn => {
          btn.addEventListener('click', this.onDetailRefund)
        });
      }, 300);
    },
    // GET
    async getRefundList(callback) {
      let request = {
        nifCif: this.session.user.nifCif
      };
      let response = await api.getRefundList(request);
      this.refundList = api.parseOrdersFromResponse(response);
      
      if (callback && typeof callback === "function") callback(this.refundList);
    },
    // TARGET 
    onTargetRefund(event) {
      console.log('TARGET WHERE ID:');
      console.log(event.target.getAttribute('data-id'));
      this.targetRefundId = event.target.getAttribute('data-id');
    },
    // DETAILS
    onDetailRefund(event) {
      console.log('DETAIL WHERE ID:');
      console.log(event.target.getAttribute('data-id'));
      this.targetRefundId = event.target.getAttribute('data-id')
      var tr = $(event.target).closest('tr');
      var row = $("#refundsDataTable").DataTable().row(tr);
      if (row.child.isShown()) {
        event.target.name = "list-circle-outline";
        row.child.hide();
        tr.removeClass('shown');
      } 
      else {
        event.target.name = "chevron-up-circle-outline";
        let content = '' +
          '<table id="detailTable'+this.targetRefundId+'" cellspacing="0" border="0" style="width:100%; margin: 0;">' +
            '<tr>' +
              '<td class="tx-bold">Producto</td>' +
              '<td class="tx-bold">Cantidad</td>' +
              '<td class="tx-bold">Talla</td>' +
              '<td class="tx-bold">Color</td>' +
              '<td class="tx-bold">Caducidad</td>' +
            '</tr>' +
            '<tr>' +
              '<td>' + this.targetRefundId +'-AL' + '</td>' +
              '<td>' + '102' + '</td>' +
              '<td>' + 'M' + '</td>' +
              '<td>' + 'Rojo' + '</td>' +
              '<td>' + '-' + '</td>' +
            '</tr>' +
            '<tr>' +
              '<td>' + this.targetRefundId +'-AL' + '</td>' +
              '<td>' + '41' + '</td>' +
              '<td>' + 'XL' + '</td>' +
              '<td>' + 'Verde' + '</td>' +
              '<td>' + '-' + '</td>' +
            '</tr>' +
          '</table>';
        row.child(content).show();
        document.getElementById('detailTable'+this.targetRefundId).parentNode.style.padding = 0;
        tr.addClass('shown');
      }
    },
  },
  async created() {
    await this.getRefundList();
    this.displayRefundsDataTable();
  },
  mounted() {
    $("#refundsDataTable").DataTable(
      {
        pagingType: 'full_numbers',
        pageLength: 10,
        processing: true,
        dom: 'Bfrtip',
        buttons: [
          { "extend": 'colvis', "className": 'btn' },
          { "extend": 'copy', "className": 'btn ms-auto' },
          { "extend": 'csv', "className": 'btn' },
          { "extend": 'print', "className": 'btn me-0' },
        ],
        columns: this.refundsColumns,
        select: this.dataTableOptions.select,
        language: this.dataTableOptions.language[this.session.user.language],
        stateSave: this.dataTableOptions.stateSave,
      }
    );
    $(".dataTables_filter").get()[0].getElementsByTagName('input')[0].addEventListener('blur', this.setButtonFunctions);
  }
}
</script>
<style>
#prepareRefundModal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99999;
  background-color: rgba(0, 0, 0, 0.5);
  width: 100vw;
  height: 100vh;
}

.btnDetailRefund:hover {
  color: var(--main-dark);
}

</style>